/*-----------------------------------------------------------------------------------

    Theme Name: Fabrex - Responsive Admin Dashboard Template
    Description: Responsive Admin Dashboard Template
    Author: chitrakootweb

-----------------------------------------------------------------------------------*/
/* ----------------------------------
        
    01. Common styles
    02. CSS utilities
        2.1 Position
        2.2 Box Shadow
        2.3 Typography
        2.4 Margin
        2.5 Padding
        2.6 Width & Height 
        2.7 Borders
        2.8 Radius
    03. Components
        3.1 Text
        3.2 Label
        3.3 Icon Size
        3.4 Blockquote
        3.5 Page Title
        3.6 Breadcrumb
        3.7 Card
        3.8 Alert
        3.9 Backgrounds
        3.10 Text Color
        3.11 Btn Color
        3.12 Buttons
        3.13 Progress Bars
        3.14 Nav Pills
        3.15 Tabs
        3.16 Forms
        3.17 Pager
        3.18 Dropzone and Plupload_button
        3.19 Tables
        3.20 Dropdowns
        3.21 Page Header Nav
        3.22 Popover
        3.23 Accordion style
    04. Layout
        4.1 Page Container
        4.2 Page Header
        4.3 Page Right Sidebar
        4.4 Page Left Sidebar
        4.5 Collapsed Sidebar
        4.6 Search
        4.7 Page Content
        4.8 Page Inner
    05. Plugins
        5.1 jsTree
        5.2 Toastr
        5.3 To Do
    06. Pages
        6.1 Error Page
        6.2 Profile
        6.3 Timeline
        6.4 Maps
        6.5 Authentication
        6.6 Pricing Tables
        6.7 Charts
        6.8 Email
        6.9 Calendar
        6.10 Grid Gallery
        6.11 Dashboard
    
---------------------------------- */

$PrimaryColor: #ff4c52;
$PrimaryRgbColor: rgb(255, 76, 82);

$desk-lg-screen: 1200px;
$desk-md-screen: 1199px;
$desk-small-screen: 1024px;
$desk-md-sm-screen: 1023px;
$desk-sm-screen: 992px;
$desk-xs-screen: 991px;
$tablet-sm-screen: 768px;
$tablet-xs-screen: 767px;
$tablet-small-screen: 766px;
$mobile-md-screen: 576px;
$mobile-sm-screen: 575px;
$mobile-xs-screen: 479px;

@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

/* ===================================
    Common styles
====================================== */

/* loader */
#preloader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
  transition: 0.3s ease opacity;
  text-align: center;
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    width: 80px;
    height: 80px;
    border: 3px solid #ff4c52;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: LoaderCicle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &:after {
    content: "";
    width: 80px;
    height: 80px;
    border: 3px solid #ff4c52;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: LoaderCicle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s;
  }
}

@keyframes LoaderCicle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  10% {
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  80% {
    width: 60px;
    height: 60px;
    opacity: 0.1;
  }

  100% {
    width: 70px;
    height: 70px;
    opacity: 0;
  }
}

html {
  font-size: 14px;
  width: 100%;
  height: 100%;
}

body {
  font-size: 14px;
  width: 100%;
  height: 100%;
  background-color: #526069;
  font-weight: 400;
  color: #3d464c;
}

a {
  color: #2b2b2b;
  &:hover {
    color: #2b2b2b;
  }
}

p {
  line-height: 1.6;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  font-size: 80%;
  font-weight: 400;
}

menu {
  padding-left: 0px;
}

body, span, text {
  font-family: 'Ubuntu', sans-serif;
}

a {
  font-family: 'Ubuntu', sans-serif;
  &:hover, &:active, &:focus {
    outline: none !important;
    text-decoration: none !important;
  }
}

hr {
  border-color: #D0D4D9;
}

.cross-page-line {
  display: block;
  height: 1px;
  width: 100%;
  margin: 20px 0px;
  background: #E0E0E0;
}


/* ===================================
    CSS utilities
====================================== */

/* position */
.position-top-right, .position-top-left, .position-bottom-right, .position-bottom-left {
  position: absolute;
}

.position-top-right {
  top: 0;
  right: 0;
}

.position-top-left {
  top: 0;
  left: 0;
}

.position-bottom-right, .position-bottom-left {
  bottom: 0;
  right: 0;
}

/* box shadow */
.bs-n {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.bs-dark {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
}

.bs-light {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.bs-t {
  -webkit-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05);
}

.bs-r {
  -webkit-box-shadow: 1px 0 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.05);
}

.bs-b {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.bs-l {
  -webkit-box-shadow: -1px 0 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: -1px 0 1px rgba(0, 0, 0, 0.05);
}

/* typography */
.f-thin {
  font-weight: 300;
}

.f-normal {
  font-weight: normal;
}

.f-bold {
  font-weight: 600;
}

.f-s-10 {
  font-size: 10px;
}

.f-s-11 {
  font-size: 11px;
}

.f-s-12 {
  font-size: 12px;
}

.f-s-13 {
  font-size: 13px;
}

.f-s-14 {
  font-size: 14px;
}

.f-s-15 {
  font-size: 15px;
}

.f-s-16 {
  font-size: 16px;
}

.f-s-17 {
  font-size: 17px;
}

.f-s-18 {
  font-size: 18px;
}

.f-s-20 {
  font-size: 20px;
}

.f-s-22 {
  font-size: 22px;
}

.f-s-24 {
  font-size: 24px;
}

.f-s-26 {
  font-size: 26px;
}

.f-s-28 {
  font-size: 28px;
}

.f-s-30 {
  font-size: 30px;
}

.f-s-32 {
  font-size: 32px;
}

.secondary-link {
  font-size: 13px;
  color: #2196f3;
  font-weight: bold;
}

/* margin */
.no-s {
  margin: 0px !important;
  padding: 0px !important;
}

.no-m {
  margin: 0;
}

.no-p {
  padding: 0;
}

.m {
  margin: 15px;
}

.m-t-xxs {
  margin-top: 5px;
}

.m-r-xxs {
  margin-right: 5px;
}

.m-b-xxs {
  margin-bottom: 5px;
}

.m-l-xxs {
  margin-left: 5px;
}

.m-t-xs {
  margin-top: 10px;
}

.m-r-xs {
  margin-right: 10px;
}

.m-b-xs {
  margin-bottom: 10px;
}

.m-l-xs {
  margin-left: 10px;
}

.m-t-sm {
  margin-top: 15px;
}

.m-r-sm {
  margin-right: 15px;
}

.m-b-sm {
  margin-bottom: 15px;
}

.m-l-sm {
  margin-left: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-r-md {
  margin-right: 20px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-l-md {
  margin-left: 20px;
}

.m-t-lg {
  margin-top: 25px;
}

.m-r-lg {
  margin-right: 25px;
}

.m-b-lg {
  margin-bottom: 25px;
}

.m-l-lg {
  margin-left: 25px;
}

.m-t-xxl {
  margin-top: 30px;
}

.m-r-xxl {
  margin-right: 30px;
}

.m-b-xxl {
  margin-bottom: 30px;
}

.m-l-xxl {
  margin-left: 30px;
}

.m-t-n-xxs {
  margin-top: -5px;
}

.m-r-n-xxs {
  margin-right: -5px;
}

.m-b-n-xxs {
  margin-bottom: -5px;
}

.m-l-n-xxs {
  margin-left: -5px;
}

.m-t-n-xs {
  margin-top: -10px;
}

.m-r-n-xs {
  margin-right: -10px;
}

.m-b-n-xs {
  margin-bottom: -10px;
}

.m-l-n-xs {
  margin-left: -10px;
}

.m-t-n-sm {
  margin-top: -15px;
}

.m-r-n-sm {
  margin-right: -15px;
}

.m-b-n-sm {
  margin-bottom: -15px;
}

.m-l-n-sm {
  margin-left: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-t-n-lg {
  margin-top: -25px;
}

.m-r-n-lg {
  margin-right: -25px;
}

.m-b-n-lg {
  margin-bottom: -25px;
}

.m-l-n-lg {
  margin-left: -25px;
}

.m-t-n-xxl {
  margin-top: -30px;
}

.m-r-n-xxl {
  margin-right: -30px;
}

.m-b-n-xxl {
  margin-bottom: -30px;
}

.m-l-n-xxl {
  margin-left: -30px;
}

/* padding */
.p {
  padding: 15px;
}

.p-h-xxs {
  padding-left: 5px;
  padding-right: 5px;
}

.p-h-xs {
  padding-left: 10px;
  padding-right: 10px;
}

.p-h-sm {
  padding-left: 15px;
  padding-right: 15px;
}

.no-p-h {
  padding-left: 0;
  padding-right: 0;
}

.no-p-v {
  padding-top: 0;
  padding-bottom: 0;
}

.p-h-md {
  padding-left: 20px;
  padding-right: 20px;
}

.p-h-lg {
  padding-left: 25px;
  padding-right: 25px;
}

.p-h-xxl {
  padding-left: 30px;
  padding-right: 30px;
}

.p-v-xxs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-xs {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-v-sm {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-v-md {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-v-lg {
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-v-xxl {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* width & height */
.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.w-xxs {
  width: 50px;
}

.w-xs {
  width: 90px;
}

.w-sm {
  width: 140px;
}

.w {
  width: 200px;
}

.w-md {
  width: 240px;
}

.w-lg {
  width: 280px;
}

.w-xl {
  width: 330px;
}

.w-xxl {
  width: 370px;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

/* borders */
.no-b {
  border: 0 !important;
}

.b {
  border: 1px solid #f1f1f1;
}

.b-t {
  border-top: 1px solid #f1f1f1;
}

.b-r {
  border-right: 1px solid #f1f1f1;
}

.b-b {
  border-bottom: 1px solid #f1f1f1;
}

.b-l {
  border-left: 1px solid #f1f1f1;
}

.b-default {
  border-color: #f1f1f1;
}

.b-primary {
  border-color: #756BB0;
}

.b-info {
  border-color: #14A2BB;
}

.b-success {
  border-color: #2EC5D3;
}

.b-warning {
  border-color: #DFBA47;
}

.b-danger {
  border-color: #DE4D4D;
}

.b-2 {
  border-size: 2px;
}

.b-3 {
  border-size: 3px;
}

.b-4 {
  border-size: 4px;
}

.b-n-t {
  border-top: 0;
}

.b-n-r {
  border-right: 0;
}

.b-n-b {
  border-bottom: 0;
}

.b-n-l {
  border-left: 0;
}

/* radius */
.no-r {
  border-radius: 0 !important;
}

.r {
  border-radius: 2px;
}

.r-t-l {
  border-top-left-radius: 2px;
}

.r-t-r {
  border-top-right-radius: 2px;
}

.r-b-l {
  border-bottom-left-radius: 2px;
}

.r-b-r {
  border-bottom-right-radius: 2px;
}

.no-r-t-l {
  border-top-left-radius: 0 !important;
}

.no-r-t-r {
  border-top-right-radius: 0 !important;
}

.no-r-b-l {
  border-bottom-left-radius: 0 !important;
}

.no-r-b-r {
  border-bottom-right-radius: 0 !important;
}

/* ===================================
    Components
====================================== */

/* text */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #2b2b2b;
}

.center {
  float: none;
  margin: 0px auto;
}

/* label */ 
label {
  font-weight: 400;
}

.label {
  font-weight: 400;
  padding: 4px 8px;
  font-size: 11px;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

label.error {
  color: #EC5E69;
  font-weight: 500;
  margin-top: 5px;
  /*float: left; */
  display: inline-block;
  font-size: 12px;
}

.label {
  &.label-default {
    background: #F1F1F1;
    color: #526069;
  }
  &.label-primary {
    background: $PrimaryColor;
  }
  &.label-success {
    background: #63CB89;
  }
  &.label-info {
    background: #5893DF;
  }
  &.label-warning {
    background: #F1C205;
  }
  &.label-danger {
    background: #EC5E69;
  }
}

/* icon size */ 
.icon-xs {
  font-size: 12px;
}

.icon-sm {
  font-size: 14px;
}

.icon-md {
  font-size: 16px;
}

.icon-lg {
  font-size: 18px;
}

.icon-state-default {
  color: #E2E2E2;
}

.icon-state-primary {
  color: $PrimaryColor;
}

.icon-state-success {
  color: #63CB89;
}

.icon-state-info {
  color: #5893DF;
}

.icon-state-warning {
  color: #F1C205;
}

.icon-state-danger {
  color: #EC5E69;
}

.fontawesome-icon-list {
  padding: 0 2px;
}

.fa-hover {
  cursor: pointer;
  line-height: 50px;
  white-space: nowrap;
  &:hover {
    background-color: #f7f7f7;
  }
  i {
    margin-right: 8px;
    vertical-align: middle;
    font-size: 18px;
  }
  a {
    color: #4f4f4f;
  }
}

.lead {
  color: #2b2b2b;
}

/* blockquote */ 
blockquote {
  border-left: 5px solid #ccc;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 16px;
}

.blockquote-reverse {
  border-right: 5px solid #ccc;
  border-left: none;
  text-align: right;
}

blockquote {
  &.float-right {
    border-right: 5px solid #ccc;
    border-left: none;
    text-align: right;
  }
  .small, footer, small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
  }
  .small:before, footer:before, small:before {
    content: '\2014 \00A0';
  }
}

.well {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

/* page title */
.page-title {
  padding: 20px;
  background: #fff;
  margin-bottom: 15px;
  border: 1px solid #E6E8EB;
  border-radius: 4px;
}

/* breadcrumb */
.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-size: 13px;
}

.breadcrumb {
  li {
    i {
      font-size: 14px;
      margin-right: 4px;
      color: rgba(0, 0, 0, 0.4);
      display: block;
      float: left;
    }
    a {
      color: rgba(0, 0, 0, 0.4);
      font-weight: 500;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  > .active {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
  }
}

.breadcrumb-header {
  font-size: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  padding: 0;
  margin: 0;
}

/* card */
.card {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-shadow: none;
  padding: 25px;
  border-color: #E6E8EB;
}

.card-body {
  padding: 0;
}

.card-heading {
  padding: 0;
  border: 0;
}

.card-title {
  color: #526069;
  font-weight: 600;
  padding: 0;
  padding-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.card-default > .card-heading {
  color: #333;
  background-color: #f6f9fc;
  border-color: #E6E8EB;
  + .card-collapse > .card-body {
    padding: 20px;
  }
}

.card-primary > .card-heading + .card-collapse > .card-body, .card-success > .card-heading + .card-collapse > .card-body, .card-warning > .card-heading + .card-collapse > .card-body, .card-danger > .card-heading + .card-collapse > .card-body {
  padding: 20px;
}

.card-group .card {
  border-radius: 4px;
}

.card-default > .card-heading + .card-collapse > .card-body, .card-primary > .card-heading + .card-collapse > .card-body, .card-success > .card-heading + .card-collapse > .card-body, .card-info > .card-heading + .card-collapse > .card-body, .card-warning > .card-heading + .card-collapse > .card-body, .card-danger > .card-heading + .card-collapse > .card-body {
  border-color: #fff;
}

.card-default {
  border-color: #E6E8EB;
}

.card-primary {
  border-color: $PrimaryColor;
}

.card-success {
  border-color: #63CB89;
}

.card-info {
  border-color: #5893DF;
}

.card-warning {
  border-color: #F1C205;
}

.card-danger {
  border-color: #EC5E69;
}

.card-primary > .card-heading a, .card-success > .card-heading a, .card-info > .card-heading a, .card-warning > .card-heading a, .card-danger > .card-heading a {
  color: #fff !important;
}

.card-primary > .card-heading {
  background-color: $PrimaryColor;
  border-color: $PrimaryColor;
}

.card-success > .card-heading {
  background-color: #63CB89;
  border-color: #63CB89;
}

.card-info > .card-heading {
  background-color: #5893DF;
  border-color: #5893DF;
}

.card-warning > .card-heading {
  background-color: #F1C205;
  border-color: #F1C205;
}

.card-danger > .card-heading {
  background-color: #EC5E69;
  border-color: #EC5E69;
}

.card-default .card-title > {
  .small {
    color: #2b2b2b;
    > a {
      color: #2b2b2b;
    }
  }
  a {
    color: #2b2b2b;
  }
  small {
    color: #2b2b2b;
    > a {
      color: #2b2b2b;
    }
  }
}

/* alert */
.alert-default {
  background: #f6f9fc;
  border-color: #E6E8EB;
}

.alert-success .alert-link {
  color: #218892;
}

.alert-info .alert-link {
  color: #2f73c8;
}

.alert-warning .alert-link {
  color: #94780a;
}

.alert-danger .alert-link {
  color: #d83945;
}

/* backgrounds */
.bg-primary {
  background-color: #36a3f5 !important;
}

.bg-success {
  background-color: #63CB90 !important;
}

.bg-info {
  background-color: #01c0d6 !important;
}

.bg-danger {
  background-color: #ec5e70 !important;
}

.bg-warning {
  background-color: #ffb820 !important;
}

.bg-dark {
  background-color: #333 !important;
}

.bg-n {
  background: transparent !important;
}

.bg-primary {
  &.dark {
    background-color: #005fbf;
    color: #fff;
  }
  &.light {
    background-color: #0580fc;
    color: #fff;
  }
}

.bg-success {
  &.dark {
    background-color: #4a9b68;
    color: #fff;
  }
  &.light {
    background-color: #74eda0;
    color: #fff;
  }
}

.bg-info {
  &.dark {
    background-color: #4374b2;
    color: #fff;
  }
  &.light {
    background-color: #71acf7;
    color: #fff;
  }
}

.bg-warning {
  &.dark {
    background-color: #d6aa00;
    color: #fff;
  }
  &.light {
    background-color: #ffda47;
    color: #fff;
  }
}

.bg-danger {
  &.dark {
    background-color: #d3505a;
    color: #fff;
  }
  &.light {
    background-color: #f7717b;
    color: #fff;
  }
}

.bg-dark {
  &.dark {
    background-color: #2b2b2b;
    color: #fff;
  }
  &.light {
    background-color: #3b3b3b;
    color: #fff;
  }
}

.bg-light {
  background-color: #f1f1f1;
  &.dark {
    background-color: #e5e5e5;
  }
  &.light {
    background-color: #f9f9f9;
  }
}

.bg-gray {
  background-color: #ccc;
  &.dark {
    background-color: #b9b9b9;
  }
  &.light {
    background-color: #d7d7d7;
  }
}

/* text color */
.text-primary {
  color: #36a3f5 !important;
}

.text-success {
  color: #63CB90 !important;
}

.text-info {
  color: #01c0d6 !important;
}

.text-danger {
  color: #ec5e70 !important;
}

.text-warning {
  color: #ffb820 !important;
}

.text-dark {
  color: #333 !important;
}

/* btn color */
.btn-primary {
  background-color: #36a3f5;
  border-color: #36a3f5;
}

.btn-success {
  background-color: #63CB90;
  border-color: #63CB90;
}

.btn-info {
  background-color: #01c0d6;
  border-color: #01c0d6;
}

.btn-danger {
  background-color: #ec5e70;
  border-color: #ec5e70;
}

.btn-warning {
  background-color: #ffb820;
  border-color: #ffb820;
}

.btn-dark {
  background-color: #333;
  border-color: #333;
}

/* buttons */
.search-form .btn-default {
  background-color: #fff;
}

.btn-default {
  &.active, &:active {
    border-color: $PrimaryColor !important;
    background-color: #ececec;
    color: $PrimaryColor;
  }
}

.open > .dropdown-toggle.btn-default {
  border-color: $PrimaryColor !important;
  background-color: #ececec;
  color: $PrimaryColor;
}

.btn-default {
  &.active:hover, &:active:hover {
    border-color: #E6E9EC;
    background-color: #E6E9EC;
  }
}

.open > .dropdown-toggle.btn-default:hover {
  border-color: #E6E9EC;
  background-color: #E6E9EC;
}

.btn {
  border-radius: 4px;
  padding: 5px 16px;
  box-shadow: none !important;
  &:focus, &:active:focus, &.active:focus, &.focus, &:active.focus, &.active.focus {
    outline: 0;
    outline-offset: 0;
  }
  &:active, &.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.btn-xs {
  padding: 1px 5px;
}

.btn-sm {
  padding: 5px 10px;
}

.btn-lg {
  padding: 10px 16px;
}

.btn-rounded {
  border-radius: 60px;
}

.btn-social {
  width: 45px;
  height: 45px;
  padding: 10px 0px;
  border-radius: 50%;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  &:hover {
    color: #fff;
    opacity: 0.8;
  }
  &:focus {
    color: #fff;
  }
}

.btn-facebook {
  background-color: #3B5998;
  color: #fff;
}

.btn-twitter {
  background-color: #55ACEE;
  color: #fff;
}

.btn-google {
  background-color: #DD4B39;
  color: #fff;
}

.btn-youtube {
  background-color: #BB0000;
  color: #fff;
}

.btn-vimeo {
  background-color: #1EB8EB;
  color: #fff;
}

.btn-dribbble {
  background-color: #ea4c89;
  color: #fff;
}

.btn-instagram {
  background-color: #3F729B;
  color: #fff;
}

.btn-pinterest {
  background-color: #BD081B;
  color: #fff;
}

.btn-vk {
  background-color: #507299;
  color: #fff;
}

.btn-rss {
  background-color: #FF6600;
  color: #fff;
}

.btn-flickr {
  background-color: #FF0084;
  color: #fff;
}

/* progress bars */
.progress {
  box-shadow: none !important;
  height: 4px;
  border-radius: 0;
  margin-bottom: 18px;
  overflow: hidden;
}

.progress-bar {
  background-color: #36a3f5;
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}

.progress {
  &.progress-sm {
    height: 8px !important;
    .progress-bar {
      line-height: 10px;
    }
  }
  &.progress-md {
    height: 15px !important;
    .progress-bar {
      line-height: 15px;
    }
  }
  &.progress-lg {
    height: 20px !important;
    .progress-bar {
      line-height: 20px;
    }
  }
}

.progress-rounded {
  border-radius: 60px;
}

/* nav pills */
.nav-pills {
  .nav-link.active, .show > .nav-link {
    background-color: #36a3f5;
  }
  > li.active > a {
    color: #fff;
    background-color: #007bff;
    &:hover, &:focus {
      color: #fff;
      background-color: #007bff;
    }
  }
}

/* tabs */
.tabs-left .nav > li:first-child > a {
  margin-left: 15px !important;
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-bottom: 0;
  border-bottom: 0;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #E2E2E2;
  border-bottom: 0 !important;
  > li > a {
    margin-right: -1px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &:hover, &:focus {
      background: transparent;
      border-color: transparent;
      border-right: 1px solid #B6BABD !important;
      border-bottom: none;
      color: #3D464D;
    }
  }
  .active > a {
    border-color: transparent;
    border-right-color: #1B2733 !important;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 0 !important;
    &:hover, &:focus {
      border-color: transparent;
      border-right-color: #1B2733 !important;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
      border-top-right-radius: 0 !important;
    }
  }
}

.tabs-right {
  .nav > li > a {
    text-align: right;
    margin-left: -1px !important;
    margin-right: 0px;
    padding-right: 15px;
  }
  > .nav-tabs {
    border-bottom: 0;
  }
}

.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #E2E2E2;
  border-bottom: 0 !important;
  > li > a {
    padding-left: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &:hover, &:focus {
      background: transparent;
      border-color: transparent;
      border-left: 1px solid #B6BABD !important;
      border-bottom: none;
      color: #3D464D;
    }
  }
  .active > a {
    border-color: transparent;
    border-left-color: #1B2733 !important;
    border-bottom: none;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-top-left-radius: 0 !important;
    &:hover, &:focus {
      border-color: transparent;
      border-left-color: #1B2733 !important;
      border-bottom: none;
      border-top-right-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
      border-top-left-radius: 0 !important;
    }
  }
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none;
}

.tab-content > .active, .pill-content > .active {
  display: block;
}

.nav-tabs.nav-justified {
  > {
    li > a {
      margin-bottom: -1px;
      margin-left: 0px;
    }
    .active > a {
      border-color: transparent;
      border-bottom: 1px solid #1B2733;
      color: #1B2733;
      &:focus, &:hover {
        border-color: transparent;
        border-bottom: 1px solid #1B2733;
        color: #1B2733;
      }
    }
  }
  border-bottom: 1px solid #E2E2E2;
}

.tab-content {
  margin-top: 15px;
}

/* forms */
.form-check {
  padding-left: 0;
}

.input-group-prepend.last {
  margin-right: 0;
  margin-left: -1px;
  .input-group-text, .btn {
    border-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-group-btn {
  .input-group-prepend .btn, &.input-group-prepend .btn {
    border-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-prepend.last .btn, &.input-group-prepend.last .btn {
    border-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

div.checker {
  width: 18px;
  height: 18px;
  input, span {
    width: 18px;
    height: 18px;
  }
}

input {
  &[type=checkbox], &[type=radio] {
    margin-right: 4px;
  }
  &.form-control.input-rounded {
    border-radius: 60px;
  }
}

.form-group {
  margin-bottom: 1rem;
}

@media screen and (min-width: $tablet-sm-screen) {
  .form-horizontal .col-form-label {
    text-align: right;
  }
}

.input-group-addon {
  border-radius: 4px;
  background-color: #f7f7f7;
  border-color: #C1C7CD;
}

.bootstrap-tagsinput {
  border-radius: 4px;
  box-shadow: none;
  border-color: #D7D7D7;
  padding: 6px 12px;
}

.note-editor {
  .note-toolbar, .note-statusbar {
    background: transparent;
  }
  border-radius: 4px;
  border-color: #D7D7D7;
  .note-toolbar, .note-statusbar .note-resizebar {
    border-color: #D7D7D7;
  }
}

.has-success {
  .checkbox, .checkbox-inline, .col-form-label, .help-block, .radio, .radio-inline, &.checkbox label, &.checkbox-inline label, &.radio label, &.radio-inline label {
    color: #63CB89;
  }
  .form-control {
    border-color: #63CB89;
    &:focus {
      border-color: #6DD191;
    }
  }
}

.has-warning {
  .checkbox, .checkbox-inline, .col-form-label, .help-block, .radio, .radio-inline, &.checkbox label, &.checkbox-inline label, &.radio label, &.radio-inline label {
    color: #F1C205;
  }
  .form-control {
    border-color: #F1C205;
    &:focus {
      border-color: #F5CA1E;
    }
  }
}

.has-error {
  .checkbox, .checkbox-inline, .col-form-label, .help-block, .radio, .radio-inline, &.checkbox label, &.checkbox-inline label, &.radio label, &.radio-inline label {
    color: #EC5E69;
  }
  .form-control {
    border-color: #EC5E69;
    &:focus {
      border-color: #F5707A;
    }
  }
}

/* pager */
.pager {
  li > {
    a, span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid $PrimaryColor;
      border-radius: 15px;
    }
  }
  .disabled > {
    a {
      opacity: .65;
      border: 1px solid $PrimaryColor;
      color: $PrimaryColor;
      &:focus, &:hover {
        opacity: .65;
        border: 1px solid $PrimaryColor;
        color: $PrimaryColor;
      }
    }
    span {
      opacity: .65;
      border: 1px solid $PrimaryColor;
      color: $PrimaryColor;
    }
  }
}

/* dropzone and plupload_button */ 
.dd-handle {
  border-radius: 0;
  background-color: #f7f7f7;
  border: none;
  font-weight: 500;
  color: #2b2b2b;
}

.dd3-content {
  border: none;
  background-color: #f7f7f7;
  font-weight: 500;
}

.dd3-handle {
  height: 36px;
}

.dropzone {
  min-height: 274px;
  border: 1px solid #d8d8d8;
}

.plupload_button {
  padding: 6px 12px;
  border-radius: 0 !important;
  font-size: 14px;
  border-color: #D7D7D7;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  &:hover {
    background-color: #E6E9EC;
  }
}

.dropzone.dz-clickable .dz-message {
  line-height: 232px;
  margin: auto;
}

/* tables */
.table > {
  tbody > tr > {
    td, th {
      border-color: #E6E8EB;
    }
  }
  tfoot > tr > {
    td, th {
      border-color: #E6E8EB;
    }
  }
  thead > tr > {
    td {
      border-color: #E6E8EB;
    }
    th {
      border-color: #E6E8EB;
      border-bottom: 1px solid #E6E8EB;
      color: #6A7C8F;
    }
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f7f7f7;
}

table.dataTable {
  &.display tbody tr.even > .sorting_1, &.order-column.stripe tbody tr.even > .sorting_1, &.stripe tbody tr.odd, &.display tbody tr.odd {
    background-color: #f7f7f7;
  }
  thead th, tfoot th {
    font-weight: 400;
  }
  thead {
    .sorting_asc, .sorting_desc {
      font-weight: bold !important;
    }
  }
}

.dataTables_wrapper {
  .dataTables_paginate .paginate_button {
    border-radius: 100%;
    border: none;
    box-shadow: none;
    color: #526069 !important;
    &.current {
      border: none;
      box-shadow: none;
      background: $PrimaryColor;
      color: #fff !important;
      &:hover {
        border: none;
        box-shadow: none;
        background: $PrimaryColor;
        color: #fff !important;
      }
    }
    &:hover {
      border: none;
      box-shadow: none;
      background: #f1f1f1;
      color: #526069 !important;
    }
    &.next, &.previous {
      border-radius: 25px;
    }
  }
  .dataTables_filter input {
    box-shadow: none !important;
    border: 1px solid #C1C7CD;
    color: #646464;
    border-radius: 4px;
    padding: 5px 12px;
    height: 32px;
    &:focus {
      outline: 0;
      outline-offset: 0;
    }
  }
}

th {
  font-weight: 400;
}

.table > {
  tbody > tr > {
    td, th {
      padding: 12px 8px;
    }
  }
  tfoot > tr > {
    td, th {
      padding: 12px 8px;
    }
  }
  thead > tr > {
    td, th {
      padding: 12px 8px;
    }
  }
  tbody > tr {
    &.active > {
      td, th {
        background-color: #f6f9fc;
      }
    }
    > {
      td.active, th.active {
        background-color: #f6f9fc;
      }
    }
  }
  tfoot > tr {
    &.active > {
      td, th {
        background-color: #f6f9fc;
      }
    }
    > {
      td.active, th.active {
        background-color: #f6f9fc;
      }
    }
  }
  thead > tr {
    &.active > {
      td, th {
        background-color: #f6f9fc;
      }
    }
    > {
      td.active, th.active {
        background-color: #f6f9fc;
      }
    }
  }
  tbody > tr {
    &.success > {
      td, th {
        background-color: #92d1a9;
      }
    }
    > {
      td.success, th.success {
        background-color: #92d1a9;
      }
    }
  }
  tfoot > tr {
    &.success > {
      td, th {
        background-color: #92d1a9;
      }
    }
    > {
      td.success, th.success {
        background-color: #92d1a9;
      }
    }
  }
  thead > tr {
    &.success > {
      td, th {
        background-color: #92d1a9;
      }
    }
    > {
      td.success, th.success {
        background-color: #92d1a9;
      }
    }
  }
  tbody > tr {
    &.info > {
      td, th {
        background-color: #75a0d8;
      }
    }
    > {
      td.info, th.info {
        background-color: #75a0d8;
      }
    }
  }
  tfoot > tr {
    &.info > {
      td, th {
        background-color: #75a0d8;
      }
    }
    > {
      td.info, th.info {
        background-color: #75a0d8;
      }
    }
  }
  thead > tr {
    &.info > {
      td, th {
        background-color: #75a0d8;
      }
    }
    > {
      td.info, th.info {
        background-color: #75a0d8;
      }
    }
  }
  tbody > tr {
    &.warning > {
      td, th {
        background-color: #e8cd61;
      }
    }
    > {
      td.warning, th.warning {
        background-color: #e8cd61;
      }
    }
  }
  tfoot > tr {
    &.warning > {
      td, th {
        background-color: #e8cd61;
      }
    }
    > {
      td.warning, th.warning {
        background-color: #e8cd61;
      }
    }
  }
  thead > tr {
    &.warning > {
      td, th {
        background-color: #e8cd61;
      }
    }
    > {
      td.warning, th.warning {
        background-color: #e8cd61;
      }
    }
  }
  tbody > tr {
    &.danger > {
      td, th {
        background-color: #ea7c84;
      }
    }
    > {
      td.danger, th.danger {
        background-color: #ea7c84;
      }
    }
  }
  tfoot > tr {
    &.danger > {
      td, th {
        background-color: #ea7c84;
      }
    }
    > {
      td.danger, th.danger {
        background-color: #ea7c84;
      }
    }
  }
  thead > tr {
    &.danger > {
      td, th {
        background-color: #ea7c84;
      }
    }
    > {
      td.danger, th.danger {
        background-color: #ea7c84;
      }
    }
  }
}

.checkbox label, .radio label {
  padding-left: 0px;
}

/* dropdowns */
.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 7px 0;
  margin: 10px 0px 0px 0px;
  background-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e5e9ec;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  li {
    font-size: 14px;
    font-weight: 400;
    a:hover {
      background: #f7f7f7;
      text-decoration: none;
    }
  }
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 25px !important;
}

.dropdown-menu {
  &:before {
    position: absolute;
    top: -8px;
    left: 9px;
    right: auto;
    display: inline-block !important;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e5e9ec;
    border-left: 8px solid transparent;
    content: '';
  }
  &:after {
    position: absolute;
    top: -7px;
    left: 10px;
    right: auto;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    content: '';
  }
}

.dropdown.dropup > .dropdown-menu {
  &:after, &:before {
    display: none !important;
  }
}

.btn-group.dropup > .dropdown-menu {
  &:after, &:before {
    display: none !important;
  }
}

.dropdown-toggle.dropup > .dropdown-menu {
  &:after, &:before {
    display: none !important;
  }
}

.navbar-left .dropdown-menu:before, .dropdown-menu.dropdown-menu-left:before {
  left: 16px;
  right: auto;
}

.navbar-left .dropdown-menu:after, .dropdown-menu.dropdown-menu-left:after {
  left: 17px;
  right: auto;
}

.navbar-right .dropdown-menu:before, .dropdown-menu.dropdown-menu-right:before {
  right: 16px;
  left: auto;
}

.navbar-right .dropdown-menu:after {
  right: 17px;
  left: auto;
}

.dropdown-menu {
  &.dropdown-menu-right:after {
    right: 17px;
    left: auto;
  }
  &.title-caret:after {
    border-bottom: 7px solid #fff;
  }
  > li.active > a {
    &:hover, &:focus, &:active, &:hover, &:focus {
      background: #f7f7f7;
      color: #2b2b2b;
    }
    background: #f7f7f7;
    color: #2b2b2b;
  }
}

.drop-title {
  display: block;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #e5e9ec;
  background: #fff;
  padding: 12px;
  color: #6a6a6a;
  margin: 0px;
}

.drop-title-link {
  float: right;
  display: inline !important;
  padding: 0px !important;
  height: 18px;
  width: 18px;
  overflow: hidden;
  vertical-align: text-top;
  line-height: 18px !important;
  text-align: center;
  border-radius: 100%;
  i {
    margin-right: 0 !important;
  }
}

.dropdown-menu li.drop-all a {
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #707070;
  background: #FFF;
  font-size: 14px;
  &:hover {
    background: #f7f7f7;
  }
}

.dropdown-sm {
  width: 190px;
}

.dropdown-md {
  width: 230px;
}

.dropdown-lg {
  width: 280px;
}

.dropdown-content {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-menu li {
  a {
    padding: 8px 15px;
    color: #5f5f5f;
    font-size: 14px;
    font-weight: 400;
    display: block;
    i {
      margin-right: 10px;
    }
  }
  &.no-link {
    padding: 7px 10px 7px 10px;
  }
  &.li-group {
    border-bottom: 1px solid #E5E9EC;
    padding: 5px 0px 5px 0px;
  }
}

/* page header nav */
.page-header .nav .open > a {
  background-color: #eee;
  border-color: transparent;
  &:hover, &:focus {
    background-color: #eee;
    border-color: transparent;
  }
}

.right-sidebar-top .nav > li > a {
  padding: 11px 15px;
  position: relative;
  display: block;
}

.page-right-sidebar-inner .tab-content {
  margin-top: 0;
}

.page-header {
  .nav > li > a {
    font-weight: 400;
    margin: 0 15px;
    padding: 10px 0;
  }
  .nav-tabs.nav > li:first-child > a, .nav-pills.nav > li:first-child > a {
    margin-left: 0px;
  }
  .navbar {
    .dropdown .dropdown-menu {
      display: block;
      visibility: hidden;
      opacity: 0;
      transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      margin-top: 40px !important;
      &.dropdown-list {
        li {
          &:first-child {
            margin-top: 5px;
          }
          &:last-child {
            margin-bottom: 5px;
          }
        }
        &.theme-settings li:first-child {
          margin-top: 0;
        }
      }
    }
    .dropdown-menu.show {
      visibility: visible;
      opacity: 1;
      margin-top: 15px !important;
    }
    .dropdown {
      &.user-dropdown {
        margin-top: 2px;
      }
      display: inline-block;
    }
  }
}

.dropdown-notifications {
  height: auto !important;
  max-height: 250px;
  .dropdown-oc li a {
    padding-right: 0;
    border-bottom: 1px solid #e5e9ec;
    span {
      &.notification-badge {
        width: 30px;
        height: 30px;
        display: block;
        line-height: 30px;
        text-align: center;
        border-radius: 100%;
        display: inline-block;
        vertical-align: top;
        margin-top: 10px;
        i {
          margin-right: 0px;
        }
      }
      &.notification-info {
        width: 204px;
        display: block;
        padding: 5px 15px;
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        small {
          color: #aaa;
        }
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-header {
    height: auto !important;
    .navbar {
      height: auto !important;
    }
    .navbar-default {
      .container-fluid, .navbar-collapse {
        height: auto !important;
      }
    }
    padding: 0;
  }
  .navbar li {
    display: block;
  }
  .navbar-collapse {
    padding: 0 0 0 25px !important;
    flex-basis: unset;
    flex-grow: unset;
    width: calc(100% + 30px);
  }
  .navbar-nav {
    float: right;
    display: inline-block;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .dropdown-lg {
    width: 230px;
  }
  .dropdown-notifications .dropdown-oc li a span.notification-info {
    width: 168px;
  }
}

/* popover */
.popover {
  border: 1px solid #e5e9ec;
  border-radius: 4px;
  box-shadow: none;
  &.top > .arrow {
    border-top-color: #e5e9ec;
  }
  &.right > .arrow {
    border-right-color: #e5e9ec;
  }
}

.popover-title {
  padding: 15px 15px 10px 15px;
  color: #526069;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  background-color: transparent;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  font-family: 'Ubuntu', sans-serif;
}

.popover-content {
  padding: 10px 15px 15px 15px;
}

/* accordion style */
.accordion-style {
  .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    border: none;
    padding: 0;
    margin-bottom: 0;
  }
  .card-header {
    border: 0px;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none;
  }
  .btn-link {
    background: rgba(0, 0, 0, 0.03);
    color: #3d464c;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 4px;
    padding: 15px 30px 15px 15px;
    text-decoration: none;
    background: $PrimaryColor;
    color: #fff;
    &.collapsed {
      background: rgba(0, 0, 0, 0.03);
      color: #3d464c;
    }
    &:hover {
      background: $PrimaryColor;
      color: #fff;
      border: none;
      text-decoration: none;
    }
    &.collapsed:after {
      background: none;
      content: "+";
      right: 15px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
      color: #3d464c;
    }
    &:after {
      background: none;
      content: "-";
      right: 17px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
      position: absolute;
      color: #fff;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .card-body {
    padding: 20px;
    line-height: 24px;
    text-align: left;
  }
}

#accordion .card, #accordion2 .card {
  padding: 0;
}

#accordion .card-title, #accordion2 .card-title {
  padding: 20px;
}

/* ===================================
    Layout
====================================== */

/* page container */
.page-container {
  min-height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  &:not(.container) {
    width: 100%;
  }
}

/* page header */
.page-header {
  .nav > li > a {
    &:focus, &:hover {
      background-color: #fff;
    }
  }
  .nav-pills > li > a {
    color: #777;
    border-radius: 0px !important;
    border-radius: 4px !important;
    padding: 10px 15px !important;
    margin-right: 5px !important;
    &:hover {
      background-color: #E2E2E2;
    }
  }
  padding-bottom: 0px;
  margin: 0;
  border-bottom: none;
  height: 56px;
  position: relative;
  padding-top: 0;
  padding-right: 0;
  background: #f6f9fc;
}

.page-header-fixed {
  .page-header {
    position: fixed;
    z-index: 99;
    left: 240px;
    right: 0;
    top: 0;
  }
  .page-container.container .page-header {
    padding-right: 15px;
    padding-left: 195px;
    left: 0;
    right: 0;
    margin: auto;
  }
  &.page-sidebar-collapsed .page-container.container .page-header {
    padding-right: 15px;
    padding-left: 75px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media screen and (min-width: $tablet-sm-screen) {
  .page-header-fixed .page-container.container .page-header {
    width: 750px;
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .page-header-fixed .page-container.container .page-header {
    width: 970px;
  }
}

@media screen and (min-width: $desk-lg-screen) {
  .page-header-fixed .page-container.container .page-header {
    width: 1170px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-header-fixed .page-header {
    left: 0;
  }
}

.page-sidebar-collapsed.page-header-fixed .page-header {
  left: 80px;
}

.page-header .form-control {
  background-color: #fff;
  color: #fff;
  &::placeholder {
    color: #cfd8e7;
  }
}

.page-sidebar-fixed .page-header {
  z-index: 41;
}

.page-header {
  .navbar-default {
    background-color: #2a3542;
    border: 0;
    margin-bottom: 0;
    height: 56px;
    border-radius: 0;
    padding-right: 25px;
    padding-left: 0;
    .container-fluid, .navbar-collapse {
      height: 100% !important;
    }
    .navbar-brand i.fixed-sidebar-button-active {
      background: #fff;
    }
  }
  .navbar-nav > li {
    &.v-divider {
      display: block;
      width: 1px;
      height: 26px;
      background: #F3F3F3;
      margin: 21px 15px;
    }
    > a {
      padding-top: 20px;
      padding-bottom: 20px;
      color: #4F4F4F;
      font-size: 11px;
      text-transform: uppercase;
      display: inline-block;
      &:hover, &:link, &:focus {
        color: #fff !important;
      }
      > img {
        width: 36px;
        margin-top: -10px;
        display: inline-block;
        vertical-align: top;
      }
    }
    &.user-dropdown > a {
      margin-right: 0px;
      padding-bottom: 0;
    }
  }
}

.navbar-default .navbar-nav > .open > a {
  color: #1f2229;
  background-color: transparent;
  &:focus, &:hover {
    color: #1f2229;
    background-color: transparent;
  }
}

.page-header .navbar-collapse {
  padding-right: 0px;
  padding-left: 0px;
}

@media screen and (min-width: $tablet-sm-screen) {
  .navbar-nav {
    float: left;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  .page-header .navbar-nav > li > a > i {
    font-size: 14px;
    vertical-align: text-top;
    &[class^="icon-"] {
      font-size: 19px;
      vertical-align: top;
    }
  }
}

.page-header {
  .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    > li {
      position: relative;
      display: inline-block;
      > a {
        &:hover, &:focus {
          text-decoration: none;
          background-color: #eee;
        }
      }
      &.disabled > a {
        color: #777;
        &:hover, &:focus {
          color: #777;
          text-decoration: none;
          cursor: not-allowed;
          background-color: transparent;
        }
      }
    }
    .open > a {
      background-color: #eee;
      border-color: #337ab7;
      &:hover, &:focus {
        background-color: #eee;
        border-color: #337ab7;
      }
    }
    .nav-divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }
    > li > a > img {
      max-width: none;
    }
  }
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
    position: absolute;
  }
  .navbar-header {
    float: left;
  }
  .navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    &.in {
      overflow-y: auto;
    }
  }
  .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }
  .navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    &:hover, &:focus {
      text-decoration: none;
    }
    > img {
      display: block;
    }
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    &:focus {
      outline: 0;
    }
    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      + .icon-bar {
        margin-top: 4px;
      }
    }
  }
  .navbar-nav {
    margin: 7.5px 0;
    > li > a {
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px;
    }
  }
  .navbar-default {
    .navbar-brand {
      color: #777;
      &:hover, &:focus {
        color: #5e5e5e;
        background-color: transparent;
      }
    }
    .navbar-text {
      color: #777;
    }
    .navbar-nav > {
      li > a {
        color: #777;
        &:hover, &:focus {
          color: #333;
          background-color: transparent;
        }
      }
      .active > a {
        color: #555;
        background-color: #e7e7e7;
        &:hover, &:focus {
          color: #555;
          background-color: #e7e7e7;
        }
      }
      .disabled > a {
        color: #ccc;
        background-color: transparent;
        &:hover, &:focus {
          color: #ccc;
          background-color: transparent;
        }
      }
    }
    .navbar-toggle {
      border-color: #ddd;
      background-color: #fff;
      &:hover, &:focus {
        background-color: #ddd;
      }
      .icon-bar {
        background-color: #888;
      }
    }
    .navbar-collapse {
      padding-left: 0;
      padding-right: 0;
    }
    .navbar-nav > .open > a {
      color: #555;
      background-color: #e7e7e7;
      &:hover, &:focus {
        color: #555;
        background-color: #e7e7e7;
      }
    }
  }
}

@media screen and (min-width: $tablet-sm-screen) {
  .page-header {
    .navbar-collapse {
      width: auto;
      border-top: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      &.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
      }
      &.in {
        overflow-y: visible;
      }
    }
    .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }
    .navbar > {
      .container .navbar-brand, .container-fluid .navbar-brand {
        margin-left: -15px;
      }
    }
    .navbar-toggle {
      display: none;
    }
    .navbar-nav {
      float: left;
      margin: 0;
      display: inline-block;
      > li {
        float: left;
        display: block;
        > .dropdown-menu {
          margin-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
    .navbar-left {
      float: left !important;
    }
    .navbar-right {
      float: right !important;
      margin-right: -15px;
      ~ .navbar-right {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .page-header {
    ul.navbar-nav:first-child {
      display: none;
    }
    .navbar-nav .open .dropdown-menu {
      > li > a, .dropdown-header {
        padding: 5px 15px 5px 25px;
      }
      > li > a {
        line-height: 20px;
        &:hover, &:focus {
          background-image: none;
        }
      }
    }
    .navbar-header {
      width: calc(100% + 30px);
    }
    .navbar-default {
      padding-right: 15px;
      .navbar-nav .open .dropdown-menu > {
        li > a {
          color: #777;
          &:hover, &:focus {
            color: #333;
            background-color: transparent;
          }
        }
        .active > a {
          color: #555;
          background-color: #e7e7e7;
          &:hover, &:focus {
            color: #555;
            background-color: #e7e7e7;
          }
        }
        .disabled > a {
          color: #ccc;
          background-color: transparent;
          &:hover, &:focus {
            color: #ccc;
            background-color: transparent;
          }
        }
      }
    }
  }
}

/* page rightsidebar */
.page-right-sidebar {
  .nav-tabs {
    > li {
      &.active > a {
        border-color: #E5E9EC;
        border-bottom-color: transparent;
        &:focus, &:hover {
          border-color: #E5E9EC;
          border-bottom-color: transparent;
        }
      }
      > a {
        color: #2b2b2b;
        &:hover {
          border-color: #edf1f4;
          border-bottom: transparent;
        }
      }
    }
    border-color: #E5E9EC;
  }
  .nav-justified.nav-tabs > li > a:hover {
    border-color: #ddd;
    border-bottom: transparent;
  }
  .nav-tabs {
    > li {
      > a {
        border-radius: 0 !important;
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
        color: #777;
        border-bottom: 1px solid #E2E2E2;
      }
      &.active > a {
        border: 1px solid transparent;
        color: #1B2733;
        font-weight: 700;
        &:focus, &:hover {
          border: 1px solid transparent;
          color: #1B2733;
          font-weight: 700;
        }
      }
    }
    border-bottom: 1px solid #E2E2E2 !important;
  }
  div:not(.tabs-right):not(.tabs-left) > .page-right-sidebar .nav-tabs > li:not(.active) > a:hover {
    background: transparent;
    border-color: transparent;
    border-bottom: 1px solid #B6BABD;
    color: #3D464D;
  }
  .nav-tabs > li.active > a {
    color: #555;
    cursor: default;
    background-color: #fff;
    border-bottom-color: #1B2733;
    &:hover, &:focus {
      color: #555;
      cursor: default;
      background-color: #fff;
      border-bottom-color: #1B2733;
    }
  }
  width: 231px;
  background: #fff;
  border-left: 1px solid #efefef;
  height: 100%;
  min-height: 100%;
  right: -231px;
  top: 0;
  z-index: 42;
  position: fixed;
  -webkit-transition: all .1s;
  -moz-transition: all .1s;
  -o-transition: all .1s;
  transition: all .1s;
  &.visible {
    right: 0;
  }
  &#chat-right-sidebar {
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
  }
}

.right-sidebar-top {
  background: #fff;
  display: block;
  height: 56px;
  border-bottom: 1px solid #efefef;
}

#chat-right-sidebar .right-sidebar-top {
  height: 56px;
  border-bottom: 1px solid #e5e5e5;
}

.page-right-sidebar {
  .right-sidebar-close {
    float: right;
    color: #4F5862;
    opacity: 0.6;
    font-size: 16px;
    padding: 10px 20px;
    height: 55px;
    line-height: 34px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    &:hover {
      background: transparent;
      opacity: 1;
    }
  }
  .chat-sidebar-close {
    float: left;
    color: #4F5862;
    opacity: 0.6;
    font-size: 22px;
    padding: 17px 20px;
    height: 36px;
    line-height: 22px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    &:hover {
      background: transparent;
      opacity: 1;
    }
  }
}

.right-sidebar-tabs {
  float: left;
  width: 174px;
  height: 55px;
  li {
    width: 50%;
  }
  a {
    height: 55px;
    line-height: 33px !important;
    font-size: 12px;
    color: #4F5862;
    opacity: 0.6;
    text-transform: uppercase;
    font-weight: 700 !important;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin-left: 0 !important;
  }
  .nav-tabs {
    border-bottom: 0 !important;
    > li.active > a {
      cursor: pointer;
      background-color: #fff;
      border: none;
      color: #4F5862 !important;
      opacity: 1 !important;
      &:focus, &:hover {
        cursor: pointer;
        background-color: #fff;
        border: none;
        color: #4F5862 !important;
        opacity: 1 !important;
      }
    }
  }
  > .nav-tabs > li > a {
    &:focus, &:hover {
      text-decoration: none;
      background-color: transparent;
      opacity: 1;
      border-bottom-color: transparent !important;
    }
  }
}

.chat-list .chat-item {
  overflow: hidden;
  position: relative;
  display: block;
  border-bottom: 1px solid #efefef;
  padding: 5px 0;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  &:hover {
    background: #f9f9f9;
  }
  img {
    float: left;
    width: 40px;
    border-radius: 100%;
    margin: 10px;
    margin-left: 20px;
  }
  .user-avatar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    float: left;
  }
  &.active-user .user-avatar:after {
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #44AEA1;
    position: absolute;
    bottom: 9px;
    right: 9px;
    border: 2px solid #fff;
  }
  .chat-info {
    float: left;
    overflow: hidden;
    padding: 13px 0;
    width: 100px;
    span {
      &.chat-author {
        display: block;
        color: #4F5862;
        opacity: 0.6;
        font-size: 13px;
        line-height: 15px;
        padding: 0;
        margin-bottom: 4px;
      }
      &.chat-text {
        display: block;
        color: #4F5862;
        opacity: 0.6;
        font-size: 13px;
        line-height: 15px;
        padding: 0;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &.chat-time {
        display: block;
        color: #4F5862;
        opacity: 0.6;
        font-size: 12px;
        line-height: 15px;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -7.5px;
      }
    }
  }
  &.unread .chat-info span {
    font-weight: 600;
    opacity: 1;
  }
}

.load-more-messages {
  display: block;
  text-align: center;
  width: 100%;
  margin: 15px auto;
  font-size: 14px;
  color: #4F5862;
  opacity: 0.6;
  &:hover {
    color: #4F5862;
    opacity: 1;
  }
}

.chat-top-info {
  float: left;
  padding: 10px 0px 10px 20px;
  span {
    display: block;
    &.chat-name {
      color: #555;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
    &.chat-state {
      color: #4F5862;
      font-size: 12px;
      opacity: 0.6;
    }
  }
}

.chat-more-nav {
  float: right;
  display: block;
  position: relative;
  width: 15px;
  height: 15px;
  margin: 13px 5px;
  text-align: center;
  > a {
    display: block;
    position: relative;
    font-size: 18px;
    width: 100%;
    height: 100%;
  }
}

.chat-start-date {
  width: 100%;
  color: #767676;
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
}

.page-right-sidebar-inner {
  height: 100%;
}

.right-sidebar-content {
  height: calc(100% - 56px);
  .slimScrollDiv {
    height: calc(100% - 56px) !important;
    width: 100% !important;
  }
}

.right-sidebar-chat {
  height: 100% !important;
  padding-bottom: 20px;
  display: block;
}

.chat-bubble {
  &.them .chat-bubble-img-container {
    float: left;
  }
  .chat-bubble-text-container {
    max-width: 60%;
    display: block;
  }
  &.them .chat-bubble-text-container {
    float: left;
    clear: right;
  }
  &.me .chat-bubble-text-container {
    float: right;
    clear: right;
  }
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
  }
  &.them img {
    margin-left: 20px;
  }
  span.chat-bubble-text {
    padding: 5px 10px;
    min-height: 28px;
    margin: 0 10px;
    position: relative;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.8;
    float: left;
    clear: both;
    word-wrap: break-word;
    max-width: 100%;
  }
  &.them span.chat-bubble-text {
    margin-left: 10px;
    background: $PrimaryColor;
    color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0px;
  }
  &.me span.chat-bubble-text {
    margin-left: 10px;
    margin-right: 30px;
    background: #f1f1f1;
    float: right;
    border-radius: 4px;
    border-top-right-radius: 0px;
  }
  span.chat-bubble-text {
    margin-top: 4px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 5px;
    }
    &:first-child:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  &.them span.chat-bubble-text:first-child:before {
    border-width: 0 10px 10px 0;
    border-color: transparent $PrimaryColor transparent transparent;
    position: absolute;
    top: 0;
    left: -9px;
  }
  &.me span.chat-bubble-text:first-child:before {
    border-width: 10px 10px 0 0;
    border-color: #f1f1f1 transparent transparent;
    position: absolute;
    top: 0;
    right: -9px;
  }
}

.chat-write input {
  background: transparent;
  border: 0;
  box-shadow: none;
  border-top: 1px solid #f1f1f1;
  height: 56px;
  border-radius: 0;
  padding: 0 20px;
  &:focus {
    box-shadow: none;
    border-color: #f1f1f1;
  }
}

.right-sidebar-settings .settings-title, span.chat-title {
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: block;
  margin: 5px 0 0 0;
  background: transparent;
  color: #4F5862;
  opacity: 0.6;
}

.sidebar-setting-list li {
  padding: 12px 25px;
  font-size: 13px;
  color: #4F5862;
  &:last-child {
    border-color: transparent;
  }
  .switchery {
    float: right;
  }
}

/* page left sidebar */
.page-sidebar {
  width: 240px;
  background: #2a3542;
  min-height: 100%;
  float: left;
  z-index: 100;
  position: relative;
}

.page-sidebar-fixed .page-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  height: 100% !important;
  .page-sidebar-inner {
    height: 100% !important;
    overflow: hidden;
  }
}

body:not(.page-sidebar-fixed) .page-sidebar .slimScrollDiv {
  height: auto !important;
}

.page-sidebar-fixed .page-sidebar, .page-header-fixed .page-sidebar, .page-sidebar-fixed.page-header-fixed .page-sidebar {
  padding-top: 45px;
}

.page-sidebar-collapsed {
  &.page-sidebar-fixed .page-sidebar, &.page-header-fixed .page-sidebar, &.page-sidebar-fixed.page-header-fixed .page-sidebar {
    padding-top: 45px;
  }
}

.logo-box {
  width: 240px;
  padding: 15px 20px 10px 25px;
  color: $PrimaryColor !important;
  background: #2a3542;
  font-weight: 800;
  font-size: 18px;
  display: block;
  text-decoration: none;
  z-index: 9999;
  text-transform: uppercase;
  line-height: 30px;
}

.page-header-fixed .logo-box {
  position: fixed;
  top: 0;
}

.logo-box {
  &:hover, &:active, &:link, &:focus {
    color: #fff;
    text-decoration: none;
  }
  i {
    margin-left: 12px;
    float: right;
    height: 14px;
    margin-top: 9px;
    font-size: 13px;
  }
}

.page-sidebar-fixed .logo-box {
  position: fixed;
  left: auto;
  border-bottom: none;
  z-index: 9999;
  top: 0;
  color: #fff;
}

.page-sidebar-menu {
  padding: 10px;
  .has-sub {
    &.active-page {
      > a.active, a.active i, > a.active i.accordion-icon {
        color: #fff;
      }
    }
    > a.active {
      color: $PrimaryColor;
      font-weight: 500;
    }
    a.active i {
      color: $PrimaryColor;
    }
  }
}

.page-sidebar-fixed .page-sidebar .accordion-menu {
  margin-top: 10px;
}

.page-sidebar-menu {
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
      position: relative;
      a {
        display: block;
        font-weight: 400;
        font-size: 14px;
        padding: 10px 15px;
        color: #fff;
        line-height: 1.5 !important;
        -webkit-font-smoothing: antialiased;
        position: relative;
        border-radius: 4px;
      }
      &.active-page > a i {
        color: #fff;
        &.accordion-icon {
          color: #fff;
        }
      }
    }
  }
  .accordion-menu > li.active > a {
    color: #fff;
    font-weight: 500;
    background: $PrimaryColor;
    border-radius: 4px;
  }
  ul li {
    &.active-page > a {
      color: #fff;
      font-weight: 500;
      background: $PrimaryColor;
      border-radius: 4px;
      position: relative;
    }
    a:link {
      text-decoration: none;
    }
  }
  > ul > li > a:hover {
    opacity: .8;
  }
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li > a:hover {
  opacity: 1;
}

.page-sidebar-menu {
  > ul > li > a:hover {
    text-decoration: none;
    i.accordion-icon {
      text-decoration: none;
    }
  }
  ul li {
    a {
      i.menu-icon {
        margin-right: 10px;
        font-size: 17px;
        vertical-align: middle;
        line-height: 22px;
      }
      span {
        vertical-align: middle;
      }
    }
    &.menu-divider {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $PrimaryColor;
      margin: 10px 0;
    }
  }
  li.has-sub a i.accordion-icon {
    float: right;
    font-size: 15px;
    vertical-align: text-top;
    color: #526069;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin-top: 3px;
  }
  ul li a span.label {
    margin: 3px 0;
    float: right;
  }
  li.has-sub a.active i.accordion-icon {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    color: $PrimaryColor;
    color: $PrimaryColor;
  }
  ul li .sub-menu a:hover {
    color: #fff;
  }
}

.accordion-menu {
  .sub-menu {
    display: none;
    padding-left: 22px;
    padding-bottom: 10px;
  }
  .has-sub.active-page .sub-menu {
    display: block;
  }
}

.page-sidebar-collapsed .accordion-menu .sub-menu {
  padding-bottom: 0px;
  padding-left: 0px;
}

.accordion-menu .sub-menu li {
  a {
    border-top: 0;
    padding: 8px 20px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
  }
  &.active a {
    color: $PrimaryColor;
    &:hover {
      color: $PrimaryColor !important;
    }
  }
}

.page-sidebar-collapsed .accordion-menu .sub-menu li.active a {
  color: #fff;
  &:hover {
    color: #fff !important;
  }
}

.logo-sm, #sidebar-toggle-button-close {
  display: none;
}

@media screen and (max-width: $desk-xs-screen) {
  .logo-sm {
    display: block;
    #sidebar-toggle-button {
      float: left;
      margin-top: 8px;
      margin-right: 15px;
      margin-left: 15px;
      margin-bottom: 8px;
      vertical-align: middle;
      padding: 7px 0;
      color: #fff !important;
    }
    .logo-box {
      float: left;
      padding: 2px 0;
      margin-top: 8px;
      margin-right: 15px;
      margin-left: 8px;
      margin-bottom: 8px;
      width: auto;
    }
  }
  .page-sidebar-collapsed .logo-sm .logo-box {
    height: auto;
    display: block;
    padding-left: 0px;
  }
  .page-sidebar-fixed .logo-sm .logo-box {
    position: relative;
    z-index: 40;
  }
  .page-sidebar-collapsed .logo-sm .logo-box {
    text-align: left;
    span {
      display: block;
    }
  }
  .page-sidebar-visible .page-sidebar {
    left: 0;
  }
  #fixed-sidebar-toggle-button {
    display: none;
  }
  #sidebar-toggle-button-close {
    margin-left: 12px;
    float: right;
    height: 14px;
    font-size: 18px;
    margin-top: 6px;
    display: block;
  }
  .page-sidebar-collapsed #sidebar-toggle-button-close {
    margin: 8px auto;
    float: none;
  }
  .navbar-toggle {
    border-radius: 100%;
    width: 40px;
  }
  .page-header .navbar-nav > li > a {
    font-size: 14px;
    height: auto;
  }
}

/* collapsed sidebar */
.page-sidebar-collapsed {
  .page-sidebar, .logo-box {
    width: 80px;
  }
  &.page-sidebar-fixed .page-sidebar {
    height: 100%;
  }
  .logo-box {
    padding: 15px 10px 11px 10px;
    text-align: center;
    height: 55px;
    span {
      display: none;
    }
    i {
      margin: 9px auto;
      float: none;
    }
  }
  &:not(.page-sidebar-fixed) .page-sidebar .slimScrollDiv {
    overflow: visible !important;
    height: calc(100% - 83px) !important;
  }
  .page-sidebar .page-sidebar-inner {
    height: 100%;
    overflow-y: hidden !important;
    overflow-x: visible !important;
  }
}

.page-sidebar .slimScrollBar {
  display: none !important;
}

.page-sidebar-collapsed {
  .page-sidebar-menu {
    > ul > li {
      width: 60px;
      position: relative;
      > a {
        width: 60px;
        padding: 14px 21.5px;
        position: relative;
        > i.menu-icon {
          margin-right: 20.5px;
        }
      }
      &:hover > a, > a:hover {
        color: #fff;
      }
    }
    ul li {
      .sub-menu, &.active-page .sub-menu, &.open .sub-menu {
        display: none !important;
        width: 170px;
      }
      a {
        span {
          &:not(.label) {
            padding-left: 25px;
          }
          display: none;
        }
        i.accordion-icon {
          display: none;
        }
      }
      .sub-menu {
        position: absolute;
        left: 60px;
        z-index: 9999;
        padding-bottom: 14px;
        background-color: $PrimaryColor;
        a {
          opacity: 1;
          padding: 8px 25px 8px 25px;
        }
      }
      a:hover + .sub-menu, .sub-menu:hover, &:hover a span, a:hover span {
        display: inline !important;
      }
    }
    > ul > li:hover > a {
      width: 230px;
      background: $PrimaryColor;
    }
  }
  .page-sidebar.fixed-sidebar-scroll .slimScrollDiv {
    width: 260px !important;
  }
}

/* search */
.search-form {
  left: 0;
  right: 0;
  position: absolute;
  background: #fff;
  height: 56px;
  z-index: 41;
  margin-top: -81px;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  font-weight: 400;
  opacity: 0;
  input {
    border: none;
    height: 56px;
    border-radius: 0;
    box-shadow: none;
    padding-left: 25px;
    padding-bottom: 7px;
    color: #3d464c !important;
  }
  #close-search {
    background-color: #fff;
    color: #526069;
    border: none;
    height: 44px;
    border-radius: 50%;
    box-shadow: none;
    margin: 5px 40px 6px 0;
    padding: 0 12px;
    i {
      font-size: 20px;
      vertical-align: text-top;
    }
  }
  input:focus {
    border: none;
    box-shadow: none;
  }
  #close-search {
    &:hover, &:focus {
      background-color: #f1f1f1 !important;
      opacity: 1;
      border: none;
      outline: 0;
      box-shadow: none;
    }
  }
}

.search-content {
  position: absolute;
  background: #fff;
}

.search-open .search-form {
  margin-top: 0;
  opacity: 1;
}

@media screen and (max-width: $desk-xs-screen) {
  .search-form {
    height: 65px;
  }
}

/* page content */
.page-content {
  min-height: 56px;
  vertical-align: bottom;
  background: #f6f9fc;
  width: calc(100% - 240px);
  float: right;
}

.page-sidebar-collapsed .page-content {
  width: calc(100% - 60px);
}

.page-sidebar-fixed {
  .page-content {
    margin-left: 240px;
  }
  &.page-sidebar-collapsed .page-content {
    margin-left: 80px;
  }
}

_ {
  &::-webkit-full-page-media, &:future {
    vertical-align: top;
  }
}

:root .page-content {
  vertical-align: top;
}

@media screen and (max-width: $desk-xs-screen) {
  .page-sidebar {
    position: fixed !important;
    left: -240px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  .page-content, .page-sidebar-collapsed .page-content {
    width: 100%;
  }
  .page-sidebar-fixed {
    .page-content, &.page-sidebar-collapsed .page-content {
      margin-left: 0px;
    }
  }
}


/* page inner */
.page-inner {
  position: relative;
  min-height: calc(100% - 81px);
  padding: 20px 40px 60px 30px;
  background: #f6f9fc;
  &.no-page-title {
    padding-top: 30px;
  }
  &.full-page {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    &.no-footer {
      padding-bottom: 0px;
    }
    .page-footer {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

_ {
  &::-webkit-full-page-media, &:future {
    padding-bottom: 0px;
  }
}

:root .page-inner {
  padding-bottom: 30px;
}

.page-header-fixed .page-inner {
  margin-top: 81px;
}

.page-footer {
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: $desk-xs-screen) {
  .page-error .page-inner {
    vertical-align: top;
    padding: 40px;
  }
  .page-inner:not(.login-page) {
    padding: 20px 20px 42px 20px;
  }
}

/* ===================================
    Plugins
====================================== */

/* jsTree */
.jstree-default {
  .jstree-clicked, li a:hover {
    background: #f1f1f1;
    border-radius: 0;
    box-shadow: inset 0 0 1px #999;
  }
}

.jstree-icon.jstree-themeicon.fa {
  font-family: "FontAwesome" !important;
}

/* toastr */
#toast-container > {
  div {
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid transparent;
  }
  .toast-success {
    background-color: #E8F7ED;
    color: #30B661;
    border-color: #30B661;
  }
  .toast-info {
    background-color: #D9E7F9;
    color: #2F73C8;
    border-color: #2F73C8;
  }
  .toast-warning {
    background-color: #F9E69B;
    color: #94780A;
    border-color: #94780A;
  }
  .toast-error {
    background-color: #FBDBDE;
    color: #D83945;
    border-color: #D83945;
  }
  div:hover {
    box-shadow: none;
  }
}

/* to do */
.todo-nav {
  margin-top: 10px;
}

.todo-list {
  .todo-item {
    padding: 15px;
    margin: 5px 0;
    border-radius: 0;
    background: #f7f7f7;
  }
  margin: 10px 0;
  &.only-active .todo-item {
    &.complete {
      display: none;
    }
    &:not(.complete) {
      display: block;
    }
  }
  &.only-complete .todo-item {
    &:not(.complete) {
      display: none;
    }
    &.complete {
      display: block;
    }
  }
  .todo-item.complete span {
    text-decoration: line-through;
  }
}

.remove-todo-item {
  color: #ccc;
  visibility: hidden;
  &:hover {
    color: #5f5f5f;
  }
}

.todo-item:hover .remove-todo-item {
  visibility: visible;
}

#uniform-all-complete {
  margin-left: 11px;
}


/* ===================================
    Pages
====================================== */

/* error page */
body.error-page {
  background-color: #fff;
}

.page-error {
  height: 100%;
  width: 100%;
  display: table;
  .page-content {
    width: 100%;
    height: 100%;
    display: table;
  }
  .page-inner {
    padding: 0px;
    display: table-cell;
    vertical-align: middle;
    background: #f6f9fc;
  }
  p {
    &.error-page-top-text {
      margin-bottom: 10px;
      font-size: 24px;
    }
    &.error-page-bottom-text {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
  b {
    font-size: 30px;
    color: #2b2b2b;
  }
}

.error-page-logo {
  font-size: 88px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 20px;
}

/* profile */
.user-profile-card {
  text-align: center;
}

.user-profile-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.team .team-member {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
  float: left;
  position: relative;
  .online {
    top: 5px;
    right: 5px;
  }
  img {
    width: 40px;
    float: left;
    border-radius: 50%;
    margin: 0 5px 0 5px;
  }
}

.profile-timeline ul li {
  .timeline-item-header {
    width: 100%;
    overflow: hidden;
    img {
      width: 40px;
      height: 40px;
      float: left;
      margin-right: 10px;
      border-radius: 50%;
    }
    p {
      margin: 0;
      color: #000;
      font-weight: 500;
      span {
        margin: 0;
        color: #8E8E8E;
        font-weight: normal;
      }
    }
    small {
      margin: 0;
      color: #8E8E8E;
    }
  }
  .timeline-item-post {
    padding: 20px 0 0 0;
    position: relative;
    > img {
      width: 100%;
    }
  }
}

.timeline-options {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 0 10px 0;
  a {
    display: block;
    margin-right: 20px;
    float: left;
    color: #2b2b2b;
    text-decoration: none;
    i {
      margin-right: 3px;
    }
    &:hover {
      color: $PrimaryColor;
    }
  }
}

.timeline-comment {
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
  .timeline-comment-header {
    overflow: hidden;
    img {
      width: 30px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
    p {
      color: #000;
      float: left;
      margin: 0;
      font-weight: 500;
    }
    small {
      font-weight: normal;
      color: #8E8E8E;
    }
  }
  p.timeline-comment-text {
    display: block;
    color: #2b2b2b;
    font-size: 14px;
    padding-left: 40px;
  }
}

.post-options {
  overflow: hidden;
  margin-top: 15px;
  margin-left: 15px;
  a {
    display: block;
    margin-top: 5px;
    margin-right: 20px;
    float: left;
    color: #2b2b2b;
    text-decoration: none;
    font-size: 16px !important;
    &:hover {
      color: $PrimaryColor;
    }
  }
}

.online {
  position: absolute;
  top: 2px;
  right: 2px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ccc;
  &.on {
    background: #2EC5D3;
  }
  &.off {
    background: #EC5E69;
  }
}

/* timeline */
#cd-timeline::before {
  border: 0;
  background: #f1f1f1;
}

.cd-timeline-content {
  p, .cd-read-more, .cd-date {
    font-size: 14px;
  }
}

.cd-timeline-img {
  &.cd-success {
    background: #2EC5D3;
  }
  &.cd-danger {
    background: #EC5E69;
  }
  &.cd-info {
    background: #5893DF;
  }
  &.cd-warning {
    background: #F1C205;
  }
  &.cd-primary {
    background: #9F7CE1;
  }
}

/* maps */
.page-inner.full-page {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

#map {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#vmap {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1px;
  position: relative;
  overflow: hidden;
}

.jqvmap-zoomin, .jqvmap-zoomout {
  border-radius: 4px;
  background: #f1f1f1;
  padding: 3px;
  color: #526069;
  width: 20px;
  height: 20px;
  line-height: 14px;
  left: 25px;
}

.jqvmap-zoomin:hover, .jqvmap-zoomout:hover {
  background: #526069;
  color: #fff;
}

/* authentication */
.login-page {
  width: 100%;
  height: 100%;
  background: url('../img/screen-bg.jpg') no-repeat center center fixed;
  background-size: cover;
  padding: 0px;
}

.login-box {
  width: 300px;
  height: 100vh;
  background: #fff;
  padding: 35px;
}

.login-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 35px;
  span {
    margin-left: 5px;
    font-size: 12px;
    opacity: .7;
  }
}

.forgot-link {
  margin-top: 10px;
  display: inline-block;
}

.lock-screen {
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
  }
  p {
    margin-bottom: 20px;
    display: block;
    span {
      display: inline-block;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $tablet-sm-screen) {
  .login-box {
    width: 100%;
  }
}

/* pricing tables */
.pricing {
  text-align: center;
  border: 1px solid #E5E9EC;
  font-size: 14px;
  padding-left: 0;
  li {
    &:first-child, &:last-child {
      padding: 20px 13px;
    }
    list-style: none;
    padding: 12px 8px;
    + li {
      border-top: 1px solid #E5E9EC;
    }
  }
  h1 {
    font-size: 24px;
    color: #2b2b2b;
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 0;
    font-size: 32px;
  }
  span {
    font-size: 12px;
    color: #999;
    font-weight: normal;
  }
  li:nth-child(2) {
    padding: 30px 13px;
  }
}

/* charts */
#nvd1, #nvd2, #nvd3, #nvd4, #nvd5, #nvd6, #flot1, #flot2 {
  height: 400px;
}

#flot3, #flot4 {
  height: 275px;
}

/* email */
.email-list {
  max-height: 590px;
  overflow-y: auto;
  ul {
    margin-bottom: 0px;
  }
}

.email-list-item {
  .email-author {
    img {
      width: 35px;
      border-radius: 100%;
    }
    span {
      &.author-name {
        margin-left: 7px;
        font-weight: bold;
        line-height: 25px;
      }
      &.email-date {
        float: right;
        opacity: .6;
        line-height: 25px;
        font-size: 12px;
      }
    }
  }
  .email-info {
    display: block;
    margin-top: 8px;
    .email-subject {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
    .email-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      opacity: .7;
    }
  }
}

.email-list li a {
  &:active, &:focus {
    color: #2b2b2b;
  }
}

.email-menu li a {
  &:active, &:focus {
    color: #2b2b2b;
  }
}

.email-list li {
  a {
    display: block;
    padding: 18px 12px;
    border-bottom: 1px solid #E0E0E0;
    &:hover {
      background: #f1f1f1;
    }
  }
  &.active a {
    background: #ececec;
  }
}

.email-actions .compose {
  width: 100%;
}

.divider {
  height: 1px;
  display: block;
  width: 100%;
  margin: 10px 0;
  background: #E0E0E0;
}

.email-menu {
  margin-top: 20px;
  ul li {
    a {
      display: block;
      padding: 5px 8px;
      &:hover {
        color: $PrimaryColor;
      }
    }
    &.active a {
      color: $PrimaryColor;
    }
    a {
      i {
        margin-right: 10px;
        width: 10px;
        font-size: 16px;
        vertical-align: middle;
        line-height: 30px;
      }
      span {
        line-height: 30px;
        vertical-align: middle;
      }
    }
  }
}

.email {
  margin-top: 20px;
  .email-title {
    font-size: 15px;
    font-weight: bold;
  }
  .email-author {
    img {
      width: 35px;
      border-radius: 100%;
    }
    span {
      &.author-name {
        margin-left: 7px;
        line-height: 25px;
      }
      &.email-date {
        float: right;
        opacity: .6;
        line-height: 25px;
        font-size: 12px;
      }
    }
  }
  .email-reply {
    margin-top: 20px;
    display: block;
    .btn-default {
      color: #3d464c;
      border-color: #3d464c;
      &:hover, &:active, &.active, &:focus {
        color: #3d464c !important;
        border-color: #3d464c !important;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .email-actions {
    margin-top: 20px;
  }
}

/* calendar */
.fc-event {
  color: #fff !important;
  cursor: move;
  &:hover {
    color: #fff !important;
    cursor: move;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .fc-toolbar {
    display: block;
    text-align: center;
    .fc-left, .fc-center {
      width: 100%;
      margin-bottom: 15px;
    }
    .fc-right {
      width: 100%;
    }
  }
}

/* grid gallery */
@media screen and (max-width: $desk-xs-screen) {
  .grid-gallery figcaption h3 {
    font-size: 13px;
    font-weight: bold;
  }
}

/* dashboard */
.stats-widget {
  .stats-number {
    color: #526069;
    font-size: 25px;
  }
  .stats-info {
    margin: 0px;
    color: #526069;
  }
  .stats-icon {
    font-size: 2em;
    margin-left: 10px;
    vertical-align: top;
  }
  .success.stats-icon {
    color: #63CB89;
  }
  .danger.stats-icon {
    color: #EC5E69;
  }
  .warning.stats-icon {
    color: #F1C205;
  }
}

#chart1 {
  height: 265px;
}

#chart2 {
  height: 255px;
}

.server-load {
  > .server-stat {
    display: inline-block;
    margin-bottom: 5px;
    padding-left: 0;
  }
  .server-stat {
    span {
      font-size: 13px;
      margin-top: 3px;
      font-weight: 400;
      color: #aaa;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0;
    }
  }
}

.task-list .task-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 9px 5px 12px 5px;
  &:last-child {
    border-bottom: none;
  }
  .progress {
    margin-bottom: 0px;
  }
  .task-name {
    font-size: 13px;
    margin-bottom: 10px;
    color: #526069;
    display: block;
    i {
      margin-left: 5px;
      color: #526069;
    }
  }
}

.invoice-table table {
  margin-bottom: 0px;
}

.browser-stats ul, .project-stats ul {
  margin: 0;
  li {
    border-bottom: 1px solid #eee;
    padding: 11px 0;
    &:last-child {
      border-bottom: 0;
    }
    i {
      margin-right: 10px;
      width: 20px;
      text-align: center;
    }
    div i {
      margin-left: 5px;
      margin-right: 0px;
    }
  }
}

.grid-icons {    
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 12px;
  text-align: center;
  display: inline-block;
}

.grid-icon .blue {
  border: 1px solid #2154f5;
  color: #2154f5;
}

.grid-icon .red {
  border: 1px solid #f00;
  color: #f00;
}

.grid-icon .yellow {
  border: 1px solid #f8f843;
  color: #f8f843;
}