.jobPostinglinks {
    color: #507dbd;
    line-height: 1.5em;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
}

.jobPostinglinksBreadcom {
    color: #507dbd;
    line-height: 1.5em;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    margin: 0 10px 0 10px;
    text-decoration: underline;
}

.jobPostingProfilesImageClass {
    width: 40px;
    margin-right: 5px;
}