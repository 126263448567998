/*-----------------------------------------------------------------------------------

    Theme Name: Fabrex - Multipurpose Business and Admin Template
    Description: Multipurpose Business and Admin Template
    Author: Chitrakoot Web
    Version: 3.4

-----------------------------------------------------------------------------------*/
/* ----------------------------------
        
    01. Common styles
    02. Section heading
    03. Topbar
    04. Navigation
    05. Page title
    06. Slider and Banner
    07. Feature boxes
    08. Service blocks
    09. Process with Icon
    10. Client section
    11. Counter box
    12. Call to action
    13. Video
    14. About area style
    15. Progress bars
    16. Testmonial
    17. Owl carousel
    18. Team member
    19. Project grid
    20. Accordion style
    21. Service detail
    22. Portfolio masonry
    23. Tabs
    24. Pricing table
    25. Shop
    26. Blog block
    27. Blog 
    28. Count and error box
    29. Contact info
    30. Others
    31. Copy Elements
    32. Footer
    
---------------------------------- */

$PrimaryColor: #86bc42;
$PrimaryRgbColor: rgb(134, 188, 66);

$desk-lg-screen: 1200px;
$desk-md-screen: 1199px;
$desk-small-screen: 1024px;
$desk-md-sm-screen: 1023px;
$desk-sm-screen: 992px;
$desk-xs-screen: 991px;
$tablet-sm-screen: 768px;
$tablet-xs-screen: 767px;
$tablet-small-screen: 766px;
$mobile-md-screen: 576px;
$mobile-sm-screen: 575px;
$mobile-xs-screen: 479px;

/* ===================================
    Common styles
====================================== */
/* loader */
#preloader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
  transition: 0.3s ease opacity;
  text-align: center;
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    width: 80px;
    height: 80px;
    border: 3px solid $PrimaryColor;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: LoaderCicle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &:after {
    content: "";
    width: 80px;
    height: 80px;
    border: 3px solid $PrimaryColor;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: LoaderCicle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s;
  }
}

@keyframes LoaderCicle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  10% {
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  80% {
    width: 60px;
    height: 60px;
    opacity: 0.1;
  }

  100% {
    width: 70px;
    height: 70px;
    opacity: 0;
  }
}

/* theme color and basic options */
a {
  &:hover, &:active {
    color: $PrimaryColor;
    text-decoration: none;
  }
}

.bg-primary {
  background-color: $PrimaryColor !important;
}

.bg-primary-90 {
  background: rgba($PrimaryRgbColor, 0.9);
}

.bg-primary-80 {
  background: rgba($PrimaryRgbColor, 0.8);
}

.theme-overlay {
  &[data-overlay-dark]:before, &[data-overlay-light]:before {
    background: $PrimaryColor;
  }
}

.text-primary {
  color: $PrimaryColor !important;
}

.text-content-big p {
  font-weight: 400;
  line-height: 200%;
  margin-bottom: 25px;
  font-size: 15px;
}

.shadow-primary {
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.red {
  color: red;
}

.line-through {
  text-decoration: line-through;
}

.readmore {
  font-weight: 700;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;

  &:after {
    content: '\f105';
    font-size: 16px;
    vertical-align: top;
    font-family: Font Awesome\ 5 Free;
    padding-left: 8px;
    vertical-align: middle;
  }

  &:hover {
    color: $PrimaryColor;
  }
}

.left-title {
  position: relative;

  &:before {
    content: "";
    background: $PrimaryColor;
    width: 15px;
    height: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 10px;
  }

  &.white:before {
    background: #fff;
  }
}

/* border for inner box */
.inner-border {
  border: 8px solid rgba(255, 255, 255, 0.35);
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  left: 20px;
  top: 20px;
  position: absolute;
}

.inner-border2 {
  border: 8px solid rgba(255, 255, 255, 0.35);
  height: calc(100% - 80px);
  width: calc(100% - 110px);
  left: 55px;
  top: 40px;
  position: absolute;
}

.w-200px {
  width: 200px;
}

.line-height-1 {
  line-height: 1;
}

.position-right {
  right: 0 !important;
}

.position-bottom {
  bottom: 0 !important;
}

/* primary listing */
.primary-list {
  padding-left: 0;

  li {
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    list-style-type: none;
    color: #222;

    i {
      color: $PrimaryColor;
      padding-right: 10px;
    }
  }
}

.white-list {
  padding-left: 0;

  li {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    list-style-type: none;

    i {
      color: $PrimaryColor;
      padding-right: 15px;
    }
  }
}

.list-style-16 li:after {
  color: $PrimaryColor;
}

.white-list {
  padding-left: 0;

  li {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    list-style-type: none;

    i {
      color: $PrimaryColor;
      padding-right: 15px;
    }
  }
}

/* scroll to top */
.scroll-to-top {
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 50%;
  background: #000;
  border: 1px solid #2a2a2a;
  width: 35px;
  height: 35px;
  line-height: 30px;
  z-index: 9999;
  outline: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all 0.3s ease;

  i {
    color: #fff;
  }

  &:hover {
    color: #232323;
    background: #fff;

    i {
      color: #232323;
    }
  }

  &:visited {
    color: #232323;
    text-decoration: none;
  }
}

/* Button style */
.butn {
  background: #232323;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 12px 30px !important;
  line-height: normal;
  font-weight: 500;
  text-transform: none !important;
  position: relative;
  // z-index: 9999;
  display: inline-block;
  white-space: nowrap;
  border: none;
  cursor: pointer;

  span {
    z-index: 9;
    position: relative;
  }

  &:hover,&:active,&:focus {
    color: #fff;
    border-radius: 3px;
  }

  &:after {
    content: '';
    position: absolute;
    border-radius: 4px;
    bottom: 6px;
    left: 0;
    height: 2px;
    -webkit-transition: width .4s;
    -o-transition: width .4s;
    transition: width .4s;
    width: 0;
    background: $PrimaryColor;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  &:hover:after,&:active:after,&:focus:after {
    width: 100%;
    border-radius: 3px;
  }

  &.white {
    background: #fff;
    color: #232323;

    &:hover,&:active,&:focus {
      color: #fff;
      border-radius: 3px;
    }

    &:after {
      background: #232323;
      border-radius: 3px;
    }
  }

  &.primary {
    background: $PrimaryColor;
    color: #fff;

    span {
      color: #fff;
    }

    &:hover, &:active, &:focus {
      color: #fff;
      border-radius: 3px;
    }

    &:after {
      background: #232323;
      border-radius: 3px;
    }

    &.white-hover {
      &:hover,&:active,&:focus {
        color: #232323;

        span {
          color: #232323;
        }
      }

      &:after {
        background: #fff;
        border-radius: 3px;
      }
    }
  }

  &.small {
    padding: 8px 20px !important;
  }

  &.medium {
    padding: 10px 20px !important;
  }
}

/* Button Style 2*/
.btn-style2 {
  display: inline-block;
  font-size: 14px;
  padding: 12px 20px;
  border: 2px solid #222;
  color: #232323;
  letter-spacing: .5px;
  line-height: 26px;
  border-radius: 0;
  text-transform: uppercase;
  width: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:hover, &:active, &:focus {
    background: $PrimaryColor;
    color: #fff;
    border-color: $PrimaryColor;
  }

  &:focus {
    background: $PrimaryColor;
    color: #fff;
    box-shadow: none;
    border-color: $PrimaryColor;
  }

  &.white {
    border-color: #fff;
    color: #fff;

    &:hover, &:active, &:focus {
      border-color: $PrimaryColor;
    }
  }

  span {
    text-shadow: none !important;
    color: #fff !important;
  }
}

/* Button Style 3*/
.butn-style3 {
  text-align: center;
  display: inline-block;
  font-size: 14px;
  padding: 9px 22px;
  min-width: 150px;
  background: transparent;
  border: 2px solid $PrimaryColor;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: $PrimaryColor;
  letter-spacing: .5px;
  line-height: inherit;
  width: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:hover, &:active, &:focus {
    background: $PrimaryColor;
    color: #fff;
  }

  &.primary {
    background: $PrimaryColor;
    border: 2px solid $PrimaryColor;
    color: #fff;

    &:hover, &:active, &:focus {
      background: transparent;
      color: #fff;
      border-color: #fff;
    }
  }

  &.white {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
  }

  &:hover, &:active, &:focus {
    background: $PrimaryColor;
    color: #fff;
    border-color: $PrimaryColor;
  }
}

/* Button Style 4*/
.btn-style4 {
  display: inline-block;
  border: 2px solid transparent;
  line-height: inherit;
  text-transform: uppercase;
  font-weight: 600;
  min-width: 175px;
  z-index: 1;
  overflow: hidden;
  padding: 15px 10px;
  border-radius: 0;
  border: none;
  letter-spacing: .5px;
  font-family: "Montserrat", sans-serif;
  background-color: $PrimaryColor;
  display: inline-block;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &.btn-very-small {
    font-size: 9px;
    padding: 1px 17px;
    line-height: 22px;
  }

  &.btn-small {
    font-size: 11px;
    padding: 4px 24px;
  }

  &.btn-medium {
    font-size: 12px;
    padding: 12px 25px 11px;
  }

  &.btn-large {
    font-size: 13px;
    padding: 9px 34px;
    line-height: 25px;
  }

  &.btn-extra-large {
    font-size: 15px;
    padding: 12px 40px 13px;
    line-height: 25px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    height: 1000%;
    background: rgba(255, 255, 255, 0.3);
    z-index: -1;
    transform-origin: 0% 0%;
    transform: translateX(calc(20% - 20px)) translateY(10%) rotate(-45deg);
    transition: transform 0.3s;
  }

  &:hover:after, &:active:after, &:focus:after {
    transform: translateY(10%) translateX(-20px) rotate(-45deg);
  }

  i {
    margin-left: 6px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }

  &:hover, &:active, &:focus {
    background-color: #000 !important;
    color: #fff !important;
    box-shadow: none !important;
    border: none !important;
  }

  &.min-width-auto {
    min-width: auto;
  }

  &.btn-primary {
    background-color: #000;
    color: #fff;

    &:hover, &:active, &:focus {
      background-color: $PrimaryColor !important;
      color: #fff !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .butn {
    padding: 10px 28px;

    &.small {
      padding: 7px 16px;
    }

    &.medium {
      padding: 8px 18px;
    }
  }

  .btn-style2 {
    padding: 10px 18px;
  }

  .butn-style3 {
    min-width: 130px;
  }

  .btn-style4 {
    padding: 10px 10px;
    min-width: 150px;
    font-size: 13px;
    letter-spacing: 1px;

    &.btn-medium {
      padding: 10px 22px 9px;
    }

    &.btn-large {
      padding: 8px 32px;
      line-height: 24px;
    }

    &.btn-extra-large {
      padding: 10px 36px 12px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .butn {
    padding: 8px 26px;

    &.small {
      padding: 6px 14px;
    }

    &.medium {
      padding: 6px 16px;
    }
  }

  .btn-style2 {
    padding: 8px 14px;
  }

  .butn-style3 {
    min-width: inherit;
  }

  .btn-style4 {
    padding: 8px 8px;
    min-width: 140px;
    font-size: 12px;
    letter-spacing: 1px;

    &.btn-medium {
      padding: 10px 18px 8px;
    }

    &.btn-large {
      font-size: 12px;
      padding: 8px 32px;
      line-height: 24px;
    }

    &.btn-extra-large {
      font-size: 14px;
      padding: 10px 36px 12px;
      line-height: 24px;
    }
  }
}

.list-style-1 li {
  padding: 5px 0;
}

/* list style 2 */
.list-style-2 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 0 12px;
    margin: 0 0 9px 0;

    &:before {
      content: "";
      width: 5px;
      height: 1px;
      background-color: #232323;
      position: absolute;
      top: 12px;
      left: 0;
    }
  }
}

/* list style 3 */
.list-style-3 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 0 22px;
    margin: 0 0 15px 0;

    &:before {
      content: "";
      width: 10px;
      height: 1px;
      background-color: #232323;
      position: absolute;
      top: 13px;
      left: 0;
    }
  }
}

/* list style 4 */
.list-style-4 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 10px 22px;
    margin: 0 0 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:before {
      content: "\f152";
      position: absolute;
      top: 0;
      left: 0;
      font-family: Font Awesome\ 5 Free;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &.list-style-color li {
    border-color: rgba(255, 255, 255, 0.15);

    &:before {
      color: #ff214f;
    }
  }
}

/* list style 5 */
.list-style-5 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 10px 25px;
    margin: 0 0 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:before {
      content: "\f192";
      position: absolute;
      top: 0;
      left: 0;
      font-family: Font Awesome\ 5 Free;
      color: #c0c0c0;
    }

    &:first-child {
      padding-top: 10px;

      &:before {
        top: 11px;
      }
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  &.list-style-color li {
    border-color: rgba(255, 255, 255, 0.15);

    &:before {
      color: #232323;
    }
  }
}

/* list style 6 */
.list-style-6 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 15px 8px 0;
    margin: 0 0 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    span {
      position: absolute;
      top: 3px;
      right: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: "";
    }
  }
}

/* list style 7 */
.list-style-7 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0 0 13px 0;
    margin: 0 0 10px 0;

    &:last-child {
      margin: 0;
      padding: 0;
    }
  }
}

/* list style 8 */
.list-style-8 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 15px 0;
    margin: 0 0 4px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    span {
      margin-bottom: 2px;
    }

    p {
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: "";
    }
  }
}

/* list style 9 */
.list-style-9 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 15px 0;
    margin: 0 0 4px 0;
    border-bottom: 1px solid #373737;

    span {
      margin-bottom: 2px;
    }

    p {
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: "";
    }
  }
}

/* list style 10 */
.list-style-10 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    i {
      float: left;
      margin-top: 5px;
    }

    span {
      padding-left: 15px;
      display: table;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

/* list style 11 */
.list-style-11 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 8px 0;
    margin: 0 0 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    span {
      position: absolute;
      top: 3px;
      right: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: "";
    }
  }
}

/* list style 12 */
.list-style-12 {
  position: relative;

  li {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;

    i {
      left: 0;
      line-height: normal;
      position: absolute;
      top: 2px;
    }
  }
}

/* list style 13 */
.list-style-13 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 3px 25px;
    margin: 0 0 3px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:before {
      content: "\f192";
      position: absolute;
      top: 0;
      left: 0;
      font-family: Font Awesome\ 5 Free;
      color: #c0c0c0;
    }

    &:first-child {
      padding-top: 5px;

      &:before {
        top: 6px;
      }
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

/* list style 14 */
.list-style-14 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 5px 25px;
    margin: 0 0 5px 0;

    &:before {
      content: "\f192";
      position: absolute;
      top: 0;
      left: 0;
      font-family: Font Awesome\ 5 Free;
      color: #c0c0c0;
    }

    &:first-child {
      padding-top: 5px;

      &:before {
        top: 6px;
      }
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

/* list style 16 */
.list-style-16 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:after {
      content: '\f14a';
      font-family: Font Awesome\ 5 Free;
      position: absolute;
      left: 0px;
      top: 1px;
      z-index: 1;
      color: #232323;
    }

    &:hover {
      padding-left: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* ===================================
    Section heading
====================================== */
.section-heading {
  margin-bottom: 50px;
  text-align: center;

  &.half {
    margin-bottom: 30px;
  }

  h1, h2, h3, h4, h5, h6 {
    position: relative;
    padding-bottom: 10px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    line-height: 140%;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background: $PrimaryColor;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.white {
    h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }
  }

  &.grey {
    h1, h2, h3, h4, h5, h6 {
      color: #b7b7b7;
    }

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      background: #b7b7b7;
    }
  }

  &.left {
    text-align: left;

    h1, h2, h3, h4, h5, h6 {
      padding-bottom: 15px;
    }

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      margin: 0;
      left: 0;
    }
  }

  strong {
    font-weight: 600;
  }

  &.white p {
    color: #fff;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    padding-top: 15px;
    margin: 0 auto;
  }

  span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: $PrimaryColor;
    line-height: 200%;
    margin-bottom: 10px;
  }

  &.white span {
    color: #fff;
  }

  &.grey span {
    color: #b7b7b7;
  }

  .badge {
    display: inline-block;
    padding: 7px 19px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 14px;
    color: $PrimaryColor;
    border: 1px solid $PrimaryColor;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: .075em;
  }
}

/* title style2 */
.title-style2 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 0;

  &:after {
    content: '';
    width: 60px;
    height: 2px;
    background: $PrimaryColor;
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
  }

  &:before {
    content: '';
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 250px;
  }
}

.section-heading.left .title-style2 {
  left: 0;
  margin: 0;

  &:before {
    width: 125px;
    left: 0;
    margin: 0;
  }

  &:after {
    width: 50px;
    left: 37px;
  }
}

h1.title-style2.white, h2.title-style2.white, h3.title-style2.white, h4.title-style2.white, h5.title-style2.white, h6.title-style2.white {
  color: #fff;
}

.section-heading .title-style2.white {
  &:after {
    background: $PrimaryColor;
  }

  &:before {
    background: rgba(255, 255, 255, 0.8);
  }
}

/* title style3 */
.section-heading.title-style3 {
  text-align: left;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: none;
  }
}

/* title overlay */
.title-overlay {
  mix-blend-mode: lighten;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.85);
  margin: 0;
}

/* title style4 */
.section-heading.title-style4 {
  h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  p {
    padding-top: 0;
  }

  span {
    color: $PrimaryColor;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: none;
  }
}

/* title style5 */
.section-heading.title-style5 {
  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
  }

  &.white {
    h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: none;
  }

  .separator-left, .separator-right {
    height: 1px;
    position: absolute;
    top: 50%;
    width: 70px;
    margin-top: -1px;
    margin-bottom: 0;
  }

  .separator-left {
    left: -80px;
  }

  .separator-right {
    right: -80px;
  }

  .square {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $PrimaryColor;
    display: inline-block;
    vertical-align: text-bottom;
    position: relative;
  }

  &.left .square .separator-left {
    display: none;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .section-heading.title-style4 span {
    font-size: 30px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .section-heading {
    margin-bottom: 30px;

    p {
      padding-top: 10px;
      font-size: 14px;
    }

    &.title-style4 span {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .section-heading.title-style4 span {
    font-size: 28px;
  }
}

/* title style6 */
.section-heading.title-style6 {
  h1, h2, h3, h4, h5, h6 {
    position: relative;
    padding-bottom: 10px;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    line-height: 140%;
    text-transform: inherit;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    background: $PrimaryColor;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  span {
    color: #232323;
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
  }

  &.white span {
    color: #fff;
  }

  &.left {
    text-align: left;

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      margin: 0;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .section-heading.title-style6 span {
    font-size: 30px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .section-heading.title-style6 span {
    font-size: 28px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .section-heading.title-style6 span {
    font-size: 26px;
  }
}

/* title style7 */
.inner-title {
  h1, h2, h3, h4, h5, h6 {
    font-size: 20px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 600;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background: $PrimaryColor;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.half {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 15px;
    }
  }
}

/* title-style8 */
.title-style8 {
  padding-bottom: 25px;
  padding-left: 40px;
  padding-top: 25px;
  position: relative;
  font-weight: 700;
  z-index: 1;

  &:before {
    border: 5px solid #f3f3f3;
    bottom: 0;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 150px;
    z-index: -1;
  }
}

/* ===================================
    Topbar
====================================== */
.top-bar-info {
  display: inline-block;
  vertical-align: middle;

  ul {
    margin-bottom: 0;
  }

  li {
    font-weight: 500;
    color: #fff;
    list-style-type: none;
    font-size: 13px;
    padding: 0 5px 0;
    display: inline-block;
    margin-bottom: 0;
  }
}

#top-bar {
  display: block;
  position: relative;
  z-index: 999;
  background: #1c1c1c;
  padding: 7px 0;
}

.top-bar-info li i {
  font-size: 16px;
  color: $PrimaryColor;
  margin-right: 8px;
  margin-top: 0;
  display: inline-block;
  vertical-align: text-bottom;
}

.top-social-icon {
  padding: 0;
  float: right;
  margin: 0;

  li {
    font-size: 14px;
    list-style-type: none;
    float: left;
    text-align: center;
    margin: 0;
    padding: 0 7px;

    &:last-child {
      padding-right: 0;

      a {
        padding-right: 0;
      }
    }

    a {
      color: #fff;
      line-height: 28px;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
      padding: 0 3px;

      &:hover {
        color: $PrimaryColor;
      }
    }
  }
}

#top-bar.top-bar-style2 {
  background: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.top-bar-style2 {
  .top-bar-info li {
    color: #6f6f6f;

    i {
      color: #6f6f6f;
    }
  }

  .top-social-icon li a {
    color: #6f6f6f;

    &:hover {
      color: #232323;
    }
  }
}

/* ===================================
    Navigation
====================================== */
.navbar-nav li {
  &.current > a, &.active > a {
    color: $PrimaryColor;
  }
}

.navbar > ul > li.current > a:after {
  border-color: transparent $PrimaryColor $PrimaryColor transparent;
}

.search-form i {
  font-size: 1.2rem;
  line-height: 2rem;
}

.search-form_submit {
  margin-top: 12px;
}

@media screen and (min-width: $desk-sm-screen) {
  /*Header style01 and style02*/
  .header-style1 .navbar-nav li.current > a, .header-style2 .navbar-nav li.current > a, .header-style1 .navbar-nav li.active > a, .header-style2 .navbar-nav li.active > a {
    color: $PrimaryColor;
  }

  .header-style1 .navbar > ul > li.current > a:after, .header-style2 .navbar > ul > li.current > a:after {
    border-color: transparent $PrimaryColor $PrimaryColor transparent;
  }

  .header-style1.scrollHeader .navbar-nav li.current > a, .header-style2.scrollHeader .navbar-nav li.current > a, .header-style1.scrollHeader .navbar-nav li.active > a, .header-style2.scrollHeader .navbar-nav li.active > a, .header-style1.scrollHeader .navbar-nav li.current > a:hover, .header-style2.scrollHeader .navbar-nav li.current > a:hover, .header-style1.scrollHeader .navbar-nav li.active > a:hover, .header-style2.scrollHeader .navbar-nav li.active > a:hover {
    color: $PrimaryColor;
  }

  .header-style1.scrollHeader .navbar > ul > li.current > a:after, .header-style2.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent $PrimaryColor $PrimaryColor transparent;
  }

  .header-style1.scrollHeader .butn.primary.white-hover:after { background: #232323; }
  .header-style1.scrollHeader .butn.primary.white-hover span { color: #fff; }

  /*Header style03*/
  .header-style3 {
    border-bottom: 2px solid rgba($PrimaryRgbColor, 0.9);
  }

  /*Header style04*/
  .header-style4 {
    .navbar-nav li.current > a {
      color: $PrimaryColor;
    }

    .navbar > ul > li.current > a:after {
      border-color: transparent $PrimaryColor $PrimaryColor transparent;
    }

    .navbar-nav li.active > a {
      color: $PrimaryColor;
    }
  }

  /*Header style05*/
  .header-style5 #top-bar {
    background: $PrimaryColor;
  }

  /*Header style06*/
  .header-style6 {
    .navbar-default {
      background: rgba($PrimaryRgbColor, 0.85);
    }

    &.scrollHeader .navbar-default {
      background: $PrimaryColor;
    }
  }

  /*Header style08*/
  .header-style8 {
    .navbar ul ul li.active > a {
      color: $PrimaryColor;
    }

    .navbar-nav li {
      &.current > a, &.active > a {
        color: $PrimaryColor;
      }
    }

    .navbar > ul > li.current > a:after {
      border-color: transparent $PrimaryColor $PrimaryColor transparent;
    }

    &.scrollHeader {
      .navbar-nav {
        > li.has-sub > a:hover {
          color: $PrimaryColor;
        }

        li.current > a {
          color: $PrimaryColor;

          &:hover {
            color: $PrimaryColor;
          }
        }
      }

      .navbar > ul > li.current > a:after {
        border-color: transparent $PrimaryColor $PrimaryColor transparent;
      }

      .navbar-nav li.active > a {
        color: $PrimaryColor;

        &:hover {
          color: $PrimaryColor;
        }
      }
    }
  }

  /*Menu area light*/
  .menu_area-light {
    .navbar-nav li {
      &.current > a, &.active > a {
        color: $PrimaryColor;
      }
    }

    .navbar > ul > li.current > a:after {
      border-color: transparent $PrimaryColor $PrimaryColor transparent;
    }

    &.scrollHeader {
      .navbar-nav li.current > a {
        color: $PrimaryColor;

        &:hover {
          color: $PrimaryColor;
        }
      }

      .navbar > ul > li.current > a:after {
        border-color: transparent $PrimaryColor $PrimaryColor transparent;
      }

      .navbar-nav li.active > a {
        color: $PrimaryColor;

        &:hover {
          color: $PrimaryColor;
        }
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {

  .attr-nav {
    margin-right: 63px;
  }

  .menu_area .navbar-brand {
    display: block;
  }

  /*Header style03*/
  .header-style3 {
    border-bottom: 2px solid rgba($PrimaryRgbColor, 0.9);
  }

  /*Header style05*/
  .header-style5 {
    #top-bar, .navbar-toggler {
      background: $PrimaryColor;
    }
  }

  /*Header style06*/
  .header-style6 .navbar-default {
    background: rgba($PrimaryRgbColor, 0.85);
  }

  /*Header style08*/
  .header-style8 .navbar ul ul li.active > a {
    color: $PrimaryColor;
  }
}

/* ===================================
    Page title
====================================== */
/*style1*/
.page-title-section {
  background-size: cover;
  background-position: center;

  h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    color: #fff;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: left;

    li {
      display: inline-block;
      vertical-align: middle;

      &:last-child a {
        color: $PrimaryColor;
      }

      &:after {
        content: '\f105';
        font-weight: 700;
        vertical-align: middle;
        color: #fff;
        font-family: Font Awesome\ 5 Free;
        padding: 0 8px 0 10px;
        font-size: 12px;
      }

      &:last-child:after {
        content: none;
      }

      a {
        color: #fff;
        font-weight: 500;
      }
    }
  }

  .active a, li.active:last-child a {
    color: #fff;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .page-title-section h1 {
    font-size: 36px;
    line-height: 40px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-title-section h1 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .page-title-section {
    h1 {
      margin-bottom: 10px;
      font-size: 28px;
      line-height: 36px;
    }

    ul {
      margin-top: 5px;
    }
  }
}

/*style2*/
.page-title-section2 {
  padding: 250px 0 150px;
  text-align: center;

  h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    color: #fff;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center;

    li {
      display: inline-block;

      &:last-child a {
        color: $PrimaryColor;
      }

      &:after {
        content: '\f105';
        font-weight: 700;
        vertical-align: middle;
        color: #fff;
        font-family: Font Awesome\ 5 Free;
        padding: 0 5px 0 10px;
        font-size: 12px;
      }

      &:last-child:after {
        content: none;
      }

      a {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .active a, li.active:last-child a {
    color: #fff;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .page-title-section2 {
    padding: 200px 0 100px;

    h1 {
      font-size: 36px;
      line-height: 42px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-title-section2 {
    padding: 148px 0 75px;

    h1 {
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 40px;
    }

    ul {
      margin-top: 5px;
    }
  }
}

/*style3*/
.page-title-section3 {
  h1 {
    color: #232323;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center;

    li {
      display: inline-block;

      &:last-child a {
        color: #232323;
      }

      &:after {
        content: ' \f152';
        color: #fff;
        font-weight: 700;
        font-family: Font Awesome\ 5 Free;
        padding: 0 10px;
      }

      &:last-child:after {
        content: none;
      }

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-title-section3 ul {
    margin-top: 5px;
  }
}

/*style4*/
.page-title-section.small {
  h1 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: normal;
    color: #fff;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 6px;
    text-align: left;

    li {
      display: inline-block;

      &:last-child a {
        color: $PrimaryColor;
      }

      &:after {
        content: ' \f152';
        color: #fff;
        font-weight: 700;
        font-family: Font Awesome\ 5 Free;
        padding: 0 10px;
      }

      &:last-child:after {
        content: none;
      }

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .page-title-section.small {
    h1 {
      margin-bottom: 5px;
      font-size: 24px;
    }

    ul {
      margin-top: 5px;
    }
  }
}

/* vertical-header */
.vertical-header {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 56px;

  &.new-work-vertical-header {
    padding-bottom: 120px;
  }
}

.vertical-header-text {
  left: -124px;
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  text-align: center;
  transform: rotate(-90deg);
  width: 300px;
  font-size: 14px;
  text-transform: uppercase;

  &:after {
    background: $PrimaryColor;
    content: "";
    display: inline-block;
    height: 1px;
    left: 32px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 20px;
  }

  &:before {
    background: $PrimaryColor;
    content: "";
    display: inline-block;
    height: 1px;
    left: 247px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 20px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .vertical-header {
    display: none !important;
  }
}

/* ===================================
    Slider and Banner
====================================== */
/* Banner */
.owl-carousel .caption {
  .overflow-hidden {
    display: inline-block;
  }

  h3 {
    font-weight: 200;
    animation-delay: 0.5s;
    position: relative;
    display: inline-block;
  }

  h1 {
    margin: 10px 0;
    font-size: 70px;
    font-weight: 500;
    text-transform: uppercase;
    animation-delay: 0.8s;
  }

  p {
    font-size: 18px;
    color: #eee;
    word-spacing: 2px;
    animation-delay: 1.2s;
  }

  span {
    display: inline-block;
    padding: .2em 0;
  }

  .butn {
    animation-delay: 1.4s;

    i {
      position: relative;
      top: 1px;
      z-index: 9;
    }
  }
}

/* carousel-style4 */
.carousel-style4 .owl-carousel .owl-item img {
  width: 100%;
}

/*home-business-slider*/
.home-business-slider .owl-nav {
  .owl-next {
    position: absolute;
    right: 15px;
    top: 50%;
    background: none !important;
    opacity: 0.35;

    &:hover {
      opacity: 1;
    }
  }

  .owl-prev {
    position: absolute;
    left: 15px;
    top: 50%;
    background: none !important;
    opacity: 0.35;

    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  /*half container banner */
  .half-container-banner .banner-container {
    background: rgba(255, 255, 255, 0.95);
    padding: 25px;
    border: 10px solid rgba(0, 0, 0, 0.1);
  }

  .half-banner {
    &[data-overlay-dark="0"]:before, &[data-overlay-light="0"]:before {
      opacity: 0.70;
    }
  }

  .owl-carousel .caption {
    h1 {
      font-size: 50px;
    }

    p {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .owl-carousel .caption {
    h1 {
      font-size: 32px;
      margin-top: 5px;
    }

    h3 {
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  /*half container banner */
  .half-container-banner .banner-container {
    border: 5px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 90%;
  }

  .owl-carousel .caption h1 {
    font-size: 20px;
    margin-top: 5px;
  }
}

.slider-fade {
  .owl-item {
    height: 100vh;
    position: relative;
  }

  .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;

    .caption {
      width: 100%;
      z-index: 9;
    }
  }

  .owl-theme .owl-dots {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    left: 0;
    text-align: center;
  }
}

/* revolution slider */
.rev_slider_wrapper {
  .tp-caption span {
    color: $PrimaryColor;
  }

  .butn {
    span {
      font-size: 14px !important;
      line-height: 24px !important;
      color: #fff;
      text-shadow: none;
    }

    &.white {
      span {
        color: #232323;
      }

      &:hover span {
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: $tablet-xs-screen) {
  .custom-paragraph .slider-text p {
    width: 450px !important;
    font-size: 15px !important;
    line-height: 26px !important;
  }
}

@media screen and (min-width: $desk-small-screen) {
  .rev_slider .max-style {
    font-size: 60px !important;
    line-height: 70px !important;

    span {
      font-size: 60px !important;
      line-height: 70px !important;
    }
  }
}

@media screen and (max-width: $desk-md-sm-screen) {
  .rev_slider .max-style {
    font-size: 45px !important;
    line-height: 50px !important;

    span {
      font-size: 45px !important;
      line-height: 50px !important;
    }
  }

  .custom-paragraph .slider-text p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

@media screen and (max-width: $tablet-small-screen) {
  .rev_slider .max-style {
    font-size: 30px !important;
    line-height: normal !important;

    span {
      font-size: 30px !important;
      line-height: normal !important;
    }
  }

  .custom-paragraph .slider-text p {
    text-align: center !important;
    font-size: 14px !important;
    line-height: 24px !important;
    width: 480px !important;
    padding: 0 15px;
  }

  .custom-controls {
    .tp-leftarrow, .tp-rightarrow {
      width: 40px !important;
      height: 40px !important;
    }

    .tp-leftarrow {
      left: -15px !important;
    }

    .tp-rightarrow {
      left: inherit !important;
      right: -55px !important;
    }

    .tparrows:before {
      line-height: 20px !important;
    }
  }
}

@media screen and (max-width: $mobile-xs-screen) {
  .rev_slider .max-style {
    font-size: 20px !important;
    line-height: normal !important;

    span {
      font-size: 20px !important;
      line-height: normal !important;
    }
  }

  .custom-paragraph .slider-text p {
    width: 320px !important;
    padding: 0 15px;
  }
}

/* ===================================
    Feature boxes
====================================== */
/* feature box 01 */
.feature-box-01 {
  background: #fff;
  border: 5px solid rgba(0, 0, 0, 0.05);
  padding: 25px 20px;
  text-align: center;
  transition: all 0.5s ease 0s;
  border-radius: 5px;

  p {
    margin-bottom: 10px;
  }

  .icon-tools {
    color: #939393;
  }

  &:hover {
    background: #ffffff;
    border: 5px solid $PrimaryColor;

    .icon-tools {
      color: $PrimaryColor;
    }
  }
}

/* feature box 02 */
.feature-box-02 {
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;

  &:hover {
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  }

  &:before {
    background: $PrimaryColor;
    content: "";
    height: 0;
    left: 0px;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 2px;
  }

  &:hover:before {
    height: 100%;
  }
}

/* feature box 03 */
.feature-box-03 {
  border: 2px solid #383838;
  padding: 25px 20px;
  text-align: center;
  transition: all 0.5s ease 0s;

  .icon-tools {
    color: #939393;
  }

  p {
    color: #9d9d9d;
  }

  &:hover {
    background: $PrimaryColor;
    border: 2px solid $PrimaryColor;

    p {
      color: #fff;
    }
  }
}

/* feature box 04 */
.feature-box-04 {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;

  &:hover {
    -webkit-box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15);
  }

  .fetaure-image {
    position: relative;
    overflow: hidden;
    z-index: 1;

    img {
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }

    &:hover img {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }

    &:after {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      -webkit-box-shadow: 0 -130px 70px -70px #000 inset;
      box-shadow: 0 -130px 70px -70px #000 inset;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
  }

  .package-detail {
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px 18px;
    position: absolute;
    z-index: 9;
  }

  .title {
    color: #fff;
    margin-right: 5px;
  }

  .price {
    width: 60%;
    float: left;
  }

  .people-duration {
    float: right;
    margin-left: 5px;
  }

  .package-detail p {
    margin: 0 0 0 10px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;

    .theme-color {
      margin-right: 5px;
    }
  }

  .rating li {
    float: left;
    margin-left: 3px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .feature-box-04 {
    .package-detail {
      padding: 20px 10px;
    }

    .price {
      width: 55%;
    }
  }
}

/* feature box 05 */
.feature-box-05 {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;

  .fetaure-image {
    position: relative;
    overflow: hidden;

    img {
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }

    &:hover img {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }

    &:after {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      -webkit-box-shadow: 0 -130px 70px -70px rgba(0, 0, 0, 0.65) inset;
      box-shadow: 0 -130px 70px -70px rgba(0, 0, 0, 0.65) inset;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
  }

  .package-detail {
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px 18px;
    position: absolute;
    z-index: 9;
  }
}

/* feature box 06 */
.feature-box-06 {
  text-align: left;
  transition: all 0.5s ease 0s;

  i {
    color: $PrimaryColor;
  }

  p {
    color: #6f6f6f;
  }
}

/* feature box 07 */
.feature-box-07 {
  width: 100%;
  float: left;

  .img-box {
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .feature-textbox {
    position: relative;
    width: 90%;
    z-index: 2;
    top: -50px;
    left: 0;
    transition: all 0.3s ease-out 0s;
    right: 0;
    margin: 0 auto;

    &:after {
      border-bottom: 5px solid transparent;
      width: 0px;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.5s ease-out 0s;
    }
  }

  &:hover .feature-textbox:after {
    border-bottom: 5px solid rgba(0, 0, 0, 0.1);
    content: "";
    width: 100%;
  }

  .feature-textbox:before {
    border-top: 5px solid $PrimaryColor;
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover .feature-textbox:before {
    width: 0;
    transition: all 0.5s ease-out 0s;
  }

  .feature-textbox a {
    color: $PrimaryColor;
  }

  &:hover .feature-textbox {
    background-color: $PrimaryColor;

    .title, .feature-desc, a.read-more {
      color: #fff;
    }
  }

  .img-box img {
    transition: all 0.3s ease-out 0s;
  }

  &:hover .img-box img {
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .feature-box-07 .feature-textbox {
    width: 95%;
    top: -30px;
  }
}

/* feature box 8 */
.feature-box-08 {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  position: relative;

  .show-details {
    opacity: 0;
    transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > img {
      width: 100%;
      max-width: none;
    }
  }

  &:hover .show-details {
    opacity: 1;
  }

  .feature-box-detail {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 100%;

    .height-100 {
      overflow: auto;
    }

    .list-style-5 li {
      margin-bottom: 8px;
    }
  }
}

/* feature box 9 */
.feature-box9 {
  position: relative;

  &:after {
    background: $PrimaryColor;
    position: absolute;
    content: "";
    right: -2px;
    top: 15px;
    width: 2px;
    height: 25px;
    transition-duration: .5s;
    -ms-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
  }

  i {
    transition-duration: .5s;
    -ms-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
  }

  &:hover {
    i {
      color: $PrimaryColor;
    }

    &:after {
      top: 0;
      height: 50px;
    }
  }
}

/* feature flex box */
.feature-flex {
  position: relative;
  margin: 30px 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.feature-flex-square {
  position: relative;
  border-radius: 5px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.feature-flex-square-icon {
  float: left;
  width: 50px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;

  i {
    color: $PrimaryColor;
    border-radius: 10px;
    font-size: 32px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
  }
}

.feature-flex-square-content {
  float: left;
  width: 75%;
  padding-left: 10px;

  h4 {
    color: #232323;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: normal;

    a {
      color: #232323;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      line-height: normal;
    }
  }

  p {
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.feature-flex-square-content-button {
  display: inline-block;
  color: #232323;
  font-size: 14px;
  font-weight: 500;

  &:after {
    content: '\f0da';
    font-weight: 700;
    font-size: 14px;
    font-family: Font Awesome\ 5 Free;
    color: #232323;
    margin-left: 7px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    vertical-align: middle;
  }

  &:hover {
    color: $PrimaryColor;

    &:after {
      color: $PrimaryColor;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .feature-flex-square-icon {
    text-align: center;

    i {
      font-size: 30px;
    }
  }

  .feature-flex-square-content {
    h4 {
      font-size: 16px;

      a {
        font-size: 16px;
      }
    }

    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .feature-flex-square-icon {
    text-align: left;
    width: 40px;
  }
}

/* feature case block */
.case-block {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  height: 100%;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  position: relative;

  &:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.3);
    -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.3);
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.3);
  }

  img {
    width: 100%;
  }
}

.case-block-inner {
  padding: 20px 25px 20px 0;
}

.case-block {
  h4 {
    font-size: 18px;
    margin-bottom: 0;
    line-height: normal;
    font-weight: 500;

    &:after {
      content: '';
      display: block;
      width: 80px;
      height: 2px;
      background: $PrimaryColor;
      margin-top: 10px;
      margin-bottom: 15px;
      -webkit-transition-duration: .4s;
      transition-duration: .4s;
    }
  }

  p {
    margin-bottom: 12px;
  }
}

.case-block-inner a {
  font-size: 15px;
  font-weight: 500;
  color: $PrimaryColor;

  &:after {
    content: '\f0da';
    font-size: 15px;
    font-weight: 700;
    font-family: Font Awesome\ 5 Free;
    color: $PrimaryColor;
    margin-left: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    vertical-align: middle;
  }

  &:hover {
    color: #232323;

    &:after {
      color: #232323;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .case-block-inner {
    padding: 25px;
  }
}

/* feature partner box */
.partner-box {
  border: 1px solid #ececec;

  img {
    width: 100%;
  }
}

.partner-text {
  padding: 25px 40px 25px 0;

  span {
    color: $PrimaryColor;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.partner-box:hover .partner-text span {
  color: #999;
}

.partner-text h4 {
  color: #232323;
  font-size: 21px;
  font-weight: 600;
  margin: 5px 0 20px 0;
  line-height: normal;

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background: $PrimaryColor;
    margin-top: 10px;
    -webkit-transition-duration: .4s;
    transition-duration: 0.4s;
  }
}

.partner-box:hover .partner-text h4:after {
  width: 80px;
}

@media screen and (max-width: $tablet-xs-screen) {
  .partner-text {
    padding: 25px;
  }
}

/* feature feedback box */
.feedback-box {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #ececec;
  padding: 25px;
}

/* ===================================
    Service blocks
====================================== */
/* services images */
.content-bg1 {
  background-image: url("../img/content/feature-01.jpg");
}

@media screen and (max-width: $desk-xs-screen) {
  .sm-bg {
    min-height: 400px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .sm-bg {
    min-height: 320px;
  }
}

/* services blocks */
.services-blocks {
  margin-top: -90px;
}

@media screen and (max-width: $desk-md-screen) {
  .services-blocks {
    margin-top: -70px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .services-blocks {
    margin-top: -50px;
  }
}

/* service simple */
.service-simple {
  position: relative;
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);

  img {
    transition: all 0.5s ease 0s;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.service-simple-inner {
  position: relative;
  padding: 25px 25px 20px;

  p {
    max-width: 95%;
  }

  a {
    display: inline-block;
  }
}

.service-simple h4 {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0;
}

/* services block one */
.owl-carousel .service-box {
  margin: 0 15px 25px 15px;
}

.service-box {
  background: #fff;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(50, 50, 50, 0.12);
  -moz-box-shadow: 0 10px 20px 0 rgba(50, 50, 50, 0.12);
  box-shadow: 0 10px 20px 0 rgba(50, 50, 50, 0.12);
  margin: 15px 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.service-inner-box {
  padding: 20px;
}

.service-icon-box {
  width: 20%;
  max-width: 50px;
  float: left;
  padding-top: 2px;
}

.service-content-box {
  width: 80%;
  float: left;
}

.service-box {
  .img-holder {
    position: relative;

    &:before {
      background-color: rgba($PrimaryRgbColor, 0.6);
      bottom: 0;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: auto;
      transition: all 0.3s ease 0s;
      width: 100%;
      z-index: 10;
    }
  }

  &:hover .img-holder:before {
    opacity: 1;
  }

  .img-holder img {
    width: 100%;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: normal;
    font-weight: 500;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0;
  }

  i {
    color: $PrimaryColor;
    font-size: 28px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  &:hover i {
    color: #232323;
  }
}

/* services block two */
.services-block-two {
  position: relative;

  .inner-box {
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 30px 30px 30px 95px;
    height: 100%;
    position: relative;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .5s;
    -ms-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 0;
    line-height: normal;
    display: inline-block;

    a {
      color: #3c3c3c;
      display: block;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;

      &:hover {
        color: $PrimaryColor;
      }
    }
  }

  h2 {
    bottom: 0;
    color: #f0f0f0;
    font-size: 36px;
    font-weight: 600;
    right: 10px;
    margin-bottom: 0;
    position: absolute;
  }

  .icon-box {
    color: $PrimaryColor;
    font-size: 36px;
    left: 35px;
    position: absolute;
    top: 25px;
  }

  &.dark {
    .inner-box {
      background: #000;
      border: 1px solid #000;

      &:hover {
        background: #000;

        h5 a, p, .icon-box {
          color: #fff;
        }
      }
    }

    h3 a {
      color: #6f6f6f;
    }

    h2 {
      color: rgba(255, 255, 255, 0.1);
    }

    .inner-box:hover h2 {
      color: rgba(255, 255, 255, 0.04);
    }

    .icon-box {
      color: #6f6f6f;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .services-block-two {
    h2 {
      font-size: 36px;
    }

    h3 a {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .icon-box {
      top: 18px;
      left: 21px;
      font-size: 28px;
    }

    .inner-box {
      padding: 20px 20px 20px 75px;
    }
  }
}

/* services block three */
.services-block-three {
  > a {
    display: block;
    border: 3px solid #d5d5d5;
    border-radius: 0;
    text-align: center;
    background: #fff;
    padding: 20px;
    position: relative;

    &:before {
      display: block;
      content: "";
      width: 9%;
      height: 17%;
      position: absolute;
      bottom: -3px;
      right: -3px;
      border-bottom: 3px solid $PrimaryColor;
      border-right: 3px solid $PrimaryColor;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
    }

    &:after {
      display: block;
      content: "";
      width: 9%;
      height: 17%;
      position: absolute;
      top: -3px;
      left: -3px;
      border-top: 3px solid $PrimaryColor;
      border-left: 3px solid $PrimaryColor;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
    }

    &:hover {
      opacity: 1;
      border-color: #d5d5d5;

      &:before, &:after {
        width: 95%;
        height: 90%;
      }
    }
  }

  i {
    font-size: 32px;
  }

  p {
    margin-bottom: 0;
  }

}

/* service-block */
.service-grids {
  .service-block {
    overflow: hidden;
    position: relative;
  }

  .img-holder {
    position: relative;
  }

  .service-block {
    &:hover .img-holder:before {
      opacity: 1;
    }

    .details {
      background-color: #fff;
      box-shadow: 0 3px 13px rgba(0, 0, 0, 0.07);
      padding: 45px 45px 50px;
      position: relative;
      text-align: center;
      transition: all 0.5s ease 0s;
      z-index: 20;
    }

    .number {
      background-color: $PrimaryColor;
      border-radius: 75px;
      color: #fff;
      height: 75px;
      font-size: 30px;
      left: 50%;
      line-height: 75px;
      position: absolute;
      top: -37.5px;
      transform: translateX(-50%);
      width: 75px;
    }

    .details {
      h4 {
        color: $PrimaryColor;
        font-size: 20px;
        margin: 10px 0 15px 0;
        line-height: normal;
      }

      .read-more {
        color: $PrimaryColor;
        font-weight: 600;
      }
    }
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .service-grids {
    .service-block {
      height: 335px;
    }

    .img-holder:before {
      background-color: rgba($PrimaryRgbColor, 0.6);
      bottom: 20px;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: auto;
      transition: all 0.3s ease 0s;
      width: 100%;
      z-index: 10;
    }

    .service-block {
      .details {
        height: 195px;
        position: absolute;
        top: 168px;
      }

      &:hover .details {
        height: 230px;
        top: 105px;
      }
    }
  }
}

@media screen and (min-width: $desk-lg-screen) {
  .service-grids .service-block {
    height: 370px;

    .details {
      height: 195px;
      position: absolute;
      top: 205px;
    }

    &:hover .details {
      height: 230px;
      top: 170px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .service-grids .service-block .details {
    padding: 45px 25px 35px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .service-grids .service-block .number {
    height: 65px;
    line-height: 65px;
    top: -32.5px;
    width: 65px;
    border-radius: 65px;
  }
}

/* services block five */
.service-block5 {
  position: relative;

  > a {
    display: inline-block;
    width: 100%;
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    content: " ";
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  }

  &:hover:before {
    content: none;
  }

  &:after {
    background: $PrimaryColor none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease 0s;
    width: 100%;
  }

  &:hover:after {
    opacity: 0.8;
  }

  a img {
    max-width: 100%;
  }

  .service-desc {
    bottom: 25px;
    position: absolute;
    padding: 0 20px;
    z-index: 9;

    h5 {
      color: $PrimaryColor;
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 600;
      padding-bottom: 10px;
      margin-bottom: 0;
      line-height: normal;
    }
  }

  &:hover .service-desc h5 {
    color: #fff;
  }

  .service-desc h4 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 24px;

    a {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .service-block5 .service-desc {
    bottom: 30px;

    h4 {
      font-size: 16px;

      a {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .service-block5 .service-desc {
    bottom: 20px;

    h4 {
      font-size: 15px;

      a {
        font-size: 15px;
      }
    }

    h5 {
      font-size: 12px;
    }
  }
}

/* services block six */
.service-block6 {
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 50px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-radius: 5px;

  a span {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -6px;
  }

  .service-icons {
    background: linear-gradient(0deg, rgba($PrimaryRgbColor, 0.1) 20%, rgba($PrimaryRgbColor, 0.4) 100%);
    width: 80px;
    height: 80px;
    border-radius: 30px;
    position: relative;
    line-height: 95px;
    text-align: center;

    > i {
      font-size: 42px;
      color: $PrimaryColor;
      margin-top: 40px;
    }
  }

  &:hover {
    box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
  }
}

/* services detail */
.services-single-menu li {
  border-bottom: 1px solid #ececec;

  &:last-child {
    border-bottom: none;
  }

  &.active a {
    border-left-color: $PrimaryColor;
    color: $PrimaryColor;

    &:after {
      content: none;
    }
  }

  a {
    display: block;
    border-left: 4px solid transparent;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 10px 14px 20px;

    &:after {
      content: "";
      background: $PrimaryColor;
      display: inline-block;
      height: 1px;
      width: 0;
      vertical-align: middle;
      margin-left: 8px;
      transition-timing-function: ease-in-out;
      -ms-transition-timing-function: ease-in-out;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      transition-duration: .2s;
      -ms-transition-duration: .2s;
      -moz-transition-duration: .2s;
      -webkit-transition-duration: .2s;
      -o-transition-duration: .2s;
    }

    &:hover:after {
      width: 30px;
    }
  }
}

.callback-box {
  background: #232323;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px 15px 25px 15px;
  position: relative;
}

.services-single-right .special {
  padding: 15px;
  margin: 30px 0;
  border-left: 2px solid #111;
  background: #f7f7f7;
  font-size: 16px;
}

@media screen and (max-width: $tablet-xs-screen) {
  .services-single-menu li a {
    font-size: 14px;
    padding: 12px 10px 12px 16px;
  }
}

/* downloads */
.downloads {
  li {
    border: 1px solid $PrimaryColor;
    border-radius: 5px;
  }

  i {
    background: $PrimaryColor;
    padding: 10px 12px;
    color: #fff;
    vertical-align: top;
  }

  .label {
    margin-top: 10px;
    display: inline-block;
    margin-left: 10px;
  }
}

/* ===================================
    Process with icon
====================================== */
.process-step-xs {
  position: relative;
  margin: 15px 0;
  padding: 0;

  .process-step-icon {
    text-align: center;

    i {
      color: $PrimaryColor;
      font-size: 47px;
    }
  }

  h3 {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #6f6f6f;
    margin: 10px 0;
    text-align: center;
  }
}

.process-steps-xs li {
  position: relative;
  float: left;
  list-style-type: none;
  display: inline-block;

  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 8px solid transparent;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    border-bottom: 8px solid transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 40px;
    text-align: center;
    border-left: 8px solid rgba(0, 0, 0, 0.1);
  }

  &:last-child:after {
    display: none;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .process-step-xs .process-step-icon i {
    font-size: 44px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .process-steps-xs li:nth-child(2):after {
    display: none;
  }

  .process-step-xs {
    .process-step-icon i {
      font-size: 40px;
    }

    h3 {
      font-size: 13px;
      margin: 5px 0;
    }
  }

  .process-steps-xs li:after {
    right: 0;
  }
}

/* ===================================
    Client section
====================================== */
.section-clients {
  padding: 50px 0;
}

.clients img {
  opacity: .6;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;

  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: $desk-sm-screen) {
  .section-clients {
    padding: 30px 0;

    .item {
      text-align: center;
      width: 100%;
    }

    .owl-carousel .owl-item img {
      max-width: 75%;
      display: inline-block;
    }
  }
}

/*client style2*/
.section-clients2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;

  .client-single {
    width: 33.333%;
    min-height: 96px;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    overflow: hidden;

    .client-img {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      opacity: .8;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      img {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $desk-small-screen) {
  .section-clients2 .client-single {
    width: 50%;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .section-clients2 .client-single {
    width: 100%;
  }
}

/*client style3*/
.section-clients3 {
  .client-single {
    margin-bottom: 30px;

    &:last-child, p {
      margin-bottom: 0;
    }

    a {
      display: block;

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }

  .client-img {
    position: relative;
    float: left;
    padding: 10px;
    min-height: 102px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 225px;
    margin: 0 30px 0 0;
    border: 1px solid #ddd;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 3px solid transparent;
      z-index: 100;
    }
  }

  .client-desc {
    overflow: hidden;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .section-clients3 .client-img {
    float: none;
    margin-bottom: 15px;
  }
}

/* ===================================
    Counter box
====================================== */
.counter-box {
  h3, h4 {
    display: inline-block;
    font-weight: 600;
  }
}

.social-icons a:hover {
  opacity: 1;
}

.counter-box {
  position: relative;
  text-align: center;

  h3:after {
    content: '+';
  }

  h4 {
    font-size: 35px;
    line-height: normal;
    margin: 0;

    &:after {
      content: '+';
    }
  }

  h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .counter-box {
    p {
      &:before {
        margin: 0;
        position: absolute;
        top: 45px;
      }

      &:after {
        margin: 0;
        left: 14px;
        right: 0;
        top: 45px;
      }
    }

    &.black p:after {
      margin: 0;
      left: 14px;
      right: 0;
      top: 45px;
    }

    h4 {
      font-size: 26px;
    }
  }
}

/* counterbox style2*/
.counter-box-style2 {
  position: relative;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 10px;

  h3:after, h4:after {
    content: '+';
  }
}

/* ===================================
    Call to action
====================================== */
.callto-action-imgbox {
  display: block;
  margin-right: 50px;
  padding-left: 40px;
  padding-top: 40px;
  position: relative;
  z-index: 1;

  &:before {
    border: 2px solid $PrimaryColor;
    bottom: 40px;
    content: "";
    left: 0;
    position: absolute;
    right: 40px;
    top: 0;
    z-index: -1;
  }

  img {
    width: 100%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .callto-action-imgbox {
    margin-right: 0;
    margin-bottom: 30px;
    padding: 20px 20px 0 20px;
  }
}

/* ===================================
    Video
====================================== */
.story-video {
  height: 100%;
}

.story-video-height {
  min-height: 400px;
  height: 100%;
}

.video_btn {
  position: relative;
  height: 80px;
  width: 80px;
  background: $PrimaryColor;
  text-align: center;
  display: inline-block;
  line-height: 85px;
  color: #fff;
  border-radius: 50%;
  transition-duration: 0s;
  -ms-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;

  &:hover i, &:focus i {
    color: #fff;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 80px;
    width: 80px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite;
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 95px;
    width: 95px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite;
  }
}

.video_btn-style2 {
  &:after, &:before {
    border-color: rgba(0, 0, 0, 0.1);
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.video_btn {
  &.border-grey {
    &:after, &:before {
      border: 2px solid #d8d8d8;
    }
  }

  &.small {
    width: 50px;
    height: 50px;
    line-height: 50px;

    &:after {
      height: 50px;
      width: 50px;
    }

    &:before {
      height: 65px;
      width: 65px;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .story-video-height {
    min-height: 350px;
  }

  .video_btn {
    height: 75px;
    width: 75px;
    line-height: 80px;

    &:after {
      height: 75px;
      width: 75px;
    }

    &:before {
      height: 90px;
      width: 90px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .story-video-height {
    min-height: 300px;
  }

  .video_btn {
    height: 70px;
    width: 70px;
    line-height: 70px;

    &:after {
      height: 70px;
      width: 70px;
    }

    &:before {
      height: 85px;
      width: 85px;
    }
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    }

    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .story-video-height {
    min-height: 250px;
  }

  .video_btn {
    height: 60px;
    width: 60px;
    line-height: 62px;

    &:after {
      height: 60px;
      width: 60px;
    }

    &:before {
      height: 75px;
      width: 75px;
    }
  }
}

/* ===================================
    About area style
====================================== */
.about-area {
  padding: 0;
}

.about-text {
  display: block;
  padding-bottom: 90px;
  padding-top: 90px;

  .sec-title {
    padding-bottom: 34px;
  }

  .inner-content {
    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      margin: 0 0 14px;
      color: #fff;

      span {
        font-weight: 700;
      }
    }

    p {
      color: #fff;
    }

    .bottom {
      display: block;
      overflow: hidden;
      margin-top: 25px;

      p {
        margin: 0;
        font-weight: 500;
      }

      .signature {
        display: block;
        margin-top: 22px;
      }
    }
  }
}

.about-right-box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 90px 40px;
  z-index: 1;
  height: 100%;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $PrimaryColor;
    opacity: .92;
    content: "";
    z-index: -1;
  }

  ul {
    margin-bottom: 0;

    li {
      display: block;
      overflow: hidden;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 29px;
      margin-bottom: 30px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .left-content {
        display: block;
        width: 70%;

        .icon {
          width: 55px;
          display: table-cell;
          vertical-align: top;
          color: rgba(255, 255, 255, 0.5);

          span:before {
            font-size: 55px;
            line-height: 55px;
          }
        }

        .title {
          padding-left: 30px;
          display: table-cell;
          vertical-align: middle;
          color: rgba(255, 255, 255, 0.5);

          h3 {
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: normal;
            letter-spacing: 1px;
            margin: 0 0 1px;
          }

          span {
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }

      .right-content .title {
        display: block;
        margin-top: 3px;

        h4 {
          color: #ffffff;
          font-size: 3.4em;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .about-text, .about-right-box {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .about-area {
    padding-bottom: 50px;
  }

  .about-text, .about-right-box {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .about-right-box {
    padding: 40px 20px;

    ul li {
      padding-bottom: 20px;
      margin-bottom: 20px;

      .left-content {
        .title {
          padding-left: 10px;
        }

        .icon span:before {
          font-size: 36px;
          line-height: normal;
        }
      }

      .right-content .title h4 {
        font-size: 26px;
      }
    }
  }
}

/*about ceo*/
.about-ceo {
  position: relative;

  .about-project {
    position: absolute;
    right: 20px;
    bottom: 19px;
    background: #f7f7f7;
    z-index: 9;

    h3 {
      margin-bottom: 0;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      color: $PrimaryColor;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    p {
      display: block;
      font-size: 18px;
      color: #232323;
      margin: 0;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .box-area {
    position: relative;
    background: #fff;
    padding: 25px;
    text-align: center;
    border: 8px solid $PrimaryColor;
  }
}

/*timeline*/
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px;
  }

  > li {
    margin-bottom: 20px;
    position: relative;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    > {
      .timeline-panel {
        width: 46%;
        float: left;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        padding: 20px;
        position: relative;
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);

        &:before {
          position: absolute;
          top: 26px;
          right: -15px;
          display: inline-block;
          border-top: 15px solid transparent;
          border-left: 15px solid #ccc;
          border-right: 0 solid #ccc;
          border-bottom: 15px solid transparent;
          content: " ";
        }

        &:after {
          position: absolute;
          top: 27px;
          right: -14px;
          display: inline-block;
          border-top: 14px solid transparent;
          border-left: 14px solid #fff;
          border-right: 0 solid #fff;
          border-bottom: 14px solid transparent;
          content: " ";
        }
      }

      .timeline-badge {
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 1.4em;
        text-align: center;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -25px;
        background-color: #999999;
        z-index: 100;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    &.timeline-inverted > .timeline-panel {
      float: right;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }
}

.timeline-badge {
  &.primary {
    background-color: #2e6da4 !important;
  }

  &.success {
    background-color: #3f903f !important;
  }

  &.warning {
    background-color: #f0ad4e !important;
  }

  &.danger {
    background-color: #d9534f !important;
  }

  &.info {
    background-color: #5bc0de !important;
  }
}

.timeline-body > {
  p, ul {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 5px;
  }
}

@media (max-width: $tablet-xs-screen) {
  ul.timeline {
    &:before {
      left: 40px;
    }

    > li > {
      .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
      }

      .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
      }

      .timeline-panel {
        float: right;

        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }

        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
  }
}

/* ===================================
    Progress bars
====================================== */
.progress-text {
  font-weight: 500;
  color: #232323;
  margin-bottom: 5px;
}

.custom-progress {
  height: 4px;
  border-radius: 50px;
  box-shadow: none;
  margin-bottom: 15px;
}

.custom-bar {
  height: 100%;
  background-color: $PrimaryColor;
  box-shadow: none;
}

.custom-bar-dark {
  height: 100%;
  background-color: #232323;
  box-shadow: none;
}

.progress-medium {
  height: 8px;
  border-radius: 50px;
  box-shadow: none;
}

.progress-bold {
  height: 12px;
  border-radius: 50px;
  box-shadow: none;
}

/* ===================================
    Testmonial
====================================== */
.testmonial-single {
  text-align: center;
}

#testmonials-carousel .testmonial-single img {
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
}

.testmonial-single {
  p {
    text-align: center;
    font-style: italic;
    font-weight: 500;

    &:before {
      margin: auto auto 15px;
      content: "\f10d";
      font-weight: 700;
      font-family: Font Awesome\ 5 Free;
      color: $PrimaryColor;
      font-size: 23px;
      display: block;
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 5px 0;
    line-height: normal;
  }

  h6 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    line-height: normal;
  }
}

.testmonial-box {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 15px 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);

  img {
    border: 2px solid #ececec;
    border-radius: 50%;
    padding: 2px;
  }
}

.owl-carousel .testmonial-box img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-box {
  h5 {
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0 0;
    line-height: normal;
  }

  span {
    font-size: 13px;
  }

  p {
    font-size: 15px;
    line-height: 1.7;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.testmonial-style2 {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 0 0 30px 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  img {
    border: 2px solid #ececec;
    border-radius: 50%;
    padding: 2px;
  }
}

.owl-carousel .testmonial-style2 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-style2 {
  h5 {
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0 0;
    line-height: normal;
  }

  span {
    font-size: 13px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.testimonial-style3 {
  .testimonial-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .testimonial-review p {
    margin-bottom: 0;
  }

  .testimonial-left-col {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 177px;
    flex: 1 0 177px;
    margin-right: 100px;
    text-align: center;
  }

  .testimonial-pic {
    position: relative;
    margin: 0 0 25px;

    &:before {
      content: '\f10d';
      font-weight: 700;
      font-family: Font Awesome\ 5 Free;
      display: block;
      position: absolute;
      top: 50%;
      right: -62px;
      margin-top: -8px;
      font-size: 21px;
      color: $PrimaryColor;
    }
  }

  .testimonial-separator {
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      position: absolute;
      right: -53px;
      background-color: #6f6f6f;
    }

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      position: absolute;
      right: -53px;
      background-color: #6f6f6f;
      bottom: 0;
    }
  }

  .client-info {
    h6 {
      margin-bottom: 0;
      font-size: 15px;
      color: #fff;
    }

    span {
      color: #d6d5d5;
    }
  }

  .testimonial-review {
    padding-bottom: 70px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 300;
  }

  .custom-dot {
    display: inline-block;
    z-index: 1;
    margin-top: 30px;

    span {
      width: 12px;
      height: 12px;
      margin: 0 7px;
      background: none;
      cursor: pointer;
      display: block;
      border: 1px solid #fff;
      -webkit-backface-visibility: visible;
      border-radius: 0;
      transition-duration: .3s;
      -webkit-transition-duration: .3s;

      &:hover {
        background: #fff;
        -webkit-backface-visibility: visible;
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testimonial-style3 .testimonial-review {
    padding-bottom: 65px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testimonial-style3 {
    .client-info h6 {
      font-style: 14px;
    }

    .custom-dot {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .testimonial-style3 {
    .testimonial-review {
      padding-bottom: 0;
    }

    .testimonial-left-col {
      margin-bottom: 20px;
      text-align: left;
    }

    .testimonial-item {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .testimonial-separator, .testimonial-pic:before {
      display: none;
    }
  }
}

/* Testimonial style4 */
.testimonial-style4 {
  border: 2px solid #ededed;
  padding: 25px;
  position: relative;

  &:after {
    background: $PrimaryColor;
    position: absolute;
    content: "";
    right: -2px;
    top: 15px;
    width: 2px;
    height: 25px;
    transition-duration: .5s;
    -ms-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
  }

  &:hover:after {
    top: 0;
    height: 50px;
  }

  .quote {
    font-size: 80px;
    line-height: 30px;
    margin-top: 40px;
  }

  img {
    border-radius: 50%;
    max-width: 120px;
  }

  .name {
    position: relative;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testimonial-style4 .quote {
    font-size: 60px;
  }
}

/*testimonial style5*/
.testmonials-style5 {
  position: relative;
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 30px 0;
  -moz-transition-duration: .3s;
  -ms-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    height: 5px;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  img {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 2px;
  }
}

.owl-carousel .testmonials-style5 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonials-style5 {
  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    position: relative;
  }

  span {
    font-size: 13px;
    color: $PrimaryColor;

    &:before {
      background: rgba(0, 0, 0, 0.2);
      height: 1px;
      width: 20px;
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  p {
    margin-top: 10px;
  }

  .data {
    position: relative;

    i {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testmonials-style5 {
    margin-bottom: 20px;

    p {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonials-style5 {
    margin-bottom: 15px;
    padding: 25px 15px;

    h5 {
      font-size: 15px;
    }
  }
}

/*testimonial style6*/
.testimonial-style6 .testmonial-single {
  h4 {
    font-size: 16px;
  }

  h6 {
    font-size: 11px;
    margin-bottom: 0;
  }
}

/* ===================================
    Owl carousel
====================================== */
.owl-theme .owl-dots .owl-dot {
  &.active span, &:hover span {
    background-color: $PrimaryColor;
  }
}

.owl-carousel .owl-nav button {
  &.owl-next, &.owl-prev {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    background: #fff !important;
    -webkit-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
  }
}

.owl-nav {
  i, span {
    color: #232323;
    font-size: 18px;
    padding-top: 4px;
  }
}

.owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}

.testmonial-single {
  text-align: center;
}

#testmonials-carousel .testmonial-single img {
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
}

.testmonial-single {
  p {
    text-align: center;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;

    &:before {
      margin: auto auto 15px;
      content: "\f10d";
      font-weight: 700;
      font-family: Font Awesome\ 5 Free;
      color: $PrimaryColor;
      font-size: 23px;
      display: block;
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 5px 0;
    line-height: normal;
  }

  h6 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    line-height: normal;
  }
}

.testmonial-box {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 15px 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);

  img {
    border: 2px solid #ececec;
    border-radius: 50%;
    padding: 2px;
  }
}

.owl-carousel .testmonial-box img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-box {
  h5 {
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0 0;
    line-height: normal;
  }

  span {
    font-size: 13px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.testmonial-style2 {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 0 0 30px 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  img {
    border: 2px solid #ececec;
    border-radius: 50%;
    padding: 2px;
  }
}

.owl-carousel .testmonial-style2 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-style2 {
  h5 {
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0 0;
    line-height: normal;
  }

  span {
    font-size: 13px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.testimonial-style3 {
  .testimonial-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .testimonial-review p {
    margin-bottom: 0;
  }

  .testimonial-left-col {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 177px;
    flex: 1 0 177px;
    margin-right: 100px;
    text-align: center;
  }

  .testimonial-pic {
    position: relative;
    margin: 0 0 25px;

    &:before {
      content: '\f10d';
      font-weight: 700;
      font-family: Font Awesome\ 5 Free;
      display: block;
      position: absolute;
      top: 50%;
      right: -62px;
      margin-top: -8px;
      font-size: 21px;
      color: $PrimaryColor;
    }
  }

  .testimonial-separator {
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      position: absolute;
      right: -53px;
      background-color: #6f6f6f;
    }

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      position: absolute;
      right: -53px;
      background-color: #6f6f6f;
      bottom: 0;
    }
  }

  .client-info {
    h6 {
      margin-bottom: 0;
      font-size: 15px;
      color: #fff;
    }

    span {
      color: #d6d5d5;
    }
  }

  .testimonial-review {
    padding-bottom: 70px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 300;
  }

  .custom-dot {
    display: inline-block;
    z-index: 1;
    margin-top: 30px;

    span {
      width: 12px;
      height: 12px;
      margin: 0 7px;
      background: none;
      cursor: pointer;
      display: block;
      border: 1px solid #fff;
      -webkit-backface-visibility: visible;
      border-radius: 0;
      transition-duration: .3s;
      -webkit-transition-duration: .3s;

      &:hover {
        background: #fff;
        -webkit-backface-visibility: visible;
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testimonial-style3 .testimonial-review {
    padding-bottom: 65px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testimonial-style3 {
    .client-info h6 {
      font-style: 14px;
    }

    .custom-dot {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .testimonial-style3 {
    .testimonial-review {
      padding-bottom: 0;
    }

    .testimonial-left-col {
      margin-bottom: 20px;
      text-align: left;
    }

    .testimonial-item {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .testimonial-separator, .testimonial-pic:before {
      display: none;
    }
  }
}

/* Testimonial style4 */
.testimonial-style4 {
  border: 2px solid #ededed;
  padding: 25px;
  position: relative;

  &:after {
    background: $PrimaryColor;
    position: absolute;
    content: "";
    right: -2px;
    top: 15px;
    width: 2px;
    height: 25px;
    transition-duration: .5s;
    -ms-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
  }

  &:hover:after {
    top: 0;
    height: 50px;
  }

  .quote {
    font-size: 80px;
    line-height: 30px;
    margin-top: 40px;
  }

  img {
    border-radius: 50%;
    max-width: 120px;
  }

  .name {
    position: relative;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testimonial-style4 .quote {
    font-size: 60px;
  }
}

/*testimonial style5*/
.testmonials-style5 {
  position: relative;
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 30px 0;
  -moz-transition-duration: .3s;
  -ms-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    height: 5px;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  img {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 2px;
  }
}

.owl-carousel .testmonials-style5 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonials-style5 {
  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    position: relative;
  }

  span {
    font-size: 13px;
    color: $PrimaryColor;

    &:before {
      background: rgba(0, 0, 0, 0.2);
      height: 1px;
      width: 20px;
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  p {
    margin-top: 10px;
  }

  .data {
    position: relative;

    i {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testmonials-style5 {
    margin-bottom: 20px;

    p {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonials-style5 {
    margin-bottom: 15px;
    padding: 25px 15px;

    h5 {
      font-size: 15px;
    }
  }
}

/*testimonial style6*/
.testimonial-style6 .testmonial-single {
  h4 {
    font-size: 16px;
    margin-top: 10px;
  }

  h6 {
    font-size: 11px;
    margin-bottom: 0;
  }
}

/* cretive slider */
.about-carousel {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 70px;
    left: -70px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 30px solid rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .about-carousel {
    margin-bottom: 50px;

    &:after {
      top: 50px;
      left: -50px;
      border: 20px solid rgba(0, 0, 0, 0.1);
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .about-carousel {
    margin: 0;

    &:after {
      display: none;
    }
  }
}

/* ===================================
    Team member
====================================== */
.team-member {
  border: 1px solid #ececec;
  border-radius: 5px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  background: #fff;
  overflow: hidden;

  &:hover {
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.28);
    -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  }
}

.team-member-img {
  width: 100%;
  height: auto;

  img {
    width: 100%;
  }
}

.team-member-text {
  text-align: center;
  padding: 20px;

  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
  }
}

.team-member:hover .team-member-text h4 {
  color: $PrimaryColor;
}

.team-member-text {
  h4 a {
    color: #232323;
  }

  span {
    color: $PrimaryColor;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
  }

  p {
    margin: 20px auto 0;
    max-width: 90%;
  }

  ul {
    margin-top: 15px;
    padding: 8px 0;

    li a i {
      font-size: 17px;
      line-height: 34px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .team-member-text {
    padding: 20px 10px;
  }
}

/* team single img */
.team-single-img {
  width: 100%;

  img {
    border-radius: 5px;
  }
}

.team-single-text {
  padding: 5px 50px 15px 15px;

  span {
    color: $PrimaryColor;
    font-weight: 600;
  }

  h4 {
    color: #232323;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.team-single-info {
  padding: 0;

  li strong {
    color: #555;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }
}

.team-single-text li span {
  color: #777;
  font-size: 15px;
  font-weight: 400;
}

/* team single icon */
.team-single-icons ul li {
  display: inline-block;
  border: 1px solid $PrimaryColor;
  border-radius: 50%;
  color: $PrimaryColor;
  margin-right: 8px;
  margin-bottom: 5px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;

  a {
    color: $PrimaryColor;
    display: block;
    font-size: 14px;
    height: 25px;
    line-height: 26px;
    text-align: center;
    width: 25px;
  }

  &:hover {
    background: $PrimaryColor;
    border-color: $PrimaryColor;

    a {
      color: #fff;
    }
  }
}

/* team style1 */
.team-style1 .team-member-img {
  overflow: hidden;
  border-radius: 5px;

  img {
    transition: all 0.5s ease 0s;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.team-style2 .team-member-img {
  img {
    transition: all 0.3s ease 0s;
  }

  &:hover img {
    filter: grayscale(100%);
  }
}

/* team style2 */
.team-style2 {
  .team-description {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    opacity: 0;
    transition: all 200ms ease-in-out;
    padding: 15px;
    z-index: 9;
  }

  .social-links {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out, opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    -webkit-font-smoothing: antialiased;

    a:hover {
      opacity: 0.65;
    }
  }

  .team-description-wrapper {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
  }

  .team-description-content {
    display: table-cell;
    vertical-align: middle;
  }

  .team-member-img {
    position: relative;
    display: block;
    overflow: hidden;
  }

  .bg-cover {
    background-color: rgba(255, 66, 41, 0);
    opacity: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    transform: scale(2);
    transition: all 200ms ease-in-out;
    display: none;
    z-index: 1;
  }

  .team-member-img > img {
    transition: all 0.2s ease 0s;
  }

  &:hover {
    .bg-cover {
      background-color: rgba(0, 0, 0, 0.75);
      display: block;
    }

    .social-links {
      transition-delay: 100ms, 100ms;
      opacity: 1;
      transform: translateY(0);
    }

    .team-member-img > img {
      filter: blur(4px) grayscale(4%);
    }

    .team-description {
      opacity: 1;
    }
  }
}

/* team style2 */
.team-style3 {
  .team-description {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    opacity: 0;
    transition: all 200ms ease-in-out;
    padding: 15px;
    z-index: 9;
  }

  .social-links {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out,opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

  .about-me {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out,opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: inline-block;
    color: #f7f7f7;
  }

  .social-links {
    &:after {
      background: $PrimaryColor;
      bottom: -12px;
      content: "";
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 75px;
    }

    a:hover {
      opacity: 0.65;
    }
  }

  .team-description-wrapper {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
  }

  .team-description-content {
    display: table-cell;
    vertical-align: middle;
  }

  .team-member-img {
    position: relative;
    display: block;
    overflow: hidden;
  }

  .bg-cover {
    background-color: rgba(255, 66, 41, 0);
    opacity: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    transform: scale(2);
    transition: all 200ms ease-in-out;
    display: none;
    z-index: 1;
  }

  .team-member-img > img {
    transition: all 0.2s ease 0s;
  }

  &:hover {
    .bg-cover {
      background-color: rgba(0, 0, 0, 0.83);
      display: block;
    }

    .social-links {
      transition-delay: 100ms,100ms;
      opacity: 1;
      transform: translateY(0);
    }

    .about-me {
      transition-delay: 200ms,200ms;
      opacity: 1;
      transform: translateY(0);
    }

    .team-member-img > img {
      filter: blur(4px) grayscale(4%);
    }

    .team-description {
      opacity: 1;
    }
  }
}

/* Team Style 04*/
.team-style4 {
  .team-description {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    opacity: 0;
    transition: all 200ms ease-in-out;
    padding: 15px;
    z-index: 9;
  }

  .social-links {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out,opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

  .about-me {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out,opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: inline-block;
  }

  .social-links {
    &:after {
      background: $PrimaryColor;
      bottom: -12px;
      content: "";
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 12px;
    }

    a:hover {
      opacity: 0.65;
    }
  }

  .team-description-wrapper {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
  }

  .team-description-content {
    display: table-cell;
    vertical-align: middle;
  }

  .team-member-img {
    position: relative;
    display: block;
    overflow: hidden;
  }

  .bg-cover {
    background-color: rgba(255, 66, 41, 0);
    opacity: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    transform: scale(2);
    transition: all 200ms ease-in-out;
    display: none;
    z-index: 1;
  }

  .team-member-img > img {
    transition: all 0.2s ease 0s;
  }

  &:hover {
    .bg-cover {
      background-color: rgba(0, 0, 0, 0.83);
      display: block;
    }

    .social-links {
      transition-delay: 100ms,100ms;
      opacity: 1;
      transform: translateY(0);
    }

    .about-me {
      transition-delay: 200ms,200ms;
      opacity: 1;
      transform: translateY(0);
    }

    .team-member-img > img {
      filter: blur(4px) grayscale(4%);
    }

    .team-description {
      opacity: 1;
    }
  }
}

/* ===================================
    Cards
====================================== */
/* card-style1 */
.card-style1 {
  position: relative;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);

  .card-date {
    display: inline-block;

    &:before {
      content: "/";
      padding-right: 5px;
    }

    h5 {
      font-size: 12px;
      line-height: normal;
      letter-spacing: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      padding: 0;
      border: none;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
    }
  }

  .card-body {
    padding: 25px;
    position: relative;

    > span {
      color: $PrimaryColor;
      font-size: 13px;
      padding-right: 5px;
    }

    h4 {
      font-size: 16px;
      line-height: normal;
      margin-bottom: 3px;
    }

    ul li {
      display: inline-block;
      margin: 10px 25px 10px 0;

      i {
        color: $PrimaryColor;
        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;
      }
    }

    .card-text {
      padding: 15px 0 0 0;
    }
  }
}

/* card-style2 */
.card-style2 {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background: #fff;
  height: 100%;

  .card-img {
    position: relative;
    overflow: hidden;

    &:hover img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    img {
      width: 100%;
      -webkit-filter: none;
      filter: none;
      transition: 0.3s;
    }
  }

  .card-body {
    padding: 30px;
    text-align: left;

    .tag {
      font-size: 14px;
      margin-bottom: 5px;
      letter-spacing: 1px;
    }

    .read-more {
      margin-top: 20px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 16px;
      line-height: normal;
      text-transform: inherit;
    }
  }
}

/* ===================================
    Project grid
====================================== */
/* Popup gallery*/
.lg-backdrop {
  z-index: 99999;
}

.lg-outer {
  z-index: 999999;

  .lg-thumb-item {
    &.active, &:hover {
      border-color: #00bcd1;
    }
  }
}

.lg-progress-bar .lg-progress {
  background-color: #00bcd1;
}

.lg-backdrop.in {
  opacity: 0.85;
}

/* filtering */
.filtering {
  margin-bottom: 10px;

  span {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    color: #333333;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }
  }

  .active {
    border-color: $PrimaryColor;
    color: $PrimaryColor;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .filtering span {
    margin-right: 15px;
  }
}

/* filtering style2 */
.filtering-style2 span {
  font-weight: 700;
}

.gallery-style2 .project-grid {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* project grid */
.project-grid {
  display: block;
  position: relative;
  margin: 15px 0;
}

.project-grid-img img {
  display: block;
  width: 100%;
  height: auto;
}

.project-grid-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  overflow: hidden;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  opacity: 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;

  h4 {
    margin-bottom: 0;

    a {
      text-align: center;
    }
  }

  p {
    text-align: center;
  }
}

.project-grid:hover .project-grid-overlay {
  opacity: 1;
}

.project-grid-overlay h4 a {
  font-size: 22px;
  opacity: 0;

  &:hover {
    color: $PrimaryColor;
  }

  &:after {
    content: '';
    display: block;
    width: 70px;
    height: 1px;
    background: $PrimaryColor;
    margin: 15px auto;
  }
}

.project-grid:hover h4 a {
  opacity: 1;
  line-height: normal;
}

.project-grid-overlay p {
  color: #666;
  font-size: 15px;
  margin: 0;
  opacity: 0;
}

.project-grid:hover p {
  opacity: 1;
}

.project-grid-overlay.theme {
  background: rgba($PrimaryRgbColor, 0.95);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 5px solid rgba(0, 0, 0, 0.15);

  h4 a {
    color: #fff;

    &:hover {
      color: rgba(255, 255, 255, 0.55);
    }

    &:after {
      height: 1px;
      background: #fff;
    }
  }

  p {
    color: #fff;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .project-grid-overlay h4 {
    line-height: 20px;

    a {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .project-grid {
    margin: 20px 0 0 0;

    &:hover p {
      top: 52%;
    }
  }
}

/* project grid style2 */
.project-grid-style2 {
  .project-details {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      -webkit-transition: all 400ms;
      transition: all 400ms;
    }
  }

  .portfolio-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

    a {
      position: absolute;
      top: 50%;
      -webkit-transform: scale3d(0, 0, 0);
      transform: scale3d(0, 0, 0);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      color: #fff;
      font-size: 26px;
      z-index: 9;
    }
  }

  .portfolio-title {
    padding: 15px 15px 0 15px;
    text-align: center;
    display: block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    h4 {
      font-size: 16px;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  &:hover {
    .project-details img {
      -webkit-transform: scale3d(1.15, 1.15, 1);
      transform: scale3d(1.15, 1.15, 1);
    }

    .portfolio-icon {
      opacity: 1;

      a {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);

        &:hover {
          color: $PrimaryColor;
        }
      }
    }
  }
}

.portfolio-post-border {
  border: 1px solid $PrimaryColor;
  bottom: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  visibility: hidden;
}

.project-grid-style2:hover .portfolio-post-border {
  visibility: visible;
}

/* project single */
.project-carousel h4:after {
  content: " ";
  background: $PrimaryColor;
  height: 2px;
}

.project-single-text {
  h4:after {
    content: " ";
    background: $PrimaryColor;
    height: 2px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8;
  }

  h5 {
    color: #232323;
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0 20px;

    &:after {
      display: block;
      width: 70px;
      margin-top: 8px;
      content: " ";
      background: $PrimaryColor;
      height: 2px;
    }
  }

  ul li {
    color: #232323;
    font-size: 15px;
    font-weight: 500;

    i {
      color: $PrimaryColor;
      font-weight: 500;
      font-size: 15px;
      padding-right: 10px;
    }
  }
}

.project-single-info {
  border: 1px solid #ececec;
  display: block;
  width: 100%;
  padding: 0 20px;

  ul li {
    color: #777;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #ececec;
    padding: 12px 0;

    &:last-child {
      border-bottom: none;
    }

    span {
      display: inline-block;
      color: #232323;
      font-size: 15px;
      font-weight: 500;
      margin-right: 25px;
      width: 70px;
      max-width: 100%;
    }

    .value {
      width: auto;
      margin-right: 0;
      color: #6f6f6f;
    }
  }
}

.project-single-img {
  padding-bottom: 30px;
}

.project-single-box-img img {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
}

/* project grid style3 */
.project-grid-style3 {
  position: relative;
  margin-bottom: 10px;

  .inner-box {
    position: relative;
    display: block;
    overflow: hidden;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

    .project-image {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      }
    }

    &:hover .project-image:before {
      opacity: 0;
    }

    .project-image img {
      position: relative;
      width: 100%;
      display: block;
    }

    .overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      color: #ffffff;
      visibility: hidden;
      -moz-transition: all 700ms ease;
      -webkit-transition: all 700ms ease;
      -ms-transition: all 700ms ease;
      -o-transition: all 700ms ease;
      transition: all 700ms ease;
      -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
      -moz-transform-origin: left top;
      transform-origin: left top;
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: block;
        visibility: hidden;
        transition: all 900ms ease;
        -moz-transition: all 900ms ease;
        -webkit-transition: all 900ms ease;
        -ms-transition: all 900ms ease;
        -o-transition: all 900ms ease;
        background-color: rgba($PrimaryRgbColor, 0.95);
      }
    }

    &:hover .overlay:before {
      opacity: 1;
      visibility: visible;
    }

    .overlay-inner {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      display: table;
      vertical-align: middle;
      padding: 20px;

      .description {
        position: relative;
        display: table-cell;
        vertical-align: middle;
      }
    }

    .text {
      position: relative;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .read-more {
      position: relative;
      font-size: 13px;
      font-weight: 700;
      color: #ffffff;
      padding-right: 22px;

      .fa {
        position: relative;
        margin-right: 4px;
      }
    }

    &:hover .overlay {
      opacity: 1;
      visibility: visible;
      -moz-transform: translateX(0%);
      -webkit-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }

    .project-desc {
      background: #fff;
      position: relative;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }

    &:hover .project-desc {
      background-color: #f9f8fc;
    }

    .project-desc .category {
      color: $PrimaryColor;
      letter-spacing: 1px;
      font-size: 13px;
      font-weight: 700;
      padding-bottom: 8px;
      margin-bottom: 20px;
      text-transform: uppercase;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }

    &:hover .project-desc .category {
      color: #232323;
    }

    .project-desc .category:after {
      content: "";
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      width: 20%;
      display: block;
      margin-top: 10px;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }

    &:hover .project-desc .category:after {
      width: 100%;
      background: $PrimaryColor;
    }

    .project-desc a {
      position: relative;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      line-height: 24px;

      &:hover {
        color: $PrimaryColor;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .project-grid-style3 .inner-box {
    .overlay-inner {
      padding: 15px;
    }

    .text {
      margin-bottom: 15px;
    }
  }
}

/* project grid style4 */
.project-grid-style4 {
  display: block;
  position: relative;
  margin: 0;

  &.portfolio-item-wrapper {
    display: block;
    text-decoration: none;
  }

  .portfolio-img-container {
    display: block;
    position: relative;
  }

  .portfolio-description {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fafafa;
    color: #000;
    padding: 15px;
    font-size: 0.85em;
    text-align: center;
  }
}

/* Responsive CSS */
@media screen and (min-width: $tablet-sm-screen) {
  /* Portfolio Style 04*/
  .project-grid-style4 {
    .portfolio-item-img {
      width: 100%;
      display: block;
    }

    .portfolio-description {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: transparent;
      opacity: 0;
      transition: all 200ms ease-in-out;
    }

    .portfolio-cover {
      background-color: rgba(255, 66, 41, 0);
      opacity: 1;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      transform: scale(2);
      transition: all 200ms ease-in-out;
      display: none;
    }

    .portfolio-title {
      transform: translateY(15px);
      transition: transform 300ms ease-in-out,opacity 150ms ease-in;
      opacity: 0;
      color: #fff;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
    }

    .portfolio-sub-title {
      transform: translateY(15px);
      transition: transform 300ms ease-in-out,opacity 150ms ease-in;
      opacity: 0;
      color: #fff;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      position: relative;
      display: inline-block;
      font-size: 14px;

      &:after {
        background: $PrimaryColor;
        bottom: 10px;
        content: "";
        height: 1px;
        position: absolute;
        right: -20px;
        width: 12px;
      }

      &:before {
        background: $PrimaryColor;
        bottom: 10px;
        content: "";
        height: 1px;
        position: absolute;
        left: -20px;
        width: 12px;
      }
    }

    .portfolio-description-wrapper {
      position: relative;
      display: table;
      width: 100%;
      height: 100%;
    }

    .portfolio-description-content {
      display: table-cell;
      vertical-align: middle;
    }

    &.portfolio-item-wrapper {
      backface-visibility: hidden;
      margin-bottom: 0;
      overflow: hidden;
    }

    .portfolio-item-img {
      transition: all 0.2s ease 0s;
    }

    &:hover {
      .portfolio-cover {
        background-color: rgba(0, 0, 0, 0.83);
        display: block;
      }

      .portfolio-title {
        transition-delay: 100ms,100ms;
        opacity: 1;
        transform: translateY(0);
      }

      .portfolio-sub-title {
        transition-delay: 200ms,200ms;
        opacity: 1;
        transform: translateY(0);
      }

      .portfolio-item-img {
        filter: blur(4px) grayscale(4%);
      }

      .portfolio-description {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  /* Portfolio Style 04*/
  .project-grid-style4 {
    .portfolio-description {
      position: static;
    }

    .portfolio-title {
      font-size: 15px;
    }

    .portfolio-sub-title {
      font-size: 15px;
      font-size: 13px;
      color: #6f6f6f;
    }
  }
}

/* ===================================
    Accordion style
====================================== */
/*accordion style 1*/
.accordion-style {
  .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    border: none;
  }

  .card-header {
    border: 0px;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none;
  }

  .btn-link {
    background: $PrimaryColor !important;
    color: #fff;
    position: relative;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 4px;
    padding: 15px 30px 15px 15px;
    text-decoration: none;
    box-shadow: none;

    &:hover {
      background: $PrimaryColor !important;
      border: none;
      text-decoration: none;
    }

    &.collapsed:after {
      background: none;
      content: "+";
      right: 15px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 12px;
    }

    &:after {
      background: none;
      content: "-";
      right: 17px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 12px;
      position: absolute;
      color: #fff;
    }
  }

  .card-body {
    padding: 20px;
    line-height: 24px;
    text-align: left;
  }
}

/*accordion style 2*/
.accordion-style2 {
  .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    border: none;
  }

  .card-header {
    border: 0px;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none;
  }

  .btn-link {
    background: #ededed !important;
    color: #232323;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 0;
    padding: 15px 30px 15px 15px;
    text-decoration: none;
    box-shadow: none;

    &:hover {
      background: #ededed !important;
      border: none;
      text-decoration: none;
    }

    &.collapsed:after {
      background: none;
      content: "+";
      right: 15px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
    }

    &:after {
      background: none;
      content: "-";
      right: 17px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
      position: absolute;
      color: #232323;
    }
  }

  .card-body {
    padding: 10px 15px;
    line-height: 24px;
    text-align: left;
    background: #f9f9f9;
  }
}

/* ===================================
    Service detail
====================================== */
.services-single-menu {
  border: 1px solid #ececec;

  ul li {
    border-bottom: 1px solid #ececec;

    &:last-child {
      border-bottom: none;
    }

    &.services-active {
      background: $PrimaryColor;

      a {
        color: #fff;
      }
    }

    a {
      display: block;
      color: #232323;
      font-weight: 500;
      padding: 17px 0 17px 15px;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
    }
  }
}

.callback-box {
  background: #232323;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px 15px 25px 15px;
  position: relative;
}

/* ===================================
    Portfolio masonry
====================================== */
.masonry {
  margin: 10px;
  padding: 0;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  transition-duration: .4s;
  -webkit-transition-duration: .4s;

  a {
    display: block;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.masonry-item {
  position: relative;
  overflow: hidden;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;

  img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
  }
}

.masonry-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  border: 8px solid rgba(255, 255, 255, 0.65);
  padding: 3%;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.masonry-item:hover .masonry-item-overlay {
  position: absolute;
  opacity: 1;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;

  h4 {
    opacity: 1;
    transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    text-align: center;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
  }

  ul {
    opacity: 1;
    transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    text-align: center;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
  }
}

.masonry-item-overlay {
  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    bottom: 40px;
    margin: 0 0 20px 0;
    line-height: 20px;
  }

  ul {
    position: absolute;
    padding-left: 0;
    bottom: 25px;
    margin: 0;

    li {
      list-style-type: none;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      padding-left: 0;
      opacity: .7;

      &:last-child:after {
        content: none;
      }

      &:after {
        content: ' / ';
        font-size: 8px;
        font-weight: 700;
        margin: 0 5px;
      }

      a {
        color: #fff;
      }
    }
  }
}

.masonry-item:first-child {
  margin-top: 0;
}

@media screen and (min-width: $mobile-md-screen) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: $tablet-sm-screen) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media screen and (min-width: $desk-lg-screen) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

/* masonry 4 */
.masonry-4 {
  margin: 10px;
  padding: 0;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;

  a {
    display: block;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: $mobile-md-screen) {
  .masonry-4 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: $tablet-sm-screen) {
  .masonry-4 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .masonry-4 {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media screen and (min-width: $desk-lg-screen) {
  .masonry-4 {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

/* ===================================
    Tabs
====================================== */
ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
  font-weight: 600;
  display: inline-block;
  padding: 13px 15px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  i {
    font-size: 20px;
    padding-right: 5px;
    vertical-align: text-bottom;
  }
}

.resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  clear: left;
}

.resp-tab-content {
  display: none;
  padding: 20px;
}

.resp-tabs-list li.resp-tab-active {
  border: 1px solid $PrimaryColor;
  border-bottom: none;
  border-color: $PrimaryColor !important;
  margin-bottom: -1px;
  padding: 12px 14px 14px 14px;
  border-top: 4px solid $PrimaryColor !important;
  border-bottom: 0px #fff solid;
  border-bottom: none;
  background-color: #fff;
  color: $PrimaryColor;
}

.resp-content-active, .resp-accordion-active {
  display: block;
}

.resp-tab-content {
  border: 1px solid #c1c1c1;
  border-top-color: #c1c1c1;
  float: left;
  width: 100%;
}

h2 {
  &.resp-accordion {
    cursor: pointer;
    display: none;
    font-size: 14px;
    border: 1px solid #c1c1c1;
    border-top: 0px solid #c1c1c1;
    margin: 0px;
    padding: 14px 15px;
    float: left;
    width: 100%;
  }

  &.resp-tab-active {
    border-bottom: 0px solid #c1c1c1 !important;
    background-color: $PrimaryColor !important;
    color: #fff;
  }

  &.resp-tab-title:last-child {
    border-bottom: 12px solid #c1c1c1 !important;
    background: blue;
  }
}

/* vertical tabs */
.resp-vtabs {
  ul.resp-tabs-list {
    float: left;
    width: 30%;
  }

  .resp-tabs-list li {
    display: block;
    padding: 15px 15px !important;
    margin: 0 0 4px;
    cursor: pointer;
    float: none;
  }

  .resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    border: 1px solid $PrimaryColor !important;
    float: left;
    width: 70%;
    min-height: 250px;
    clear: none;
  }

  .resp-tab-content {
    border: none;
    word-wrap: break-word;
  }

  li.resp-tab-active {
    position: relative;
    z-index: 1;
    margin-right: -1px !important;
    padding: 14px 15px 15px 11px !important;
    border-top: 1px solid;
    border: 1px solid $PrimaryColor !important;
    border-left: 4px solid $PrimaryColor !important;
    margin-bottom: 4px !important;
    border-right: 1px #FFF solid !important;
  }
}

.resp-arrow {
  border-color: transparent #232323 #232323 transparent;
  border-style: solid;
  border-width: 0 1px 1px 0;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 4px;
}

h2.resp-tab-active span.resp-arrow {
  border-color: #fff transparent transparent #fff;
  border-style: solid;
  border-width: 1px 0 0 1px;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 7px;
}

/* accordion styles */
.resp-easy-accordion {
  h2.resp-accordion {
    display: block;
  }

  .resp-tab-content {
    border: 1px solid #c1c1c1;

    &:last-child {
      border-bottom: 1px solid #c1c1c1;
    }
  }
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
  border-color: $PrimaryColor !important;
}

h2.resp-accordion {
  &:first-child {
    border-top: 1px solid #c1c1c1;
  }

  &.resp-tab-active {
    border-color: $PrimaryColor !important;
  }
}

/* tab-style4 */
.tab-style4 {
  .resp-tabs-list li.resp-tab-active, .resp-tab-content-active {
    background: $PrimaryColor !important;
    color: #fff;
  }
}

/* tab-style5 */
@media screen and (min-width: $tablet-sm-screen) {
  .tab-style5 {
    ul.resp-tabs-list {
      text-align: center;

      &.text-left {
        padding-left: 15px;
      }

      li {
        &:last-child {
          margin-right: 0;
        }

        &.resp-tab-active {
          border-bottom-color: $PrimaryColor;
          color: $PrimaryColor;
        }
      }
    }

    .resp-tab-content {
      border: 1px solid #e6e6e6;
      border-width: 1px 0 0 0;
      border-color: $PrimaryColor;
    }
  }
}

/* tab-style6 */
@media screen and (min-width: $tablet-sm-screen) {
  .tab-style6 {
    ul.resp-tabs-list {
      text-align: center;
      border: 1px solid #e6e6e6;
      border-width: 0 0 1px 0;

      li {
        &:last-child {
          margin-right: 0;
        }

        &.resp-tab-active {
          border-bottom-color: $PrimaryColor;
          color: $PrimaryColor;
        }
      }
    }

    .resp-tab-content {
      border: none;
      padding: 30px 20px;
    }

    .resp-tabs-list {
      .tab-box {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        margin-top: 8px;
      }

      li.resp-tab-active h6 {
        color: $PrimaryColor;
      }

      .tab-box {
        h6 {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
          color: #6f6f6f;
          line-height: 12px;
        }

        span {
          text-transform: lowercase;
          font-size: 12px;
        }
      }

      li {
        font-size: 18px;
        border-bottom: 2px solid transparent !important;
        margin: 0;
        background: none !important;
        padding: 0 35px 15px 35px;
        text-transform: uppercase;

        &.resp-tab-active {
          border-width: 0 0 2px 0 !important;
          border-color: $PrimaryColor !important;
        }
      }
    }

    ul.resp-tabs-list {
      i {
        margin-right: 15px;
        border: 1px solid #d5d5d5;
        border-radius: 70px;
        width: 70px;
        height: 70px;
        line-height: 68px;
        font-size: 30px;
        padding: 0;
        vertical-align: middle;
      }

      li.resp-tab-active i {
        background: $PrimaryColor;
        border-color: $PrimaryColor;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .tab-style6 {
    .resp-tab-content {
      padding: 20px;
    }

    .resp-tabs-list li {
      padding: 0 12px 15px 13px;
    }
  }
}

/* change the breakpoint to set the accordion */
@media screen and (max-width: $tablet-xs-screen) {
  ul.resp-tabs-list {
    display: none;
  }

  h2.resp-accordion {
    display: block;

    i {
      margin-right: 8px;
      font-size: 18px;
    }
  }

  .resp-vtabs {
    .resp-tab-content {
      border: 1px solid #C1C1C1;
    }

    .resp-tabs-container {
      border: none !important;
      float: none;
      width: 100%;
      min-height: 100px;
      clear: none;
    }
  }

  .resp-accordion-closed {
    display: none !important;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid #c1c1c1 !important;
  }

  .resp-tab-content {
    background-color: #fff;
  }

  /* tab-style6 */

  .tab-style6 {
    .tab-box {
      display: inline-block;
      vertical-align: top;
    }

    h2.resp-accordion i {
      margin-right: 15px;
    }

    .tab-box {
      h6 {
        margin-bottom: 2px;
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }

    .resp-tab-active h6 {
      color: #fff;
    }
  }
}

/* tab-style7 */
.tab-style7 {
  .resp-tabs-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    li {
      position: relative;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      padding: 0 15px 15px 15px;
      margin: 0 4px 0 0;
      list-style: none;
      cursor: pointer;
      min-width: auto;
      color: #000;
      background: none !important;

      &.resp-tab-active {
        border: 1px solid $PrimaryColor;
        border-bottom: none;
        border-color: $PrimaryColor !important;
        margin-bottom: -1px;
        border-top: 4px solid $PrimaryColor !important;
        border-bottom: 0px #fff solid;
        border-bottom: none;
        background-color: #fff;
        color: $PrimaryColor;
        color: #5e2ced;
        -ms-border-top-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-top-left-radius: 5px;
        -o-border-top-left-radius: 5px;
        -ms-border-top-right-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-top-right-radius: 5px;
        -o-border-top-right-radius: 5px;
        -ms-border-radius-top-left: 5px;
        -webkit-border-radius-top-left: 5px;
        -moz-border-radius-top-left: 5px;
        -o-border-radius-top-left: 5px;
        -ms-border-radius-topright: 5px;
        -webkit-border-radius-topright: 5px;
        -moz-border-radius-topright: 5px;
        -o-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;

        &:after {
          content: "";
          background: $PrimaryColor;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: -1px;
          left: 0;
          margin: 0 auto;
          right: 0;
        }
      }
    }
  }

  .img-effect:hover {
    transform: translateY(-8px);
  }

  .box-shadow-primary {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .tab-style7 {
    ul.resp-tabs-list {
      text-align: center;

      &.text-left {
        padding-left: 15px;
      }

      li {
        &:last-child {
          margin-right: 0;
        }

        &.resp-tab-active {
          color: $PrimaryColor;
        }
      }
    }

    .resp-tab-content {
      margin-top: 40px;
      border: none;
      padding: 0;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .tab-style7 {
    ul.resp-tabs-list {
      display: none;
    }

    h2.resp-accordion {
      display: block;

      i {
        margin-right: 8px;
        font-size: 18px;
      }
    }

    .resp-accordion-closed {
      display: none !important;
    }

    .resp-tab-content {
      background-color: #fff;
    }
  }
}

/* tab-style8 */
.tab-style8 {
  ul.resp-tabs-list {
    background: #999999;
    display: inline-block;
    padding: 3px;
    border-radius: 5px;
  }

  .resp-tabs-list li {
    position: relative;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    list-style: none;
    cursor: pointer;
    min-width: auto;
    color: #fff;
    margin-right: 0;
    background: none !important;
    border-radius: 5px;
    padding: 5px 15px;

    &.resp-tab-active {
      margin-bottom: -1px;
      color: #fff;
      padding: 5px 15px;
      background-color: $PrimaryColor !important;
      border: none !important;
    }
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .tab-style8 {
    ul.resp-tabs-list {
      text-align: center;

      &.text-left {
        padding-left: 15px;
      }

      li:last-child {
        margin-right: 0;
      }
    }

    .resp-tab-content {
      margin-top: 25px;
      border: none;
      padding: 0;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .tab-style8 {
    ul.resp-tabs-list {
      display: none;
    }

    h2.resp-accordion {
      display: block;

      i {
        margin-right: 8px;
        font-size: 18px;
      }
    }

    .resp-accordion-closed {
      display: none !important;
    }

    .resp-tab-content {
      background-color: #fff;
    }
  }
}

/* ===================================
    Pricing table
====================================== */
.price-table {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  position: relative;
}

.price-table-header {
  background: $PrimaryColor;
  text-align: center;
  padding: 30px;
}

.price-table-recommended {
  position: relative;
  z-index: 9999;
  transform: scale(1.09);
}

.pricing-list > i {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 100%;
  color: $PrimaryColor;
  display: inline-block;
  font-size: 30px;
  height: 80px;
  line-height: 78px;
  margin-bottom: 15px;
  width: 80px;
}

.price-table-header {
  h5 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: #ffffff;
    display: block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    padding: 5px 15px 15px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
  }

  h4 {
    color: #fff;
    font-size: 60px;
    margin: 15px 0 10px;

    sup {
      font-size: 35px;
      margin: 0 5px;
    }
  }

  span {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
  }
}

.price-table-content {
  padding: 20px;

  ul {
    padding-left: 0;
    padding-bottom: 0;

    li {
      display: block;
      list-style-type: none;
      color: #232323;
      font-size: 14px;
      font-weight: 500;
      padding: 15px;

      &:before {
        content: '\f00c';
        font-weight: 700;
        font-family: Font Awesome\ 5 Free;
        display: inline-block;
        color: $PrimaryColor;
        margin-right: 12px;
      }

      &:nth-child(2) {
        background: #eee;
      }
    }
  }

  a {
    display: inline-block;
    border: 2px solid $PrimaryColor;
    background: #fff;
    font-family: "Montserrat",sans-serif;
    color: #232323;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    padding: 12px 15px;
    width: 100%;
    margin-top: 15px;

    &:hover {
      background: $PrimaryColor;
      color: #fff;
    }
  }
}

.pricing-list {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #ececec;
  padding: 30px 40px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-transition-duration: .5s;
  transition-duration: .5s;

  &.highlight {
    background: $PrimaryColor;
    border-color: transparent;

    .pricing-list-button {
      background: #fff;
      margin: 20px 15px 0;
      border: 2px solid #fff;
      border-radius: 5px;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;

      &:hover {
        background: $PrimaryColor;
      }

      a {
        display: block;
        color: $PrimaryColor;
        font-size: 16px;
        font-weight: 500;
        padding: 8px;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
      }

      &:hover a {
        color: #fff;
      }
    }
  }

  h4 {
    color: #232323;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  h2 {
    display: inline-block;
    color: #232323;
    letter-spacing: 2px;

    sup {
      color: #232323;
      font-size: 30px;
      font-weight: 500;
      vertical-align: sub;
    }
  }

  span {
    display: inline-block;
    color: $PrimaryColor;
    font-weight: 500;
  }

  ul {
    margin-top: 20px;

    li {
      color: #666;
      font-size: 15px;
      font-weight: 400;
      padding: 7px;

      &:after {
        content: '';
        display: block;
        width: 240px;
        max-width: 100%;
        height: 1px;
        background: #ececec;
        margin: 10px auto 0;
      }
    }
  }
}

.pricing-list-button {
  background: $PrimaryColor;
  margin: 20px 15px 0;
  border: 2px solid $PrimaryColor;
  border-radius: 5px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;

  &:hover {
    background: #fff;
  }

  a {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
  }

  &:hover a {
    color: $PrimaryColor;
  }
}

.price-table-style2 {
  .price-table-header {
    background: #000;
  }

  .price-table-content {
    a {
      border-color: #232323;

      &:hover {
        background: #232323;
      }
    }

    ul li:before {
      color: #232323;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .price-table-recommended {
    position: relative;
    z-index: 9999;
    transform: scale(1);
  }
}

/* price table3 */
.single-plan {
  position: relative;
  z-index: 99;
  border: 1px solid #efefef;
  background: #fff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  -moz-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  -ms-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  -o-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);

  .head-plan h4 {
    padding: 20px;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
  }
}

.body-plan .price-plan {
  padding: 32px 0 43px;
  position: relative;
  background: #f7f7f7;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;

  h3 {
    margin: 0;
    z-index: 11;
    position: relative;
    font-weight: bold;
    color: #616161;

    sup {
      font-size: 18px;
      top: -15px;
      font-weight: bold;
      margin-right: 2px;
    }
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
    z-index: 11;
    position: relative;
  }
}

.feat-plan {
  margin: 56px 0 33px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 15px;
      border-bottom: 1px solid #efefef;
      padding-bottom: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.price-plan i {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  background: $PrimaryColor;
}

.footer-plan {
  padding: 0 0 30px;
}

@media screen and (max-width: $tablet-xs-screen) {
  .body-plan .price-plan {
    padding: 28px 0 38px;

    h3 sup {
      font-size: 16px;
    }
  }

  .feat-plan {
    margin: 45px 0 30px;

    ul li {
      margin-bottom: 12px;
      padding-bottom: 12px;
    }
  }
}

/* ===================================
    Shop
====================================== */
/* home shop */
.shop-category {
  -webkit-box-shadow: 1px 5px 22px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 5px 22px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 5px 22px -3px rgba(0, 0, 0, 0.1);

  li {
    border-color: #eaeaea;
    padding: 0 0 10px 40px;
  }

  &.list-style-5 li:before {
    left: 15px;
  }
}

.label-offer {
  position: absolute;
  left: 0;
  top: 0;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  padding: 0px 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  z-index: 1;
}

.product-grid {
  .border {
    float: left;
    width: 100%;
    position: relative;
  }

  .product-info {
    padding: 15px;
    float: left;
    width: 100%;
    background: #fafafa;
    border-top: 1px solid #eeeeee;
    text-align: center;
    font-size: 18px;

    > a {
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  .price {
    font-weight: 600;
  }

  .product-block:hover > img {
    opacity: 0.65;
  }

  .buttons {
    float: left;
    width: 100%;

    a {
      width: 50%;
      float: left;
      text-align: center;
      padding: 8px 0;
    }
  }
}

.control-top {
  .owl-nav {
    margin: 0;
    position: absolute;
    right: -2px;
    top: -73px;
  }

  &.owl-theme .owl-nav {
    [class*='owl-'] {
      background: #fff !important;
      padding: 0 7px;
      width: 30px;
      height: 30px;
      margin: 0 2px;
      border-radius: 0;
      border: 1px solid #d5d5d5;
    }

    i {
      color: #6f6f6f;
      padding-top: 4px;
    }
  }
}

.v-middle-content {
  padding: 50px 8.5%;
  padding-top: 0;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  max-width: 65%;
  display: block;
}

.banner-content {
  letter-spacing: 2px;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    content: "";
    opacity: 0;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
  }

  &:hover:before {
    background: rgba(0, 0, 0, 0.65);
    opacity: 1;
  }

  .subtitle {
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .title {
    font-size: 44px;
    line-height: 44px;
    text-transform: uppercase;
    font-weight: 500;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
  }

  &:hover .title {
    margin-left: 20px;
  }

  .view {
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    border-bottom: 1px solid #373737;
    display: inline-block;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
  }

  &:hover .view {
    margin-left: 20px;
    border-bottom: 1px solid #fff;
  }
}

.feature-product-grid {
  .border {
    float: left;
    width: 100%;
    background: #fafafa;
  }

  .product-block {
    width: 90px;
    height: 90px;
    border: 1px solid #eee;
    border-width: 0 1px 0 0;
    float: left;
  }

  .product-info {
    max-width: 61%;
    padding: 8px 15px;
    float: left;
  }
}

/* shop product detail*/
.product-detail {
  .label-sale {
    margin-left: 5px;
    vertical-align: top;
    padding: 2px 7px;
  }

  .offer-price {
    text-decoration: line-through;
    color: red;
  }
}

.product-rating .fas:hover {
  color: #232323;
}

.xzoom-preview {
  z-index: 99999; }

/* shop product cart*/
.shop-cart-table {
  width: 100%;
  overflow-x: auto;

  .product-thumbnail {
    min-width: 100px;
  }

  .shop-cart td {
    line-height: 26px;
    vertical-align: middle;
    padding: 16px 8px;
  }
}

.table.cart-sub-total {
  border-color: transparent;
  vertical-align: middle;
  padding: 10px 30px;

  td, th {
    border-color: transparent;
    vertical-align: middle;
    padding: 10px 30px;
  }

  td {
    padding-right: 0;
  }
}

.shop-cart-table input[type="text"] {
  height: 35px;
  width: 50px;
  margin: 0;
}

/* shop product grid and list*/
.product-list {
  .product-block:hover > img {
    opacity: 0.65;
  }

  .buttons {
    float: left;
    width: 100%;

    a {
      float: left;
      text-align: center;
      padding: 8px 23px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .categories {
    .collapse-sm {
      &:after {
        font-family: Font Awesome\ 5 Free;
        content: "\f105";
        float: right;
      }

      &.current:after {
        font-family: Font Awesome\ 5 Free;
        content: "\f107";
        float: right;
      }
    }

    .shop-category {
      display: none;
    }
  }
}

/* ===================================
    Blog block
====================================== */
.latest_blog_inner {
  overflow: hidden;
}

.image_blog {
  position: relative;

  > a {
    &:after {
      background: $PrimaryColor none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all 0.3s ease 0s;
      width: 100%;
    }

    &:hover:after {
      opacity: 0.6;
    }
  }

  a img {
    max-width: 100%;
  }

  .img_blog_text {
    bottom: 35px;
    position: absolute;
    padding: 0 20px;

    h5 {
      color: #ffffff;
      font-size: 13px;
      letter-spacing: 0.26px;
      padding-bottom: 10px;
      margin-bottom: 0;
      line-height: normal;
    }

    h3 {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 24px;

      a {
        color: #fff;
      }
    }
  }
}

.text_blog {
  background: #fafafa none repeat scroll 0 0;
  padding: 35px 40px 25px;
  transition: all 0.3s linear 0s;

  &:hover {
    background: $PrimaryColor;
  }

  h5 {
    color: #616161;
    font-size: 13px;
    letter-spacing: 0.26px;
    padding-bottom: 18px;
    margin: 0;
    line-height: normal;
    transition: all 300ms linear 0s;
  }

  &:hover h5 {
    color: #ffffff;
  }

  h3 {
    color: #282828;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 24px;
  }

  &:hover h3 {
    color: #ffffff;

    a {
      color: #ffffff;
    }
  }

  p {
    margin: 0;
    padding: 20px 0;
    transition: all 300ms linear 0s;
  }

  &:hover p {
    color: #ffffff;
  }

  .blog_user {
    border-top: 2px solid #ececec;
    padding-top: 15px;

    h4 {
      color: #616161;
      font-size: 15px;
      margin-bottom: 0;
      line-height: normal;
      letter-spacing: 0.3px;
      transition: all 300ms linear 0s;

      a {
        color: $PrimaryColor;
        transition: all 300ms linear 0s;
      }
    }
  }

  &:hover .blog_user h4 {
    color: #ffffff;

    a {
      color: #ffffff;
    }
  }
}

@media screen and (max-width: $desk-sm-screen) {
  .text_blog {
    padding: 26px 20px;
  }
}

/* ===================================
    Blog
====================================== */
.blog-grid {
  position: relative;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);

  img {
    width: 100%;
  }
}

/* blog style1 */
.blog-style1 .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background: #fff;
  height: 100%;

  .post-img {
    position: relative;
    overflow: hidden;

    &:hover img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    img {
      width: 100%;
      -webkit-filter: none;
      filter: none;
      transition: 0.3s;
    }
  }

  .content {
    padding: 30px;
    text-align: left;

    .tag {
      font-size: 12px;
      margin-bottom: 5px;
      letter-spacing: 1px;
    }

    .read-more {
      margin-top: 20px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      margin-bottom: 10px;
    }

    h5 {
      font-size: 16px;
      line-height: normal;
      text-transform: inherit;
    }
  }
}

/* blog style2 */
.blog-style2 .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background: #fff;
  height: 100%;

  .post-img {
    position: relative;
    overflow: hidden;

    &:hover img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    img {
      width: 100%;
      -webkit-filter: none;
      filter: none;
      transition: 0.3s;
    }
  }

  .content {
    padding: 30px;
    text-align: left;

    .tag {
      font-size: 12px;
      margin-bottom: 0;
      letter-spacing: 1px;
    }

    .read-more {
      margin-top: 20px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    h5 {
      font-size: 16px;
      line-height: normal;
      text-transform: inherit;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .blog-style2 .item .content {
    padding: 25px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .blog-style2 .item .content {
    padding: 20px;

    h5 {
      font-size: 15px;
    }
  }
}

/* blog style6 */
.blog-style6 {
  .blog-item {
    float: left;
    width: 100%;
  }

  .blog-item-thumbnail img {
    width: 100%;
    border-radius: 3px;
  }

  .blog-meta {
    text-transform: uppercase;
    width: 24%;
    float: left;
    padding: 16px 25px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 2px;
    overflow: hidden;
    color: #fff;
    background-color: $PrimaryColor;
  }

  .date-day {
    font-weight: 600;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 5px;
  }

  .date-month {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .blog-title {
    float: left;
    width: 76%;
    padding-left: 20px;

    a {
      font-size: 18px;
      line-height: 1.25;
      margin-bottom: 18px;
      font-weight: 600;
    }
  }

  .post-cat {
    float: left;
    width: 76%;
    padding-left: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .blog-item-content {
    position: relative;
    float: left;
    width: 100%;
  }

  .blog-item-thumbnail {
    margin-bottom: 20px;
  }

  .blog-meta span {
    display: inline-block;
    margin-right: 15px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .blog-style6 {
    .blog-meta {
      padding: 15px;
    }

    .blog-title a {
      font-size: 16px;
      line-height: 1;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .blog-style6 {
    .blog-meta {
      padding: 10px;
    }

    .date-day {
      font-size: 20px;
      margin-bottom: 2px;
    }

    .post-cat {
      padding-left: 15px;
      margin-bottom: 0;
    }

    .blog-title {
      padding-left: 15px;
      line-height: 22px;

      a {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .blog-style6 {
    .post-cat {
      width: 70%;
      padding-left: 15px;
    }

    .blog-title {
      width: 70%;
      padding-left: 15px;

      a {
        font-size: 14px;
      }
    }

    .blog-meta {
      padding: 12px 10px 8px 10px;
      width: 80px;
    }

    .blog-item-thumbnail {
      margin-bottom: 15px;
    }

    .date-day {
      margin: 0;
    }
  }
}

.data-box-grid {
  display: inline-block;

  &:before {
    content: "/";
    padding-right: 5px;
  }

  h5 {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.blog-grid .data-box-grid p {
  font-size: 12px;
  margin-bottom: 0;
  padding: 0;
  border: none;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}

.blog-grid-text {
  padding: 25px;
  position: relative;

  > span {
    color: $PrimaryColor;
    font-size: 13px;
    padding-right: 5px;
  }

  h4 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 3px;
  }

  ul li {
    display: inline-block;
    font-weight: 500;
    margin: 10px 25px 10px 0;

    i {
      color: $PrimaryColor;
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  p {
    padding: 15px 0 0 0;
  }
}

.owl-carousel .blog-grid-simple {
  margin: 10px 15px 25px;
}

.blog-grid-simple-date {
  width: 100%;
}

.blog-grid-simple {
  position: relative;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ececec;
  box-shadow: 0 10px 15px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  padding: 30px;

  h3 {
    color: #232323;
    line-height: normal;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 15px;

    a {
      color: #232323;
      line-height: normal;
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 15px;

      &:hover {
        color: $PrimaryColor;
      }
    }
  }
}

.blog-grid-simple-date {
  i {
    color: #888;
    font-size: 20px;
  }

  h5 {
    color: #888;
    font-size: 13px;
    margin-top: 3px;
    margin-bottom: 0;
    display: inline-block;
    margin-left: 8px;
    line-height: normal;
  }
}

.blog-grid-simple-content {
  border-top: 1px solid #ececec;
  padding-top: 20px;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;

  a {
    display: inline-block;
    color: $PrimaryColor;
    font-weight: 600;
    font-size: 14px;
    margin-top: 2px;

    &:after {
      content: '\f105';
      font-size: 14px;
      font-weight: 700;
      font-family: Font Awesome\ 5 Free;
      color: $PrimaryColor;
      margin-left: 10px;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
    }
  }
}

.blog-list-left-heading:after, .blog-title-box:after {
  content: '';
  height: 2px;
}

.blog-grid-simple-content a:hover {
  color: #232323;

  &:after {
    color: #232323;
  }
}

.blog-list-simple {
  margin: 0 0 35px 0;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.data-box-simple {
  display: block;
  float: right;
  position: absolute;
  background: $PrimaryColor;
  left: 20px;
  top: 5px;
  padding: 5px;
  min-width: 60px;
  text-align: center;
}

.blog-list-simple-text > span {
  color: $PrimaryColor;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.data-box-simple {
  h4 {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2px;
    border-bottom: 1px solid #fff;
    margin-bottom: 5px;
    line-height: normal;
  }

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.blog-list-simple-text {

  p {
    border-top: 1px solid #ececec;
    margin-bottom: 0;
    padding: 15px 0;
  }

  .meta {
    margin: 0 0 5px 0;
  }

  li {
    display: inline-block;
    font-size: 14px;
    margin: 5px;
  }

  ul {
    margin: 10px 0;

    li {
      padding: 3px 0;

      i {
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
}

.blogs {
  .post {
    margin-bottom: 30px;

    .content {
      padding: 30px;
      background: #fafafa;

      .post-title h5 {
        font-size: 26px;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #232323;

          &:hover {
            color: #F24259;
          }
        }
      }

      .meta {
        margin-bottom: 15px;

        li {
          display: inline-block;
          margin: 5px;
        }
      }

      .special {
        padding: 15px;
        margin: 30px 0;
        border-left: 2px solid #111;
        background: #f7f7f7;
        font-size: 16px;
      }

      .btn {
        border-color: #6f6f6f;
        color: #6f6f6f;
        min-width: auto;
        padding: 6px 20px;
        font-size: 12px;

        &:hover {
          color: #fff;
          border-color: #F24259;
        }
      }
    }

    .share-post {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px dashed #ddd;
      text-align: left;

      span {
        font-weight: 700;
      }

      ul {
        float: right;

        li {
          display: inline-block;
          margin: 0 0 0 15px;
        }
      }
    }

    .post-img img {
      width: 100%;
    }
  }

  .posts .title-g h3 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .comments-area .comment-box {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee;

    &:nth-child(odd) {
      margin-left: 80px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  .comment-box {
    .author-thumb {
      width: 80px;
      float: left;
    }

    .comment-info {
      margin-left: 100px;

      h6 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .reply {
        margin-top: 10px;
        font-weight: 600;

        i {
          padding-right: 5px;
          font-size: 12px;
        }
      }
    }
  }
}

.pagination {
  border-radius: 0;
  padding: 0;
  margin: 0;

  ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto;
    padding: 0;
  }

  li {
    display: inline;
  }

  a {
    float: left;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #dbdbdb;
    border-left-width: 0;
    background: #fff;

    &:hover {
      background-color: #232323;
      color: #fff;
    }
  }

  .active a {
    background-color: #f7f7f7;
    color: #999;
    cursor: default;
  }

  .disabled {
    span {
      color: #999;
      background-color: transparent;
      cursor: default;
    }

    a {
      color: #999;
      background-color: transparent;
      cursor: default;

      &:hover {
        color: #999;
        background-color: transparent;
        cursor: default;
      }
    }
  }

  li:first-child a {
    border-left-width: 1px;
  }
}

.blockquote {
  background: #f1f1f1;
  border-left: 4px solid $PrimaryColor;
  font-size: 16px;
  font-weight: 500;
  margin: 25px 0;
  padding: 20px 30px 30px;

  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 190%;
    color: #232323;
    letter-spacing: .5px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    color: #232323;
    letter-spacing: .5px;
  }
}

.side-bar {
  .widget {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title h6 {
      position: relative;
      padding-bottom: 5px;
      letter-spacing: 1px;
      margin-bottom: 20px;

      &:after {
        content: '';
        width: 30px;
        height: 1px;
        background: $PrimaryColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    li {
      position: relative;
      padding: 0 0 0 12px;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0;
      }

      &:after {
        content: "";
        width: 5px;
        height: 1px;
        background-color: #6f6f6f;
        position: absolute;
        top: 12px;
        left: 0;
      }
    }

    .social-listing {
      margin-bottom: 0;

      li {
        list-style: none;
        display: inline-block;

        &:after {
          background: none;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  .search form {
    input {
      width: calc(100% - 50px);
      height: 50px;
      padding: 0 10px;
      margin: 0;
      border: 1px solid #d1d1d1;
      background: #f7f7f7;
    }

    button {
      width: 50px;
      height: 50px;
      line-height: 50px;
      background: #1c1c1c;
      color: #fff;
      border: 0;
      float: right;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .blog-grid-simple-content a:after {
    display: none;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .blog-grid-simple-date i {
    font-size: 16px !important;
  }
}

/* ===================================
    Count and error box
====================================== */
/* count back box */
.count-back-box {
  width: 100%;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;

  h1 {
    color: #232323;
    font-size: 40px;
    font-weight: 500;
  }
}

/* error box */
.error-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;

  h2 {
    color: rgba(204, 204, 204, 0.2);
    font-size: 250px;
    font-weight: 600;
    letter-spacing: 10px;
  }
}

.error-box-text {
  h1 {
    font-size: 200px;
    color: $PrimaryColor;
    line-height: 1;
  }

  h3 {
    font-size: 40px;
    color: #232323;
    line-height: normal;
  }

  h4 {
    font-size: 20px;
    color: #333;
    margin-top: 20px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .error-box {
    padding: 30px;
  }

  .back-box {
    display: none;
  }

  .error-box-text h1 {
    font-size: 100px;
  }
}

/* count down */
ul.countdown li {
  border-right: 1px solid #626262;
  display: inline-block;
  padding: 0 30px;
  text-align: center;

  &:last-child {
    border: medium none;
    padding-right: 0;
  }

  span {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    position: relative;

    &::before {
      content: "";
      height: 1px;
      position: absolute;
      width: 100%;
    }
  }

  p {
    &.timeRefDays, &.timeRefHours, &.timeRefMinutes, &.timeRefSeconds {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  ul.countdown li {
    padding: 0 8px;
  }

  .social-links li {
    margin-right: 15px;
  }

  ul.countdown li span {
    font-size: 22px;
  }
}

/* ===================================
    Contact info
====================================== */
/* social links */
.social-icon-style1 {
  margin-bottom: 0;

  li {
    text-align: center;
    margin-right: 4px;
    display: inline-block;

    a {
      background: $PrimaryColor;
      border: 1px solid $PrimaryColor;
      color: #fff;
      border-radius: 35px;
      height: 35px;
      width: 35px;
      line-height: 35px;
      display: inline-block;

      &:hover {
        background: #fff;
        color: $PrimaryColor;
      }
    }
  }
}

.social-icon-style2 {
  margin-bottom: 0;

  li {
    text-align: center;
    margin-right: 5px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    a {
      background: #f9f9f9;
      border: 1px solid $PrimaryColor;
      color: $PrimaryColor;
      border-radius: 35px;
      height: 35px;
      width: 35px;
      line-height: 35px;
      display: inline-block;

      &:hover {
        background: $PrimaryColor;
        color: #fff;
      }
    }
  }
}

.social-icon-style3 {
  margin-bottom: 0;

  li {
    text-align: center;
    margin-right: 10px;
    display: inline-block;

    a {
      color: #6f6f6f;
      display: inline-block;
      font-size: 16px;

      &:hover {
        color: $PrimaryColor;
      }
    }
  }
}

.social-icon-style4 {
  padding: 0;
  float: right;
  margin: 0;

  li {
    font-size: 14px;
    list-style-type: none;
    float: left;
    width: 35px;
    text-align: center;
    margin: 0;
    padding: 13px 0;

    a {
      opacity: .7;
      color: #fff;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
    }
  }
}

.social-icon-style5 li {
  display: inline-block;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  a {
    font-size: 22px;
    color: rgba(255,255,255,0.65);

    &:hover {
      color: #fff;
    }
  }
}

.social-icon-style6 {
  margin-bottom: 0;

  li {
    text-align: center;
    margin-right: 5px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    a {
      border: 1px solid #6f6f6f;
      color: #6f6f6f;
      border-radius: 35px;
      height: 35px;
      width: 35px;
      line-height: 35px;
      display: inline-block;

      &:hover {
        background: #232323;
        color: #fff;
      }
    }
  }
}

/* contact detail */
.map {
  vertical-align: top;
  border: 0;
  width: 100%;
  height: 400px;
}

.contact-info-section {
  border-bottom: 1px solid #ececec;
  padding: 25px 0;
  margin-bottom: 0;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;

  i {
    width: 25px;
    height: 25px;
    padding-top: 7px;
    border-radius: 50%;
    background: $PrimaryColor;
    color: #fff;
    font-size: 12px;
    margin-right: 5px;
    line-height: 11px;
  }

  h4 {
    margin-bottom: 15px;
  }

  p {
    margin: 0;
  }
}

.contact-box {
  text-align: center;
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 2px;
  -webkit-box-shadow: 0 20px 80px rgba(0, 0, 0, 0.12);
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.12);
  padding: 20px 15px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;

  i {
    background: $PrimaryColor;
    color: #fff;
    font-size: 35px;
    margin: 10px 0 20px;
    height: 75px;
    width: 75px;
    line-height: 75px;
    border-radius: 50%;
  }

  h4 {
    color: #232323;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 5px;
    line-height: normal;
  }
}

/* newsletter */
.newsletter-form {
  input {
    display: inline-block;
    background: #fff;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    outline: 0;
    border: 2px solid #eee;
    min-width: 270px;
    max-width: 100%;
    -moz-border-radius-topleft: 30px;
    -webkit-border-top-left-radius: 30px;
    border-top-left-radius: 30px;
    -moz-border-radius-bottomleft: 30px;
    -webkit-border-bottom-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 12px 20px;
    width: auto;
  }

  button {
    display: inline-block;
    background: $PrimaryColor;
    cursor: pointer;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 500;
    -moz-border-radius-topright: 30px;
    -webkit-border-top-right-radius: 30px;
    border-top-right-radius: 30px;
    -moz-border-radius-bottomright: 30px;
    -webkit-border-bottom-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 14px 20px;

    &:hover {
      background: #232323;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .newsletter-form {
    button {
      margin-top: 15px;
      border-radius: 30px;
      width: 100%;
      display: block;
    }

    input {
      border-radius: 30px;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      display: block;
    }
  }
}

/* newsletter */
.newsletter-form1 {
  .quform-elements {
    position: relative;

    .quform-submit-inner {
      position: absolute;
      right: 0px;
      top: 2px;
      width: auto;
    }

    .quform-loading-wrap {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  input {
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 55px;
    padding: 0.5rem 4rem 0.5rem 1.5rem;
  }

  .quform-has-error {
    input, textarea, select {
      border-color: #f5543f;
    }
  }

  .quform-input .quform-errors-wrap {
    right: 15px;
  }

  i {
    font-size: 1.1rem;
    line-height: 2rem;
  }

  .quform-elements .quform-submit-inner .butn {
    padding: 10px 20px 9px 20px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover:after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

}

/* newsletter-form2 */
.newsletter-form2 {
  .quform-elements {
    position: relative;

    .quform-submit-inner {
      position: absolute;
      right: 0px;
      top: 0;
      width: auto;

      .butn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
        display: inline-block;
        height: 50px;
        &:after {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 25px;
          border-top-right-radius: 25px;
        }
      }
    }

    .quform-loading-wrap {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  input {
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 50px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 0.5rem 4rem 0.5rem 1.5rem;
    max-width: 316px;
  }

  .quform-has-error input {
    border-color: #f5543f;
  }
}

.newsletter-form1 .quform-has-error {
  textarea, select {
    border-color: #f5543f;
  }
}

.newsletter-form2 {
  .quform-input .quform-errors-wrap {
    right: 15px;
  }

  i {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.newsletter-form2 .quform-elements .quform-submit-inner .butn.theme:after {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

@media screen and (max-width: $desk-xs-screen) {
  .newsletter-form2 {
    input {
      height: 46px;
    }

    .quform-elements .quform-submit-inner .butn {
      padding: 12px 30px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .newsletter-form2 {
    .quform-elements .quform-submit-inner {
      position: inherit;
    }

    input {
      margin-bottom: 5px;
      max-width: 100%;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    }

    .quform-elements .quform-submit-inner .butn {
      width: 100%;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
    }
  }
}

/* newsletter-form3 */
.newsletter-form3 {
  .quform-elements {
    position: relative;

    .quform-submit-inner {
      position: absolute;
      right: 0px;
      top: 0px;
      width: auto;

      .butn {
        padding: 13px 30px 12px 30px !important;
      }
    }

    .quform-loading-wrap {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .quform-has-error input {
    border-color: #f5543f;
  }
}

.newsletter-form1 .quform-has-error {
  textarea, select {
    border-color: #f5543f;
  }
}

.newsletter-form3 {
  .quform-input .quform-errors-wrap {
    right: 15px;
  }

  i {
    font-size: 1.1rem;
    line-height: 2rem;
  }
}

/* custom file */
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}

/* quform */
.form-group {
  margin-bottom: 1rem;

  label {
    margin-bottom: .5rem;
  }
}

.form-control:focus {
  border-color: $PrimaryColor;
}

.form-check-input {
  &:checked {
    border-color: $PrimaryColor;
    background-color: $PrimaryColor;
  }

  &:focus {
    box-shadow: none;
  }
}

.quform-input {
  position: relative;

  .quform-errors-wrap {
    position: absolute;
    right: 8px;
    top: 0;
    line-height: normal;
    z-index: 99999;
  }
}

.quform-element > label {
  font-weight: normal;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-size: 15px;

  .quform-required {
    color: #cc0101;
    font-size: 10px;
  }
}

.quform-inner input {
  width: 100%;
}

.quform-elements .quform-element {
  textarea {
    margin-bottom: 0;
    padding: 8px 15px;
    vertical-align: top;
  }

  select {
    margin-bottom: 0;
    padding: 8px 35px 8px 15px;
  }
}

.quform-errors {
  padding: 0;
  margin: 0;
  line-height: normal;

  > .quform-error {
    padding: 0;
    background: none;
    border: none;
    float: none;
    color: #f5543f;
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal;
  }
}

.quform-outer-no-js {
  .quform-error {
    padding: 0;
    background: none;
    border: none;
    float: none;
    color: #f5543f;
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal;
  }

  .quform-success-message {
    padding: 0.75rem 1.25rem 0.75rem 3rem;
  }
}

.quform-has-error {
  input, textarea, select, input[type=file], .custom-file-label {
    border-color: #f5543f;
  }
}

.quform-success-message {
  padding: 0.75rem 1.25rem 0.75rem 3rem;
}

.quform-submit-inner {
  float: none;
}

.quform-loading-wrap {
  float: none;

  .quform-loading {
    display: inline-block;
  }
}

.light-validation .quform-errors > .quform-error {
  color: #fff;
}

/* ===================================
    Others
====================================== */
/*icon gallery*/
.icon-gallery {
  .d-table {
    margin-top: 30px;
  }

  .d-table-cell {
    width: 125px;
    height: 125px;
    text-align: center;
    margin-bottom: 25px;
    border: 1px solid rgba(0, 0, 0, 0.075);
    vertical-align: middle;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    padding: 10px;
    background: #fff;
  }

  i {
    display: block;
    margin-bottom: 15px;
    font-size: 28px;
    color: $PrimaryColor;
  }
}

/* modal */
.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 999999;
}

/* buttons */
.bd-example {
  button, a {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
}

/* ===================================
    Copy Elements
====================================== */
pre[class*="language-"] {
  max-height: 45vh;
  height: 100%;
  margin: 35px 0 15px 0;
  padding-top: 0;
}

.html-code {
  background-color: #fbfbfb;
  position: relative;
  box-shadow: inset 0 0 0 1px #dde1e6, 0 3px 5px rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #ededed;

  &:before {
    color: #c8dfab;
    content: "•••";
    font-size: 30px;
    left: 24px;
    letter-spacing: 4px;
    line-height: 12px;
    position: absolute;
    top: 24px;
  }
}

.copy-element {
  position: absolute;
  top: -50px;
  right: 100px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.source-element {
  position: absolute;
  top: -50px;
  right: 15px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.html-code {
  .copy-element {
    top: 15px;
    right: 30px;
  }

  &:hover {
    .copy-element, .source-element {
      opacity: 1;
    }
  }
}

.box-hover:hover {
  .copy-element, .source-element {
    opacity: 1;
  }
}

.copy-element > a, .source-element > a {
  border-radius: 0.25rem;
  background: #dde1e6;
  color: #777 !important;
  display: inline-block;
  padding: 5px 15px;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer !important;
  font-weight: 600;
}

.copy-element > a:hover, .source-element > a:hover {
  background: $PrimaryColor;
  color: #fff !important;
}

.copy-clipboard {
  cursor: pointer;
  padding: 5px 15px;
}

.white-popup-block {
  background-color: #fbfbfb;
  position: relative;
  max-width: 650px;
  box-shadow: inset 0 0 0 1px #dde1e6, 0 3px 5px rgba(0, 0, 0, 0.15);
  padding: 60px 30px 30px 30px;
  border-radius: 5px;
  margin: 40px auto;
  border: 1px solid #ededed;

  &.popup-copy.mfp-hide {
    display: block !important;
    height: 0;
    position: absolute;
    z-index: -1;
    padding: 0;
    opacity: 0;
    margin: 0;
  }

  &:before {
    color: #c8dfab;
    content: "•••";
    font-size: 30px;
    left: 24px;
    letter-spacing: 4px;
    line-height: 12px;
    position: absolute;
    top: 24px;
  }

  &:hover .copy-element {
    opacity: 1;
  }

  .copy-element {
    top: 45px;
    right: 30px;
  }
}

.box-hover {
  position: relative;

  .container {
    position: relative;
  }
}

/* ===================================
    Footer
====================================== */
footer {
  padding: 50px 0 0;
  background: #1f1f1f;
  color: rgba(255,255,255,0.85);

  p {
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: $PrimaryColor;
      margin-top: 12px;
    }
  }
}

.footer-social-icons {
  ul {
    margin-bottom: 0;

    li {
      display: inline-block;
      border: 1px solid #fff;
      border-radius: 50%;
      color: #fff;
      margin-right: 10px;
      margin-bottom: 5px;
      -webkit-transition-duration: .3s;
      transition-duration: 0.3s;

      a {
        color: #939393;
        display: block;
        font-size: 15px;
        height: 35px;
        line-height: 34px;
        text-align: center;
        width: 35px;
      }

      &:hover {
        background: $PrimaryColor;
        border-color: $PrimaryColor;

        i {
          color: #fff;
        }
      }
    }
  }

  &.small ul li {
    margin-bottom: 0;

    a {
      font-size: 12px;
      height: 25px;
      line-height: 26px;
      width: 25px;
    }
  }
}

.footer-social-icons2 ul {
  margin-bottom: 0;

  li {
    display: inline-block;
    border: 1px solid #6f6f6f;
    border-radius: 50%;
    color: #6f6f6f;
    margin-right: 10px;
    margin-bottom: 0;
    -webkit-transition-duration: .3s;
    transition-duration: 0.3s;

    a {
      color: #939393;
      display: block;
      font-size: 15px;
      height: 35px;
      line-height: 34px;
      text-align: center;
      width: 35px;
    }

    &:hover {
      background: $PrimaryColor;
      border-color: $PrimaryColor;

      i {
        color: #fff;
      }
    }
  }
}

.footer-subscribe {
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;

  input {
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    border: 0px solid;
    height: 40px;
    outline: none;
    box-shadow: none;
    padding: 6px 95px 6px 12px;
    margin-bottom: 0;

    &:focus {
      background: #fff;
      border-radius: 6px;
      font-size: 14px;
      border: 0px solid;
      height: 40px;
      outline: none;
      box-shadow: none;
      padding: 6px 95px 6px 12px;
      margin-bottom: 0;
    }
  }

  .butn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    -webkit-border-radius: 0;
    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius: 0;
    -moz-border-radius-topright: 4px;
    -moz-border-radius-bottomright: 4px;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    line-height: 15px;

    &:after {
      -webkit-border-radius: 0;
      -webkit-border-top-right-radius: 3px;
      -webkit-border-bottom-right-radius: 3px;
      -moz-border-radius: 0;
      -moz-border-radius-topright: 3px;
      -moz-border-radius-bottomright: 3px;
      border-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      background: #e5e5e5;
    }

    &:hover {
      -webkit-border-radius: 0;
      -webkit-border-top-right-radius: 3px;
      -webkit-border-bottom-right-radius: 3px;
      -moz-border-radius: 0;
      -moz-border-radius-topright: 3px;
      -moz-border-radius-bottomright: 3px;
      border-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      &:after {
        -webkit-border-radius: 0;
        -webkit-border-top-right-radius: 3px;
        -webkit-border-bottom-right-radius: 3px;
        -moz-border-radius: 0;
        -moz-border-radius-topright: 3px;
        -moz-border-radius-bottomright: 3px;
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    span {
      color: #fff;
    }

    &:hover span {
      color: #232323;
    }

    &.theme {
      &:after {
        background: #000;
      }

      &:hover span {
        color: #fff;
      }
    }

    &.white {
      color: #232323;

      &:after {
        background: #232323;
      }
    }

    &.theme {
      &.white-hover {
        &:hover {
          background: #fff;

          span {
            color: #232323;
          }
        }

        &:after {
          background: #fff;
        }
      }

      &.grey-hover {
        &:hover {
          background: #e5e5e5;

          span {
            color: #232323;
          }
        }

        &:after {
          background: #e5e5e5;
        }
      }
    }
  }
}

.footer-list {
  margin: 0;

  li {
    list-style-type: none;
    color: rgba(255,255,255,0.85);
    padding: 6px 0;

    &:first-child {
      padding-top: 0;
    }

    a {
      font-size: 15px;
      font-weight: 400;
      color: rgba(255,255,255,0.85);

      &:before {
        content: '\f105';
        font-weight: 700;
        vertical-align: bottom;
        font-family: Font Awesome\ 5 Free;
        color: rgba(255,255,255,0.85);
        padding-right: 8px;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        font-size: 13px;
        position: relative;
        top: -2px;
      }

      &:hover {
        color: $PrimaryColor;

        &:before {
          color: $PrimaryColor;
        }
      }
    }
  }
}

.footer-list-style2 {
  margin: 0;

  li {
    list-style-type: none;
    color: #fff;
    padding: 6px 0;

    &:first-child {
      padding-top: 0;
    }

    a {
      font-size: 15px;
      font-weight: 400;
      color: #fff;

      &:before {
        content: '\f105';
        font-weight: 700;
        font-family: Font Awesome\ 5 Free;
        color: #fff;
        padding-right: 8px;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
      }

      &:hover {
        opacity: 0.65;

        &:before {
          opacity: 0.65;
        }
      }
    }
  }
}

.footer-list-style3 {
  margin: 0;

  li {
    list-style-type: none;
    color: #6f6f6f;
    padding: 6px 0;

    &:first-child {
      padding-top: 0;
    }

    a {
      font-size: 15px;
      font-weight: 400;
      color: #6f6f6f;

      &:before {
        content: '\f105';
        font-weight: 700;
        font-family: Font Awesome\ 5 Free;
        color: #6f6f6f;
        padding-right: 8px;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        top: -1px;
        position: relative;
        font-size: 13px;
      }

      &:hover {
        color: #232323;
      }
    }
  }
}

.footer-list-style2 li a:hover:before {
  color: #232323;
}

.footer-recent-post {
  margin: 15px 0;
}

.footer-recent-post-thumb {
  float: left;
  width: 25%;
}

.footer-recent-post-content {
  float: left;
  width: 75%;
  padding-left: 10px;

  a {
    display: block;
    color: rgba(255, 255, 255, 0.85);
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    margin-top: -5px;

    &:hover, &:active, &:focus {
      color: $PrimaryColor;
    }
  }

  span {
    color: #fff;
  }
}

.footer-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  text-align: center;
  background: #000;
  color: rgba(255,255,255,0.85);

  span {
    font-size: 15px;
    font-weight: 400;
  }
}

/*footer-top-bar*/
.footer-top-bar {
  background: #191919;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/*footer style2*/
footer h3.footer-title-style2 {
  margin-bottom: 28px;
}

h3.footer-title-style2:after {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin-top: 15px;
}

/*footer style3*/
.footer-title-style3 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 28px;
  color: #fff;

  &:after {
    content: '';
    width: 60px;
    height: 2px;
    background: $PrimaryColor;
    position: absolute;
    bottom: 0;
    left: 20px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/*footer style4*/
.footer-title-style4:after {
  margin-left: auto;
  margin-right: auto;
}

h3 {
  &.footer-title-style4 {
    margin-bottom: 20px;
  }

  &.footer-title-style5 {
    color: #fff;

    &:after {
      background: #fff;
    }
  }
}

footer h3.footer-title-style6 {
  margin-bottom: 28px;
  color: #636363;
}

h3.footer-title-style6:after {
  width: 100%;
  height: 1px;
  background: #d0d0d0;
  margin-top: 15px;
}

/*footer style7*/
footer h3.footer-title-style7 {
  margin-bottom: 30px;
  padding-bottom: 20px;

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 18px;
    height: 1px;
    margin: 0 0 0;
    width: 70px;
    margin: 10px 0;
    background-color: $PrimaryColor;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid $PrimaryColor;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  footer {
    padding-top: 30px;
  }

  .footer-bar {
    margin-top: 30px;
  }

  .footer-subscribe .butn {
    line-height: 22px;
  }

  .footer-bar p {
    font-size: 13px;
  }
}
