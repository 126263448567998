.searchAlign {
  height: 40px !important;
  width: 614px !important;
  border-color: blue !important;
}

.headerAlign {
  height: 64px;
  /* width: 1440px; */
  background-color: #ffffff !important;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.userIcon {
  margin-left: 20px;
}

.userAlign {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.serachIconAppend {
  margin-left: -2em;
}

.userIconDropdown {
  float: left;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  margin-right: 0.25rem;
}

.userInfoDrop {
  height: 20px;
  width: 103px;
  color: #212121;
  font-family: "Cognizant Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}